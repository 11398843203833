import React, { useState, useRef } from 'react';
import { Box, Button, Typography, Paper, Grid, List, ListItem, IconButton, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
}));

const VideoPreview = styled('video')({
  width: '100%',
  maxHeight: '200px',
  marginTop: '10px',
});

const VideoMerger = () => {
  const [videos, setVideos] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState('');
  const [progress, setProgress] = useState(0);
  const canvasRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const previewRef = useRef(null);

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    const videoFiles = files.filter(file => file.type.startsWith('video/'));
    
    if (videoFiles.length === 0) {
      setError('Please select valid video files.');
      return;
    }

    const newVideos = videoFiles.map(file => ({
      file,
      url: URL.createObjectURL(file),
      name: file.name,
      duration: 0,
    }));

    setVideos(prev => [...prev, ...newVideos]);
    setError('');
  };

  const removeVideo = (index) => {
    setVideos(prev => {
      const newVideos = [...prev];
      URL.revokeObjectURL(newVideos[index].url);
      newVideos.splice(index, 1);
      return newVideos;
    });
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(videos);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setVideos(items);
  };

  const mergeVideos = async () => {
    if (videos.length < 2) {
      setError('Please add at least 2 videos to merge.');
      return;
    }

    setProcessing(true);
    setError('');
    setProgress(0);

    try {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      const chunks = [];

      // Set up MediaRecorder
      const stream = canvas.captureStream(30); // 30 FPS
      mediaRecorderRef.current = new MediaRecorder(stream, {
        mimeType: 'video/webm;codecs=vp8,opus',
        videoBitsPerSecond: 2500000 // 2.5 Mbps
      });

      mediaRecorderRef.current.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunks.push(e.data);
        }
      };

      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(chunks, { type: 'video/webm' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'merged_video.webm';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
        setProcessing(false);
        setProgress(100);
      };

      // Process videos sequentially
      for (let i = 0; i < videos.length; i++) {
        const video = document.createElement('video');
        video.src = videos[i].url;
        video.muted = true;
        
        await new Promise((resolve, reject) => {
          video.onloadedmetadata = () => {
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            resolve();
          };
          video.onerror = reject;
        });

        await new Promise((resolve, reject) => {
          video.onended = resolve;
          video.onerror = reject;

          video.onplay = () => {
            if (i === 0) {
              mediaRecorderRef.current.start();
            }

            function drawFrame() {
              if (!video.paused && !video.ended) {
                ctx.drawImage(video, 0, 0);
                requestAnimationFrame(drawFrame);
              }
            }
            drawFrame();
          };

          video.play();
        });

        setProgress(((i + 1) / videos.length) * 90);
      }

      mediaRecorderRef.current.stop();
    } catch (err) {
      console.error('Error merging videos:', err);
      setError('Failed to merge videos. Please try again with different videos.');
      setProcessing(false);
    }
  };

  return (
    <Box sx={{ maxWidth: 800, margin: 'auto', padding: 2 }}>
      <StyledPaper>
        <Typography variant="h5" gutterBottom>
          Video Merger
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              component="label"
              fullWidth
              disabled={processing}
            >
              Add Videos
              <input
                type="file"
                hidden
                multiple
                accept="video/*"
                onChange={handleFileSelect}
              />
            </Button>
          </Grid>

          {error && (
            <Grid item xs={12}>
              <Typography color="error">{error}</Typography>
            </Grid>
          )}

          {processing && (
            <Grid item xs={12}>
              <Typography>Processing: {progress}%</Typography>
            </Grid>
          )}

          <Grid item xs={12}>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="videos">
                {(provided) => (
                  <List {...provided.droppableProps} ref={provided.innerRef}>
                    {videos.map((video, index) => (
                      <Draggable key={video.url} draggableId={video.url} index={index}>
                        {(provided) => (
                          <ListItem
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            secondaryAction={
                              <IconButton 
                                edge="end" 
                                onClick={() => removeVideo(index)}
                                disabled={processing}
                              >
                                <DeleteIcon />
                              </IconButton>
                            }
                          >
                            <DragIndicatorIcon sx={{ mr: 2 }} />
                            <ListItemText 
                              primary={video.name}
                              secondary={
                                <VideoPreview
                                  src={video.url}
                                  controls
                                />
                              }
                            />
                          </ListItem>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
            </DragDropContext>
          </Grid>

          {videos.length >= 2 && (
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={mergeVideos}
                disabled={processing}
                fullWidth
              >
                Merge Videos
              </Button>
            </Grid>
          )}
        </Grid>

        <canvas
          ref={canvasRef}
          style={{ display: 'none' }}
        />
        
        <video
          ref={previewRef}
          style={{ display: 'none' }}
        />
      </StyledPaper>
    </Box>
  );
};

export default VideoMerger;
