import React, { useState, useRef } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  Button,
  Alert,
  Grid,
  CircularProgress,
  TextField,
  Slider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tabs,
  Tab,
  IconButton,
} from '@mui/material';
import {
  Upload as UploadIcon,
  Download as DownloadIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  TextFields as TextIcon,
  Image as ImageIcon,
  Opacity as OpacityIcon,
  FormatSize as FontSizeIcon,
  ColorLens as ColorIcon,
} from '@mui/icons-material';
import { SketchPicker } from 'react-color';
import ToolDescription from '../common/ToolDescription';

function ImageWatermark() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [watermarkFile, setWatermarkFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [watermarkUrl, setWatermarkUrl] = useState('');
  const [error, setError] = useState('');
  const [processing, setProcessing] = useState(false);
  const [watermarkType, setWatermarkType] = useState('text');
  const [watermarkText, setWatermarkText] = useState('Watermark');
  const [textColor, setTextColor] = useState('#000000');
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [fontSize, setFontSize] = useState(48);
  const [opacity, setOpacity] = useState(0.5);
  const [position, setPosition] = useState('center');
  const [rotation, setRotation] = useState(0);

  const imageRef = useRef(null);
  const canvasRef = useRef(null);
  const watermarkImageRef = useRef(null);

  const positions = [
    { value: 'top-left', label: 'Top Left' },
    { value: 'top-center', label: 'Top Center' },
    { value: 'top-right', label: 'Top Right' },
    { value: 'center-left', label: 'Center Left' },
    { value: 'center', label: 'Center' },
    { value: 'center-right', label: 'Center Right' },
    { value: 'bottom-left', label: 'Bottom Left' },
    { value: 'bottom-center', label: 'Bottom Center' },
    { value: 'bottom-right', label: 'Bottom Right' },
  ];

  const handleFileSelect = (event, isWatermark = false) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (!file.type.startsWith('image/')) {
      setError('Please select a valid image file');
      return;
    }

    if (isWatermark) {
      setWatermarkFile(file);
      const reader = new FileReader();
      reader.onload = () => setWatermarkUrl(reader.result);
      reader.readAsDataURL(file);
    } else {
      setSelectedFile(file);
      setError('');
      const reader = new FileReader();
      reader.onload = () => setPreviewUrl(reader.result);
      reader.readAsDataURL(file);
    }
  };

  const clearImage = () => {
    setSelectedFile(null);
    setPreviewUrl('');
    setError('');
  };

  const clearWatermark = () => {
    setWatermarkFile(null);
    setWatermarkUrl('');
  };

  const getPositionCoordinates = (canvas, watermarkWidth, watermarkHeight) => {
    const padding = 20;
    const positions = {
      'top-left': { x: padding, y: padding },
      'top-center': { x: (canvas.width - watermarkWidth) / 2, y: padding },
      'top-right': { x: canvas.width - watermarkWidth - padding, y: padding },
      'center-left': { x: padding, y: (canvas.height - watermarkHeight) / 2 },
      'center': { x: (canvas.width - watermarkWidth) / 2, y: (canvas.height - watermarkHeight) / 2 },
      'center-right': { x: canvas.width - watermarkWidth - padding, y: (canvas.height - watermarkHeight) / 2 },
      'bottom-left': { x: padding, y: canvas.height - watermarkHeight - padding },
      'bottom-center': { x: (canvas.width - watermarkWidth) / 2, y: canvas.height - watermarkHeight - padding },
      'bottom-right': { x: canvas.width - watermarkWidth - padding, y: canvas.height - watermarkHeight - padding },
    };
    return positions[position];
  };

  const applyWatermark = async () => {
    if (!selectedFile || !canvasRef.current) return;

    setProcessing(true);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // Draw main image
    canvas.width = imageRef.current.naturalWidth;
    canvas.height = imageRef.current.naturalHeight;
    ctx.drawImage(imageRef.current, 0, 0);

    // Apply watermark
    ctx.save();
    ctx.globalAlpha = opacity;

    if (watermarkType === 'text') {
      // Text watermark
      ctx.font = `${fontSize}px Arial`;
      ctx.fillStyle = textColor;
      const metrics = ctx.measureText(watermarkText);
      const { x, y } = getPositionCoordinates(canvas, metrics.width, fontSize);

      ctx.translate(x + metrics.width / 2, y + fontSize / 2);
      ctx.rotate((rotation * Math.PI) / 180);
      ctx.fillText(watermarkText, -metrics.width / 2, fontSize / 2);
    } else if (watermarkType === 'image' && watermarkImageRef.current) {
      // Image watermark
      const watermarkImg = watermarkImageRef.current;
      const aspectRatio = watermarkImg.naturalWidth / watermarkImg.naturalHeight;
      const watermarkWidth = Math.min(canvas.width / 3, watermarkImg.naturalWidth);
      const watermarkHeight = watermarkWidth / aspectRatio;

      const { x, y } = getPositionCoordinates(canvas, watermarkWidth, watermarkHeight);
      
      ctx.translate(x + watermarkWidth / 2, y + watermarkHeight / 2);
      ctx.rotate((rotation * Math.PI) / 180);
      ctx.drawImage(watermarkImg, -watermarkWidth / 2, -watermarkHeight / 2, watermarkWidth, watermarkHeight);
    }

    ctx.restore();

    // Convert to blob and download
    canvas.toBlob(
      (blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `watermarked_${selectedFile.name}`;
        link.click();
        URL.revokeObjectURL(url);
        setProcessing(false);
      },
      'image/jpeg',
      0.95
    );
  };

  return (
    <Container maxWidth="lg">
      <Paper elevation={3} sx={{ p: 4, my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Image Watermark
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          Add text or image watermarks to your images
        </Typography>

        <Box sx={{ mb: 4 }}>
          <Button
            variant="contained"
            component="label"
            startIcon={<UploadIcon />}
            sx={{ mr: 2 }}
          >
            Upload Image
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={(e) => handleFileSelect(e, false)}
            />
          </Button>

          {selectedFile && (
            <Button
              variant="outlined"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={clearImage}
            >
              Clear
            </Button>
          )}
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {selectedFile && (
          <>
            <Box sx={{ mb: 4, textAlign: 'center' }}>
              <img
                ref={imageRef}
                src={previewUrl}
                alt="Preview"
                style={{
                  maxWidth: '100%',
                  maxHeight: '400px',
                  objectFit: 'contain',
                }}
              />
            </Box>

            <Tabs
              value={watermarkType}
              onChange={(e, value) => setWatermarkType(value)}
              centered
              sx={{ mb: 3 }}
            >
              <Tab icon={<TextIcon />} label="Text" value="text" />
              <Tab icon={<ImageIcon />} label="Image" value="image" />
            </Tabs>

            <Grid container spacing={3}>
              {watermarkType === 'text' ? (
                <>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Watermark Text"
                      value={watermarkText}
                      onChange={(e) => setWatermarkText(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography gutterBottom>Font Size: {fontSize}px</Typography>
                    <Slider
                      value={fontSize}
                      onChange={(e, value) => setFontSize(value)}
                      min={12}
                      max={200}
                      valueLabelDisplay="auto"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ mb: 2 }}>
                      <Button
                        variant="outlined"
                        onClick={() => setShowColorPicker(!showColorPicker)}
                        startIcon={<ColorIcon />}
                        sx={{ backgroundColor: textColor }}
                      >
                        Text Color
                      </Button>
                    </Box>
                    {showColorPicker && (
                      <Box sx={{ position: 'absolute', zIndex: 2 }}>
                        <Box
                          sx={{
                            position: 'fixed',
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                          }}
                          onClick={() => setShowColorPicker(false)}
                        />
                        <SketchPicker
                          color={textColor}
                          onChange={(color) => setTextColor(color.hex)}
                        />
                      </Box>
                    )}
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  <Box sx={{ mb: 2 }}>
                    <Button
                      variant="outlined"
                      component="label"
                      startIcon={<AddIcon />}
                    >
                      Upload Watermark Image
                      <input
                        type="file"
                        hidden
                        accept="image/*"
                        onChange={(e) => handleFileSelect(e, true)}
                      />
                    </Button>
                  </Box>
                  {watermarkUrl && (
                    <Box sx={{ textAlign: 'center', mb: 2 }}>
                      <img
                        ref={watermarkImageRef}
                        src={watermarkUrl}
                        alt="Watermark"
                        style={{
                          maxWidth: '200px',
                          maxHeight: '100px',
                          objectFit: 'contain',
                        }}
                      />
                      <IconButton onClick={clearWatermark} color="error">
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  )}
                </Grid>
              )}

              <Grid item xs={12} sm={6}>
                <Typography gutterBottom>Opacity: {Math.round(opacity * 100)}%</Typography>
                <Slider
                  value={opacity * 100}
                  onChange={(e, value) => setOpacity(value / 100)}
                  min={1}
                  max={100}
                  valueLabelDisplay="auto"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography gutterBottom>Rotation: {rotation}°</Typography>
                <Slider
                  value={rotation}
                  onChange={(e, value) => setRotation(value)}
                  min={-180}
                  max={180}
                  valueLabelDisplay="auto"
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Position</InputLabel>
                  <Select
                    value={position}
                    onChange={(e) => setPosition(e.target.value)}
                    label="Position"
                  >
                    {positions.map((pos) => (
                      <MenuItem key={pos.value} value={pos.value}>
                        {pos.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    variant="contained"
                    startIcon={<DownloadIcon />}
                    onClick={applyWatermark}
                    disabled={processing || (watermarkType === 'image' && !watermarkFile)}
                  >
                    {processing ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      'Apply Watermark and Download'
                    )}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </>
        )}

        <canvas ref={canvasRef} style={{ display: 'none' }} />
      </Paper>

      <ToolDescription
        title="Image Watermark"
        description="Add professional watermarks to your images. Support both text and image watermarks with customizable position, opacity, and rotation."
        benefits={[
          "Text and image watermark support",
          "Customizable position and rotation",
          "Opacity control",
          "Font size and color options",
          "Multiple position presets",
          "High-quality output"
        ]}
        useCases={[
          "Protect image copyright",
          "Add branding to photos",
          "Create professional watermarks",
          "Sign artwork digitally",
          "Add logos to images",
          "Create branded content"
        ]}
        howTo={[
          "Upload your main image",
          "Choose between text or image watermark",
          "Customize watermark appearance",
          "Adjust position and rotation",
          "Set opacity level",
          "Preview and download the result"
        ]}
      />
    </Container>
  );
}

export default ImageWatermark;
