import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Box } from '@mui/material';
import TextCaseConverter from '../components/tools/TextCaseConverter';
import PasswordGenerator from '../components/tools/PasswordGenerator';
import QRCodeTool from '../components/tools/QRCodeTool';
import WordCounterTool from '../components/tools/WordCounterTool';
import ColorPicker from '../components/tools/ColorPicker';
import Base64Tool from '../components/tools/Base64Tool';
import URLTool from '../components/tools/URLTool';
import JSONTool from '../components/tools/JSONTool';
import MarkdownTool from '../components/tools/MarkdownTool';
import ImageCompressor from '../components/tools/ImageCompressor';
import CodeBeautifier from '../components/tools/CodeBeautifier';
import LoremIpsumGenerator from '../components/tools/LoremIpsumGenerator';

function ToolPage() {
  const { toolId } = useParams();

  const renderTool = () => {
    switch (toolId) {
      case 'text-case-converter':
        return <TextCaseConverter />;
      case 'password-generator':
        return <PasswordGenerator />;
      case 'qr-code-generator':
        return <QRCodeTool />;
      case 'word-counter':
        return <WordCounterTool />;
      case 'color-picker':
        return <ColorPicker />;
      case 'base64-tool':
        return <Base64Tool />;
      case 'url-tool':
        return <URLTool />;
      case 'json-tool':
        return <JSONTool />;
      case 'markdown-tool':
        return <MarkdownTool />;
      case 'image-compressor':
        return <ImageCompressor />;
      case 'code-beautifier':
        return <CodeBeautifier />;
      case 'lorem-ipsum':
        return <LoremIpsumGenerator />;
      default:
        return (
          <Box sx={{ textAlign: 'center', py: 4 }}>
            <Typography variant="h4" color="error">Tool Not Found</Typography>
            <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
              The requested tool could not be found. Please check the URL and try again.
            </Typography>
          </Box>
        );
    }
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        {renderTool()}
      </Box>
    </Container>
  );
}

export default ToolPage;
