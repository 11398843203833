import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Paper,
  TextField,
  CircularProgress,
  Alert,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  Slider,
  Grid,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  CloudUpload as CloudUploadIcon,
  Save as SaveIcon,
  Edit as EditIcon,
  Subtitles as SubtitlesIcon,
  Preview as PreviewIcon,
  Refresh as RefreshIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { toBlobURL, fetchFile } from '@ffmpeg/util';

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

const SubtitleTool = () => {
  const [videoFile, setVideoFile] = useState(null);
  const [subtitles, setSubtitles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [language, setLanguage] = useState('en-US');
  const [fontSize, setFontSize] = useState(24);
  const [textColor, setTextColor] = useState('#FFFFFF');
  const [backgroundColor, setBackgroundColor] = useState('#000000');
  const [previewUrl, setPreviewUrl] = useState('');
  const [embedProgress, setEmbedProgress] = useState(0);
  const [embedStatus, setEmbedStatus] = useState('');
  const [subtitleStyle, setSubtitleStyle] = useState({
    bold: false,
    italic: false,
    underline: false,
    outline: true,
    shadow: true,
    wordHighlight: true,
    highlightColor: '#FFD700',
  });
  const [previewMode, setPreviewMode] = useState(false);
  const [currentSubtitle, setCurrentSubtitle] = useState(null);

  const videoRef = useRef(null);
  const ffmpegRef = useRef(new FFmpeg());
  const recognitionRef = useRef(null);
  const previewVideoRef = useRef(null);

  const languages = [
    { code: 'en-US', name: 'English (US)' },
    { code: 'es-ES', name: 'Spanish' },
    { code: 'fr-FR', name: 'French' },
    { code: 'de-DE', name: 'German' },
    { code: 'it-IT', name: 'Italian' },
    { code: 'pt-BR', name: 'Portuguese' },
    { code: 'hi-IN', name: 'Hindi' },
    { code: 'ja-JP', name: 'Japanese' },
    { code: 'ko-KR', name: 'Korean' },
    { code: 'zh-CN', name: 'Chinese (Simplified)' },
  ];

  const CHUNK_DURATION = 15; // 15 seconds per chunk

  useEffect(() => {
    // Load FFmpeg
    const load = async () => {
      try {
        const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.2/dist/umd';
        const ffmpeg = ffmpegRef.current;
        await ffmpeg.load({
          coreURL: await toBlobURL(baseURL + '/ffmpeg-core.js', 'text/javascript'),
          wasmURL: await toBlobURL(baseURL + '/ffmpeg-core.wasm', 'application/wasm'),
        });
      } catch (err) {
        console.error('Error loading FFmpeg:', err);
        setError('Failed to load FFmpeg');
      }
    };
    load();
  }, []);

  const handleVideoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setVideoFile(file);
      setSubtitles([]);
      setError(null);
      const url = URL.createObjectURL(file);
      setPreviewUrl(url);
      if (videoRef.current) {
        videoRef.current.src = url;
      }
    }
  };

  const generateSubtitles = async () => {
    if (!videoFile) {
      setError('Please upload a video first');
      return;
    }

    try {
      setLoading(true);
      setError(null);
      setProgress(0);
      setSubtitles([]); // Clear existing subtitles

      // Initialize speech recognition with better settings
      const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
      recognition.lang = language;
      recognition.continuous = true;
      recognition.interimResults = true;
      recognition.maxAlternatives = 3; // Get multiple alternatives
      recognition.rate = 1.0;
      recognitionRef.current = recognition;

      let isRecognitionActive = true;
      const subtitleChunks = new Map(); // Store transcripts for each chunk
      let currentChunkIndex = 0;
      let pendingText = '';

      // Pre-calculate total number of chunks
      const totalDuration = videoRef.current.duration;
      const totalChunks = Math.ceil(totalDuration / CHUNK_DURATION);

      // Initialize all chunks with empty text
      for (let i = 0; i < totalChunks; i++) {
        subtitleChunks.set(i, '');
      }

      const updateSubtitles = () => {
        const newSubtitles = Array.from(subtitleChunks.entries())
          .map(([index, text]) => ({
            id: index,
            startTime: index * CHUNK_DURATION,
            endTime: Math.min((index + 1) * CHUNK_DURATION, totalDuration),
            text: text.trim()
          }))
          .sort((a, b) => a.startTime - b.startTime);
        setSubtitles(newSubtitles);
      };

      const processTranscript = (transcript, isFinal) => {
        const currentTime = videoRef.current.currentTime;
        const chunkIndex = Math.floor(currentTime / CHUNK_DURATION);

        if (chunkIndex >= 0 && chunkIndex < totalChunks) {
          if (isFinal) {
            // For final results, append to the current chunk
            const existingText = subtitleChunks.get(chunkIndex) || '';
            const newText = pendingText + ' ' + transcript;
            subtitleChunks.set(chunkIndex, newText.trim());
            pendingText = '';
            updateSubtitles();
          } else {
            // Store interim results
            pendingText = transcript;
          }
        }
      };

      recognition.onresult = (event) => {
        const results = event.results;
        const currentResult = results[results.length - 1];
        
        if (currentResult.isFinal) {
          // Get the most confident result
          let bestTranscript = currentResult[0].transcript;
          let bestConfidence = currentResult[0].confidence;

          // Check alternatives for better confidence
          for (let i = 1; i < currentResult.length; i++) {
            if (currentResult[i].confidence > bestConfidence) {
              bestTranscript = currentResult[i].transcript;
              bestConfidence = currentResult[i].confidence;
            }
          }

          processTranscript(bestTranscript, true);
        } else {
          // Use interim results for real-time feedback
          processTranscript(currentResult[0].transcript, false);
        }
      };

      // Function to handle chunk transitions
      const checkAndUpdateChunk = () => {
        const currentTime = videoRef.current.currentTime;
        const newChunkIndex = Math.floor(currentTime / CHUNK_DURATION);
        
        if (newChunkIndex !== currentChunkIndex) {
          // Save any pending text before switching chunks
          if (pendingText) {
            const existingText = subtitleChunks.get(currentChunkIndex) || '';
            subtitleChunks.set(
              currentChunkIndex,
              (existingText + ' ' + pendingText).trim()
            );
            pendingText = '';
          }
          
          currentChunkIndex = newChunkIndex;
          
          // Restart recognition for the new chunk
          try {
            recognition.stop();
            setTimeout(() => {
              try {
                recognition.start();
              } catch (e) {
                console.warn('Recognition restart failed:', e);
              }
            }, 50);
          } catch (e) {
            console.warn('Recognition stop failed:', e);
          }
        }
      };

      recognition.onerror = (event) => {
        console.error('Recognition error:', event.error);
        if (event.error === 'no-speech' || event.error === 'audio-capture') {
          if (!videoRef.current.ended && isRecognitionActive) {
            setTimeout(() => {
              try {
                recognition.start();
              } catch (e) {
                console.warn('Recognition restart failed:', e);
              }
            }, 50);
          }
        }
      };

      recognition.onend = () => {
        if (!videoRef.current.ended && isRecognitionActive) {
          setTimeout(() => {
            try {
              recognition.start();
            } catch (e) {
              console.warn('Recognition restart failed:', e);
            }
          }, 50);
        }
      };

      // Start video and recognition
      videoRef.current.currentTime = 0;
      await videoRef.current.play();
      recognition.start();

      // Add chunk transition check
      videoRef.current.ontimeupdate = () => {
        checkAndUpdateChunk();
        const progress = (videoRef.current.currentTime / totalDuration) * 100;
        setProgress(progress);
      };

      // Stop recognition when video ends
      videoRef.current.onended = () => {
        isRecognitionActive = false;
        if (recognitionRef.current) {
          recognitionRef.current.stop();
        }
        
        // Save any remaining pending text
        if (pendingText) {
          const existingText = subtitleChunks.get(currentChunkIndex) || '';
          subtitleChunks.set(
            currentChunkIndex,
            (existingText + ' ' + pendingText).trim()
          );
        }
        
        // Final cleanup of subtitles
        const finalSubtitles = Array.from(subtitleChunks.entries())
          .map(([index, text]) => ({
            id: index,
            startTime: index * CHUNK_DURATION,
            endTime: Math.min((index + 1) * CHUNK_DURATION, totalDuration),
            text: text.trim()
          }))
          .filter(sub => sub.text.length > 0)
          .sort((a, b) => a.startTime - b.startTime);

        setSubtitles(finalSubtitles);
        setLoading(false);
      };

    } catch (err) {
      console.error('Error generating subtitles:', err);
      setError('Error generating subtitles: ' + err.message);
      setLoading(false);
    }
  };

  const formatSrtTime = (seconds) => {
    const pad = (num) => num.toString().padStart(2, '0');
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    const ms = Math.floor((seconds % 1) * 1000);
    // SRT format requires comma as decimal separator
    return `${pad(hours)}:${pad(minutes)}:${pad(secs)},${pad(ms, 3)}`;
  };

  const generateSrtContent = () => {
    // Sort subtitles by start time to ensure proper ordering
    const sortedSubtitles = [...subtitles].sort((a, b) => a.startTime - b.startTime);
    
    return sortedSubtitles.map((subtitle, index) => {
      // Ensure minimum duration and proper spacing
      const duration = subtitle.endTime - subtitle.startTime;
      const endTime = subtitle.startTime + Math.max(duration, 0.5); // Minimum 0.5s duration
      
      return `${index + 1}
${formatSrtTime(subtitle.startTime)} --> ${formatSrtTime(endTime)}
${subtitle.text.trim()}

`;
    }).join('');
  };

  const embedSubtitles = async () => {
    if (!videoFile || subtitles.length === 0) {
      setError('Please upload a video and generate subtitles first');
      return;
    }

    try {
      setLoading(true);
      setError(null);
      setEmbedProgress(0);
      setEmbedStatus('Preparing files...');

      const ffmpeg = ffmpegRef.current;
      const inputFormat = videoFile.name.split('.').pop().toLowerCase();
      const outputFileName = `subtitled_video.mp4`; // Force MP4 output for better compatibility
      const srtFileName = 'subtitles.srt';

      // Write input files
      setEmbedStatus('Loading video file...');
      await ffmpeg.writeFile('input.' + inputFormat, await fetchFile(videoFile));
      setEmbedProgress(20);

      setEmbedStatus('Processing subtitles...');
      const srtContent = generateSrtContent();
      console.log('Generated SRT content:', srtContent); // Debug log
      await ffmpeg.writeFile(srtFileName, srtContent);
      setEmbedProgress(40);

      // Build subtitle style
      const fontScale = fontSize / 24;
      const primaryColor = textColor.substring(1);
      const bgColor = backgroundColor.substring(1);
      
      // Create complex filter for subtitle burning
      const subtitleFilter = [
        'subtitles=' + srtFileName + ':force_style=',
        "'",
        `Fontname=Arial,`,
        `FontSize=${fontSize},`,
        `PrimaryColour=&H${primaryColor},`,
        `BackColour=&H${bgColor},`,
        `Outline=1,`,
        `Shadow=1,`,
        subtitleStyle.bold ? 'Bold=1,' : '',
        subtitleStyle.italic ? 'Italic=1,' : '',
        "'",
      ].filter(Boolean).join('');

      setEmbedStatus('Embedding subtitles...');
      
      // Add progress handler
      ffmpeg.on('progress', ({ progress }) => {
        setEmbedProgress(40 + (progress * 50));
        setEmbedStatus(`Processing video: ${Math.round(progress * 100)}%`);
      });

      // First pass: Scale video if needed and prepare for subtitle embedding
      await ffmpeg.exec([
        '-i', 'input.' + inputFormat,
        '-c:v', 'libx264',
        '-preset', 'medium',
        '-crf', '23',
        '-c:a', 'aac',
        '-b:a', '192k',
        '-vf', 'scale=trunc(iw/2)*2:trunc(ih/2)*2',
        'temp_video.mp4'
      ]);

      // Second pass: Burn subtitles into the video
      await ffmpeg.exec([
        '-i', 'temp_video.mp4',
        '-vf', subtitleFilter,
        '-c:v', 'libx264',
        '-preset', 'medium',
        '-crf', '23',
        '-c:a', 'copy',
        '-y',
        outputFileName
      ]);

      setEmbedStatus('Finalizing video...');
      setEmbedProgress(90);

      // Read the output file
      const data = await ffmpeg.readFile(outputFileName);
      const blob = new Blob([data.buffer], { type: 'video/mp4' });
      const url = URL.createObjectURL(blob);

      setEmbedStatus('Complete!');
      setEmbedProgress(100);
      
      // Create download link
      const a = document.createElement('a');
      a.href = url;
      a.download = outputFileName;
      a.click();

      // Cleanup
      URL.revokeObjectURL(url);
      setLoading(false);
      setEmbedProgress(0);
      setEmbedStatus('');

    } catch (err) {
      console.error('Error embedding subtitles:', err);
      setError('Error embedding subtitles: ' + err.message);
      setLoading(false);
      setEmbedProgress(0);
      setEmbedStatus('');
    }
  };

  const generatePreview = async () => {
    if (!videoFile || subtitles.length === 0) {
      setError('Please upload a video and generate subtitles first');
      return;
    }

    try {
      setLoading(true);
      setError(null);
      setEmbedProgress(0);
      setEmbedStatus('Preparing preview...');

      const ffmpeg = ffmpegRef.current;
      const inputFormat = videoFile.name.split('.').pop().toLowerCase();
      const previewFileName = 'preview.mp4';
      const srtFileName = 'subtitles.srt';

      // Write input files
      await ffmpeg.writeFile('input.' + inputFormat, await fetchFile(videoFile));
      await ffmpeg.writeFile(srtFileName, generateSrtContent());

      // Create subtitle filter with current style settings
      const subtitleFilter = [
        'subtitles=' + srtFileName + ':force_style=',
        "'",
        `Fontname=Arial,`,
        `FontSize=${fontSize},`,
        `PrimaryColour=&H${textColor.substring(1)},`,
        `BackColour=&H${backgroundColor.substring(1)},`,
        `Outline=1,`,
        `Shadow=1,`,
        subtitleStyle.bold ? 'Bold=1,' : '',
        subtitleStyle.italic ? 'Italic=1,' : '',
        "'",
      ].filter(Boolean).join('');

      // Generate preview video
      await ffmpeg.exec([
        '-i', 'input.' + inputFormat,
        '-t', '30', // Limit preview to first 30 seconds for faster processing
        '-vf', subtitleFilter,
        '-c:v', 'libx264',
        '-preset', 'ultrafast', // Use faster preset for preview
        '-crf', '28', // Lower quality for preview
        '-c:a', 'aac',
        '-b:a', '128k',
        '-y',
        previewFileName
      ]);

      // Read the preview file
      const data = await ffmpeg.readFile(previewFileName);
      const blob = new Blob([data.buffer], { type: 'video/mp4' });
      const url = URL.createObjectURL(blob);

      // Update preview state
      setPreviewUrl(url);
      setPreviewMode(true);
      setEmbedStatus('Preview ready');
      setLoading(false);

    } catch (err) {
      console.error('Error generating preview:', err);
      setError('Error generating preview: ' + err.message);
      setLoading(false);
    }
  };

  const updatePreviewSubtitle = () => {
    if (previewVideoRef.current && subtitles.length > 0) {
      const currentTime = previewVideoRef.current.currentTime;
      const currentChunks = subtitles.filter(
        sub => currentTime >= sub.startTime && currentTime < sub.endTime
      );
      
      if (currentChunks.length > 0) {
        // Combine overlapping subtitles if any
        const combinedText = currentChunks
          .map(chunk => chunk.text)
          .join(' ')
          .trim();

        // Split long text into smaller lines (max 50 chars per line)
        const words = combinedText.split(' ');
        let lines = [];
        let currentLine = '';
        
        words.forEach(word => {
          if ((currentLine + ' ' + word).length > 50) {
            lines.push(currentLine);
            currentLine = word;
          } else {
            currentLine = currentLine ? currentLine + ' ' + word : word;
          }
        });
        if (currentLine) {
          lines.push(currentLine);
        }
        
        // Update the subtitle with line breaks
        setCurrentSubtitle({
          ...currentChunks[0],
          text: lines.join('\n')
        });
      } else {
        setCurrentSubtitle(null);
      }
    }
  };

  const handleSubtitleEdit = (index, newText) => {
    const newSubtitles = [...subtitles];
    newSubtitles[index] = { ...newSubtitles[index], text: newText };
    setSubtitles(newSubtitles);
  };

  const downloadSrt = () => {
    if (subtitles.length === 0) {
      setError('No subtitles to download');
      return;
    }

    const srtContent = generateSrtContent();
    const blob = new Blob([srtContent], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'subtitles.srt';
    link.click();
  };

  useEffect(() => {
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  return (
    <Paper elevation={3} sx={{ p: 3, maxWidth: 1200, mx: 'auto', my: 4 }}>
      <Typography variant="h5" gutterBottom>
        Video Subtitle Tool
      </Typography>

      <Box sx={{ mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
                disabled={loading}
              >
                Upload Video
                <VisuallyHiddenInput
                  type="file"
                  accept="video/*"
                  onChange={handleVideoUpload}
                />
              </Button>
            </FormControl>

            {videoFile && (
              <Box sx={{ mb: 2 }}>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Selected video: {videoFile.name}
                </Typography>
                <video
                  ref={videoRef}
                  src={previewUrl}
                  controls
                  style={{ width: '100%', marginTop: '10px' }}
                  onTimeUpdate={(e) => setCurrentTime(e.target.currentTime)}
                />
              </Box>
            )}

            <FormControl fullWidth sx={{ mb: 2 }}>
              <FormLabel>Language</FormLabel>
              <Select
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
                disabled={loading}
              >
                {languages.map((lang) => (
                  <MenuItem key={lang.code} value={lang.code}>
                    {lang.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button
              variant="contained"
              onClick={generateSubtitles}
              disabled={!videoFile || loading}
              startIcon={<SubtitlesIcon />}
              fullWidth
              sx={{ mb: 2 }}
            >
              Generate Subtitles
            </Button>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              Subtitle Settings
            </Typography>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <FormLabel>Font Size</FormLabel>
              <Slider
                value={fontSize}
                onChange={(_, value) => setFontSize(value)}
                min={12}
                max={48}
                step={1}
                valueLabelDisplay="auto"
                marks={[
                  { value: 12, label: '12px' },
                  { value: 24, label: '24px' },
                  { value: 48, label: '48px' },
                ]}
              />
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <FormLabel>Text Color</FormLabel>
              <input
                type="color"
                value={textColor}
                onChange={(e) => setTextColor(e.target.value)}
                style={{ width: '100%', height: '40px' }}
              />
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <FormLabel>Background Color</FormLabel>
              <input
                type="color"
                value={backgroundColor}
                onChange={(e) => setBackgroundColor(e.target.value)}
                style={{ width: '100%', height: '40px' }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      {(previewMode || subtitles.length > 0) && (
        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Subtitle Styling
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel>Text Style</FormLabel>
                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mt: 1 }}>
                  <Button
                    variant={subtitleStyle.bold ? "contained" : "outlined"}
                    size="small"
                    onClick={() => {
                      setSubtitleStyle(prev => ({ ...prev, bold: !prev.bold }));
                      if (previewMode) generatePreview();
                    }}
                  >
                    Bold
                  </Button>
                  <Button
                    variant={subtitleStyle.italic ? "contained" : "outlined"}
                    size="small"
                    onClick={() => {
                      setSubtitleStyle(prev => ({ ...prev, italic: !prev.italic }));
                      if (previewMode) generatePreview();
                    }}
                  >
                    Italic
                  </Button>
                  <Button
                    variant={subtitleStyle.underline ? "contained" : "outlined"}
                    size="small"
                    onClick={() => {
                      setSubtitleStyle(prev => ({ ...prev, underline: !prev.underline }));
                      if (previewMode) generatePreview();
                    }}
                  >
                    Underline
                  </Button>
                </Box>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel>Effects</FormLabel>
                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mt: 1 }}>
                  <Button
                    variant={subtitleStyle.outline ? "contained" : "outlined"}
                    size="small"
                    onClick={() => {
                      setSubtitleStyle(prev => ({ ...prev, outline: !prev.outline }));
                      if (previewMode) generatePreview();
                    }}
                  >
                    Outline
                  </Button>
                  <Button
                    variant={subtitleStyle.shadow ? "contained" : "outlined"}
                    size="small"
                    onClick={() => {
                      setSubtitleStyle(prev => ({ ...prev, shadow: !prev.shadow }));
                      if (previewMode) generatePreview();
                    }}
                  >
                    Shadow
                  </Button>
                </Box>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <FormLabel>Font Size</FormLabel>
                <Slider
                  value={fontSize}
                  onChange={(e, value) => {
                    setFontSize(value);
                    if (previewMode) generatePreview();
                  }}
                  min={12}
                  max={72}
                  step={2}
                  marks={[
                    { value: 12, label: '12px' },
                    { value: 24, label: '24px' },
                    { value: 48, label: '48px' },
                    { value: 72, label: '72px' },
                  ]}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <FormLabel>Text Color</FormLabel>
                <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
                  <TextField
                    type="color"
                    value={textColor}
                    onChange={(e) => {
                      setTextColor(e.target.value);
                      if (previewMode) generatePreview();
                    }}
                    sx={{ width: '100%' }}
                  />
                </Box>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <FormLabel>Background Color</FormLabel>
                <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
                  <TextField
                    type="color"
                    value={backgroundColor}
                    onChange={(e) => {
                      setBackgroundColor(e.target.value);
                      if (previewMode) generatePreview();
                    }}
                    sx={{ width: '100%' }}
                  />
                </Box>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      )}

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      {subtitles.length > 0 && (
        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Subtitles
          </Typography>
          {subtitles.map((subtitle, index) => (
            <Box key={subtitle.id} sx={{ mb: 2 }}>
              <Typography variant="body2" color="text.secondary">
                {formatSrtTime(subtitle.startTime)} - {formatSrtTime(subtitle.endTime)}
              </Typography>
              <TextField
                fullWidth
                multiline
                value={subtitle.text}
                onChange={(e) => handleSubtitleEdit(index, e.target.value)}
                variant="outlined"
                size="small"
              />
            </Box>
          ))}

          <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
            <Button
              variant="contained"
              onClick={embedSubtitles}
              disabled={loading}
              startIcon={<SaveIcon />}
            >
              Save Video with Subtitles
            </Button>
            <Button
              variant="outlined"
              onClick={downloadSrt}
              disabled={loading}
              startIcon={<EditIcon />}
            >
              Download SRT File
            </Button>
          </Box>
        </Box>
      )}

      {previewMode && (
        <Box sx={{ mb: 3, position: 'relative' }}>
          <Typography variant="h6" gutterBottom>
            Preview
            <IconButton 
              size="small" 
              onClick={() => setPreviewMode(false)}
              sx={{ ml: 1 }}
            >
              <CloseIcon />
            </IconButton>
          </Typography>
          <Box sx={{ position: 'relative', width: '100%', maxWidth: '800px', mx: 'auto' }}>
            <video
              ref={previewVideoRef}
              src={previewUrl}
              controls
              style={{ width: '100%' }}
              onTimeUpdate={updatePreviewSubtitle}
            />
            {/* Live subtitle preview overlay */}
            {currentSubtitle && (
              <Box
                sx={{
                  position: 'absolute',
                  bottom: '10%',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  textAlign: 'center',
                  padding: '8px',
                  borderRadius: '4px',
                  maxWidth: '80%',
                  backgroundColor: `${backgroundColor}dd`,
                  color: textColor,
                  fontWeight: subtitleStyle.bold ? 'bold' : 'normal',
                  fontStyle: subtitleStyle.italic ? 'italic' : 'normal',
                  textDecoration: subtitleStyle.underline ? 'underline' : 'none',
                  fontSize: `${fontSize}px`,
                  textShadow: subtitleStyle.shadow ? '2px 2px 2px rgba(0,0,0,0.5)' : 'none',
                  WebkitTextStroke: subtitleStyle.outline ? '1px black' : 'none',
                  lineHeight: 1.5,
                  whiteSpace: 'pre-line',
                  maxHeight: '30%',
                  overflowY: 'hidden'
                }}
              >
                {currentSubtitle.text}
              </Box>
            )}
          </Box>
          <Box sx={{ mt: 2, display: 'flex', gap: 2, justifyContent: 'center' }}>
            <Button
              variant="contained"
              onClick={() => generatePreview()}
              startIcon={<RefreshIcon />}
            >
              Refresh Preview
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => embedSubtitles()}
              startIcon={<SaveIcon />}
            >
              Save Final Video
            </Button>
          </Box>
        </Box>
      )}

      <Box sx={{ mb: 3, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
        <Button
          variant="contained"
          onClick={generateSubtitles}
          disabled={!videoFile || loading}
          startIcon={<SubtitlesIcon />}
        >
          Generate Subtitles
        </Button>
        {subtitles.length > 0 && !previewMode && (
          <Button
            variant="contained"
            onClick={generatePreview}
            disabled={loading}
            startIcon={<PreviewIcon />}
          >
            Preview with Subtitles
          </Button>
        )}
        {subtitles.length > 0 && !previewMode && (
          <Button
            variant="contained"
            color="primary"
            onClick={embedSubtitles}
            disabled={loading}
            startIcon={<SaveIcon />}
          >
            Save Video with Subtitles
          </Button>
        )}
      </Box>

      {loading && (
        <Box sx={{ textAlign: 'center', my: 2 }}>
          <CircularProgress 
            variant="determinate" 
            value={embedProgress || progress} 
          />
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            {embedStatus || (progress === 0 ? 'Processing...' : `${Math.round(progress)}% complete`)}
          </Typography>
        </Box>
      )}
    </Paper>
  );
};

export default SubtitleTool;
