import React, { useState, useRef } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  Button,
  TextField,
  Alert,
  Switch,
  FormControlLabel,
  Grid,
  Slider,
  IconButton,
  CircularProgress,
} from '@mui/material';
import {
  Upload as UploadIcon,
  Download as DownloadIcon,
  AspectRatio as AspectRatioIcon,
  Delete as DeleteIcon,
  Refresh as RefreshIcon,
} from '@mui/icons-material';
import ToolDescription from '../common/ToolDescription';

function ImageResizer() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [error, setError] = useState('');
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [maintainAspectRatio, setMaintainAspectRatio] = useState(true);
  const [originalDimensions, setOriginalDimensions] = useState({ width: 0, height: 0 });
  const [quality, setQuality] = useState(90);
  const [processing, setProcessing] = useState(false);
  const [scalePercentage, setScalePercentage] = useState(100);
  const canvasRef = useRef(null);
  const imageRef = useRef(null);

  const handleFileSelect = (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (!file.type.startsWith('image/')) {
      setError('Please select a valid image file');
      return;
    }

    setSelectedFile(file);
    setError('');

    const reader = new FileReader();
    reader.onload = (e) => {
      setPreviewUrl(e.target.result);
      const img = new Image();
      img.onload = () => {
        setOriginalDimensions({ width: img.width, height: img.height });
        setWidth(img.width);
        setHeight(img.height);
        setScalePercentage(100);
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };

  const handleWidthChange = (event) => {
    const newWidth = parseInt(event.target.value) || 0;
    setWidth(newWidth);
    if (maintainAspectRatio && originalDimensions.width > 0) {
      const ratio = originalDimensions.height / originalDimensions.width;
      setHeight(Math.round(newWidth * ratio));
    }
    updateScalePercentage(newWidth, height);
  };

  const handleHeightChange = (event) => {
    const newHeight = parseInt(event.target.value) || 0;
    setHeight(newHeight);
    if (maintainAspectRatio && originalDimensions.height > 0) {
      const ratio = originalDimensions.width / originalDimensions.height;
      setWidth(Math.round(newHeight * ratio));
    }
    updateScalePercentage(width, newHeight);
  };

  const updateScalePercentage = (w, h) => {
    if (originalDimensions.width > 0) {
      const widthScale = (w / originalDimensions.width) * 100;
      const heightScale = (h / originalDimensions.height) * 100;
      setScalePercentage(Math.round((widthScale + heightScale) / 2));
    }
  };

  const handleScaleChange = (event, newValue) => {
    setScalePercentage(newValue);
    const scale = newValue / 100;
    const newWidth = Math.round(originalDimensions.width * scale);
    const newHeight = Math.round(originalDimensions.height * scale);
    setWidth(newWidth);
    setHeight(newHeight);
  };

  const resetDimensions = () => {
    setWidth(originalDimensions.width);
    setHeight(originalDimensions.height);
    setScalePercentage(100);
  };

  const resizeImage = () => {
    if (!selectedFile || !width || !height) return;

    setProcessing(true);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const img = new Image();

    img.onload = () => {
      canvas.width = width;
      canvas.height = height;
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = 'high';
      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob(
        (blob) => {
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = `resized_${selectedFile.name}`;
          link.click();
          URL.revokeObjectURL(url);
          setProcessing(false);
        },
        'image/jpeg',
        quality / 100
      );
    };

    img.src = previewUrl;
  };

  const clearImage = () => {
    setSelectedFile(null);
    setPreviewUrl('');
    setWidth(0);
    setHeight(0);
    setScalePercentage(100);
    setError('');
  };

  return (
    <Container maxWidth="lg">
      <Paper elevation={3} sx={{ p: 4, my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Image Resizer
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          Resize your images while maintaining quality
        </Typography>

        <Box sx={{ mb: 4 }}>
          <Button
            variant="contained"
            component="label"
            startIcon={<UploadIcon />}
            sx={{ mr: 2 }}
          >
            Upload Image
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={handleFileSelect}
            />
          </Button>

          {selectedFile && (
            <Button
              variant="outlined"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={clearImage}
            >
              Clear
            </Button>
          )}
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {selectedFile && (
          <>
            <Box sx={{ mb: 4, textAlign: 'center' }}>
              <img
                ref={imageRef}
                src={previewUrl}
                alt="Preview"
                style={{
                  maxWidth: '100%',
                  maxHeight: '400px',
                  objectFit: 'contain',
                }}
              />
            </Box>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography gutterBottom>Scale: {scalePercentage}%</Typography>
                <Slider
                  value={scalePercentage}
                  onChange={handleScaleChange}
                  min={1}
                  max={200}
                  valueLabelDisplay="auto"
                  valueLabelFormat={(value) => `${value}%`}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Width (px)"
                  type="number"
                  value={width}
                  onChange={handleWidthChange}
                  InputProps={{ inputProps: { min: 1 } }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Height (px)"
                  type="number"
                  value={height}
                  onChange={handleHeightChange}
                  InputProps={{ inputProps: { min: 1 } }}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={maintainAspectRatio}
                      onChange={(e) => setMaintainAspectRatio(e.target.checked)}
                    />
                  }
                  label="Maintain Aspect Ratio"
                />
              </Grid>

              <Grid item xs={12}>
                <Typography gutterBottom>Quality: {quality}%</Typography>
                <Slider
                  value={quality}
                  onChange={(e, value) => setQuality(value)}
                  min={1}
                  max={100}
                  valueLabelDisplay="auto"
                  valueLabelFormat={(value) => `${value}%`}
                />
              </Grid>

              <Grid item xs={12}>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Button
                    variant="contained"
                    startIcon={<DownloadIcon />}
                    onClick={resizeImage}
                    disabled={processing}
                  >
                    {processing ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      'Download Resized Image'
                    )}
                  </Button>
                  <Button
                    variant="outlined"
                    startIcon={<RefreshIcon />}
                    onClick={resetDimensions}
                  >
                    Reset Dimensions
                  </Button>
                  <IconButton
                    onClick={() => setMaintainAspectRatio(!maintainAspectRatio)}
                    color={maintainAspectRatio ? 'primary' : 'default'}
                    title={
                      maintainAspectRatio
                        ? 'Aspect Ratio Locked'
                        : 'Aspect Ratio Unlocked'
                    }
                  >
                    <AspectRatioIcon />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </>
        )}

        <canvas ref={canvasRef} style={{ display: 'none' }} />
      </Paper>

      <ToolDescription
        title="Image Resizer"
        description="A powerful tool to resize images while maintaining quality. Perfect for creating thumbnails, reducing image dimensions, or preparing images for web use."
        benefits={[
          "Maintain aspect ratio option",
          "Quality control slider",
          "Live preview",
          "Percentage-based scaling",
          "Custom dimensions input",
          "High-quality output"
        ]}
        useCases={[
          "Create thumbnails for websites",
          "Resize images for social media",
          "Prepare images for email attachments",
          "Create consistent image sizes for galleries",
          "Reduce image dimensions for web optimization",
          "Scale images for different display requirements"
        ]}
        howTo={[
          "Upload an image using the upload button",
          "Use the scale slider or enter custom dimensions",
          "Toggle aspect ratio lock if needed",
          "Adjust quality settings",
          "Click 'Download Resized Image' to save",
          "Use 'Reset Dimensions' to start over"
        ]}
      />
    </Container>
  );
}

export default ImageResizer;
