import React, { useState, useRef } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  Button,
  Alert,
  Grid,
  CircularProgress,
  Slider,
} from '@mui/material';
import {
  Upload as UploadIcon,
  Download as DownloadIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import ToolDescription from '../common/ToolDescription';

const ffmpeg = new FFmpeg();

function VideoTrimmer() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState('');
  const [ffmpegLoaded, setFfmpegLoaded] = useState(false);
  const [duration, setDuration] = useState(0);
  const [trimRange, setTrimRange] = useState([0, 100]);
  const videoRef = useRef(null);

  // Load FFmpeg on component mount
  React.useEffect(() => {
    const loadFFmpeg = async () => {
      try {
        if (!ffmpegLoaded) {
          await ffmpeg.load({
            coreURL: `${window.location.origin}/ffmpeg-core.js`,
            wasmURL: `${window.location.origin}/ffmpeg-core.wasm`
          });
          setFfmpegLoaded(true);
        }
      } catch (error) {
        console.error('Error loading FFmpeg:', error);
        setError('Failed to load video processing library. Please try again.');
      }
    };
    loadFFmpeg();
  }, [ffmpegLoaded]);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type.startsWith('video/')) {
        setSelectedFile(file);
        setPreviewUrl(URL.createObjectURL(file));
        setError('');
      } else {
        setError('Please select a valid video file.');
      }
    }
  };

  const handleLoadedMetadata = () => {
    if (videoRef.current) {
      setDuration(videoRef.current.duration);
      setTrimRange([0, videoRef.current.duration]);
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const handleTrimRangeChange = (event, newValue) => {
    setTrimRange(newValue);
  };

  const handleTrim = async () => {
    if (!selectedFile || !ffmpegLoaded) return;

    try {
      setProcessing(true);
      setError('');

      // Write the input file to FFmpeg's virtual file system
      await ffmpeg.writeFile('input.mp4', await fetchFile(selectedFile));

      // Execute FFmpeg command to trim the video
      const startTime = trimRange[0];
      const endTime = trimRange[1];
      const duration = endTime - startTime;

      await ffmpeg.exec([
        '-i', 'input.mp4',
        '-ss', startTime.toString(),
        '-t', duration.toString(),
        '-c', 'copy',
        'output.mp4'
      ]);

      // Read the output file
      const data = await ffmpeg.readFile('output.mp4');
      const blob = new Blob([data.buffer], { type: 'video/mp4' });
      
      // Create download link
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'trimmed_video.mp4';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);

    } catch (error) {
      console.error('Error trimming video:', error);
      setError('Failed to trim video. Please try again.');
    } finally {
      setProcessing(false);
    }
  };

  const handleClear = () => {
    setSelectedFile(null);
    setPreviewUrl('');
    setError('');
    setTrimRange([0, 100]);
  };

  const fetchFile = async (file) => {
    const response = await fetch(URL.createObjectURL(file));
    const data = await response.arrayBuffer();
    return new Uint8Array(data);
  };

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 3, my: 3 }}>
        <ToolDescription
          title="Video Trimmer"
          description="Trim your videos to the desired length. Select start and end points to keep only the parts you want."
          benefits={[
            "Precise video trimming with time control",
            "Fast processing with original quality",
            "Preview before processing",
            "Multiple video format support",
            "Simple and intuitive interface"
          ]}
          useCases={[
            "Remove unwanted parts from videos",
            "Extract specific scenes",
            "Create shorter clips from long videos",
            "Trim video for social media"
          ]}
          howTo={[
            "Upload your video file",
            "Use the slider to set start and end points",
            "Preview the selection",
            "Click 'Trim Video' to process",
            "Download your trimmed video"
          ]}
        />

        <Box sx={{ my: 3 }}>
          <input
            accept="video/*"
            style={{ display: 'none' }}
            id="video-file-input"
            type="file"
            onChange={handleFileSelect}
          />
          <label htmlFor="video-file-input">
            <Button
              variant="contained"
              component="span"
              startIcon={<UploadIcon />}
              disabled={processing}
            >
              Select Video
            </Button>
          </label>

          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}

          {selectedFile && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1" gutterBottom>
                Selected file: {selectedFile.name}
              </Typography>
              <video
                ref={videoRef}
                src={previewUrl}
                controls
                style={{ width: '100%', maxHeight: '400px' }}
                onLoadedMetadata={handleLoadedMetadata}
              />

              <Box sx={{ mt: 2 }}>
                <Typography gutterBottom>
                  Trim Range: {formatTime(trimRange[0])} - {formatTime(trimRange[1])}
                </Typography>
                <Slider
                  value={trimRange}
                  onChange={handleTrimRangeChange}
                  valueLabelDisplay="auto"
                  valueLabelFormat={formatTime}
                  min={0}
                  max={duration}
                  disabled={processing}
                />
              </Box>

              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={handleTrim}
                    startIcon={<DownloadIcon />}
                    disabled={processing || !ffmpegLoaded}
                  >
                    {processing ? (
                      <>
                        <CircularProgress size={24} sx={{ mr: 1 }} />
                        Processing...
                      </>
                    ) : (
                      'Trim Video'
                    )}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={handleClear}
                    startIcon={<DeleteIcon />}
                    disabled={processing}
                  >
                    Clear
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </Paper>
    </Container>
  );
}

export default VideoTrimmer;
