import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Paper, Alert, CircularProgress, Slider, FormControl, FormLabel, Card, CardContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CloudUpload as CloudUploadIcon, PlayArrow as PlayArrowIcon, Stop as StopIcon } from '@mui/icons-material';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { toBlobURL, fetchFile } from '@ffmpeg/util';
import WaveSurfer from 'wavesurfer.js';

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

const AudioEditor = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);
  const [fileName, setFileName] = useState('');
  const [volume, setVolume] = useState(100);
  const [speed, setSpeed] = useState(1);
  const [isPlaying, setIsPlaying] = useState(false);
  const [hasProcessedAudio, setHasProcessedAudio] = useState(false);
  const [echoDelay, setEchoDelay] = useState(1000);
  const [echoDecay, setEchoDecay] = useState(0.5);
  const [reverseSegment, setReverseSegment] = useState([0, 100]);
  const [selectedPreset, setSelectedPreset] = useState('');

  const presets = {
    ringtone: {
      name: "Ringtone Enhancement",
      description: "Louder, clearer, with slight echo",
      settings: {
        volume: 150,
        speed: 1,
        echoDelay: 300,
        echoDecay: 0.3
      },
      effects: ['volume', 'echo']
    },
    nightcore: {
      name: "Nightcore Style",
      description: "Faster with higher pitch",
      settings: {
        volume: 120,
        speed: 1.5
      },
      effects: ['speed', 'volume']
    },
    deepVoice: {
      name: "Deep Voice",
      description: "Slower with enhanced bass",
      settings: {
        volume: 130,
        speed: 0.8
      },
      effects: ['speed', 'volume']
    },
    dramatic: {
      name: "Dramatic Effect",
      description: "Strong echo with reverb",
      settings: {
        volume: 120,
        echoDelay: 1500,
        echoDecay: 0.7
      },
      effects: ['volume', 'echo']
    },
    telephone: {
      name: "Telephone Effect",
      description: "Classic telephone sound",
      settings: {
        volume: 140,
        echoDelay: 200,
        echoDecay: 0.2
      },
      effects: ['volume', 'echo']
    },
    reverse_echo: {
      name: "Reverse Echo",
      description: "Reversed with echo effect",
      settings: {
        echoDelay: 800,
        echoDecay: 0.4,
        reverseSegment: [0, 100]
      },
      effects: ['reverse', 'echo']
    }
  };

  const ffmpegRef = React.useRef(new FFmpeg());
  const [loaded, setLoaded] = useState(false);
  const wavesurferRef = React.useRef(null);
  const audioRef = React.useRef(null);
  const processedBlobRef = React.useRef(null);

  const load = async () => {
    const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.2/dist/umd';
    const ffmpeg = ffmpegRef.current;
    ffmpeg.on('progress', ({ progress }) => {
      setProgress(Math.round(progress * 100));
    });
    await ffmpeg.load({
      coreURL: await toBlobURL(baseURL + '/ffmpeg-core.js', 'text/javascript'),
      wasmURL: await toBlobURL(baseURL + '/ffmpeg-core.wasm', 'application/wasm'),
    });
    setLoaded(true);
  };

  useEffect(() => {
    load();
    return () => {
      if (wavesurferRef.current) {
        wavesurferRef.current.destroy();
      }
    };
  }, []);

  const initWaveform = (audioBlob) => {
    if (wavesurferRef.current) {
      wavesurferRef.current.destroy();
    }

    const wavesurfer = WaveSurfer.create({
      container: '#waveform',
      waveColor: '#4a9eff',
      progressColor: '#1976d2',
      cursorColor: '#1976d2',
      barWidth: 2,
      barRadius: 3,
      responsive: true,
      height: 100,
      normalize: true,
      partialRender: true,
    });

    wavesurfer.on('ready', () => {
      console.log('WaveSurfer is ready');
    });

    wavesurfer.on('play', () => setIsPlaying(true));
    wavesurfer.on('pause', () => setIsPlaying(false));
    wavesurfer.on('finish', () => setIsPlaying(false));

    wavesurferRef.current = wavesurfer;
    wavesurfer.loadBlob(audioBlob);
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      setLoading(true);
      setError(null);
      setProgress(0);
      setFileName(file.name);

      // Initialize waveform with original audio
      initWaveform(file);

      // Store the file for later processing
      audioRef.current = file;

    } catch (err) {
      console.error('Audio loading error:', err);
      setError('Error loading audio: ' + (err.message || 'Unknown error'));
    } finally {
      setLoading(false);
      setProgress(0);
    }
  };

  const processAudio = async (effect) => {
    if (!audioRef.current) return;

    try {
      setLoading(true);
      setError(null);
      setProgress(0);

      // Store current playback position and state
      const wasPlaying = wavesurferRef.current?.isPlaying();
      const currentTime = wavesurferRef.current?.getCurrentTime() || 0;

      const ffmpeg = ffmpegRef.current;
      const inputFormat = audioRef.current.name.split('.').pop().toLowerCase();
      const outputFileName = 'processed.' + inputFormat;

      // Write the input file to FFmpeg's virtual file system
      await ffmpeg.writeFile('input.' + inputFormat, await fetchFile(audioRef.current));

      // Apply the selected effect
      let command = [];
      switch (effect) {
        case 'volume':
          command = [
            '-i', 'input.' + inputFormat,
            '-filter:a', 'volume=' + (volume / 100),
            outputFileName
          ];
          break;
        case 'speed':
          command = [
            '-i', 'input.' + inputFormat,
            '-filter:a', 'atempo=' + speed,
            outputFileName
          ];
          break;
        case 'echo':
          command = [
            '-i', 'input.' + inputFormat,
            '-filter:a', `aecho=0.8:${echoDecay}:${echoDelay}:0.5`,
            outputFileName
          ];
          break;
        case 'reverse':
          if (reverseSegment[0] === 0 && reverseSegment[1] === 100) {
            // Reverse entire audio
            command = [
              '-i', 'input.' + inputFormat,
              '-filter:a', 'areverse',
              outputFileName
            ];
          } else {
            // Reverse specific segment
            const duration = wavesurferRef.current.getDuration();
            const startTime = (reverseSegment[0] / 100) * duration;
            const endTime = (reverseSegment[1] / 100) * duration;
            const segmentDuration = endTime - startTime;

            // Split into three parts: before, reversed segment, and after
            command = [
              // Input file
              '-i', 'input.' + inputFormat,
              // Split and reverse the middle segment
              '-filter_complex',
              `[0:a]atrim=0:${startTime}[a];` +
              `[0:a]atrim=${startTime}:${endTime},areverse[b];` +
              `[0:a]atrim=${endTime}[c];` +
              `[a][b][c]concat=n=3:v=0:a=1[out]`,
              // Map the output
              '-map', '[out]',
              outputFileName
            ];
          }
          break;
        default:
          throw new Error('Unknown effect: ' + effect);
      }

      // Run FFmpeg command
      await ffmpeg.exec(command);

      // Read the output file
      const data = await ffmpeg.readFile(outputFileName);
      const blob = new Blob([data.buffer], { type: 'audio/' + inputFormat });
      
      // Store the processed blob for later download
      processedBlobRef.current = blob;
      setHasProcessedAudio(true);

      // Update waveform with processed audio
      if (wavesurferRef.current) {
        wavesurferRef.current.destroy();
      }

      const wavesurfer = WaveSurfer.create({
        container: '#waveform',
        waveColor: '#4a9eff',
        progressColor: '#1976d2',
        cursorColor: '#1976d2',
        barWidth: 2,
        barRadius: 3,
        responsive: true,
        height: 100,
        normalize: true,
        partialRender: true,
      });

      wavesurfer.on('ready', () => {
        // Restore playback position and state
        wavesurfer.seekTo(currentTime / wavesurfer.getDuration());
        if (wasPlaying) {
          wavesurfer.play();
        }
      });

      wavesurfer.on('play', () => setIsPlaying(true));
      wavesurfer.on('pause', () => setIsPlaying(false));
      wavesurfer.on('finish', () => setIsPlaying(false));

      wavesurferRef.current = wavesurfer;
      await wavesurfer.loadBlob(blob);

    } catch (err) {
      console.error('Processing error:', err);
      setError('Error processing audio: ' + (err.message || 'Unknown error'));
    } finally {
      setLoading(false);
      setProgress(0);
    }
  };

  const applyPreset = async (presetKey) => {
    if (!audioRef.current) return;
    setSelectedPreset(presetKey);
    
    const preset = presets[presetKey];
    if (!preset) return;

    try {
      setLoading(true);
      setError(null);

      // Update all settings first
      if (preset.settings.volume !== undefined) setVolume(preset.settings.volume);
      if (preset.settings.speed !== undefined) setSpeed(preset.settings.speed);
      if (preset.settings.echoDelay !== undefined) setEchoDelay(preset.settings.echoDelay);
      if (preset.settings.echoDecay !== undefined) setEchoDecay(preset.settings.echoDecay);
      if (preset.settings.reverseSegment !== undefined) setReverseSegment(preset.settings.reverseSegment);

      // Apply effects in sequence
      for (const effect of preset.effects) {
        await processAudio(effect);
      }

    } catch (err) {
      console.error('Error applying preset:', err);
      setError('Error applying preset: ' + (err.message || 'Unknown error'));
    } finally {
      setLoading(false);
    }
  };

  const downloadProcessedAudio = () => {
    if (!processedBlobRef.current || !audioRef.current) return;

    const url = URL.createObjectURL(processedBlobRef.current);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'edited_' + audioRef.current.name;
    link.click();
    URL.revokeObjectURL(url);
  };

  const togglePlayPause = () => {
    if (wavesurferRef.current) {
      wavesurferRef.current.playPause();
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom>
          Audio Editor
        </Typography>
        
        <Typography variant="body1" sx={{ mb: 2 }}>
          Edit your audio files with various effects and adjustments.
        </Typography>

        <Box sx={{ mb: 3 }}>
          <Button
            component="label"
            variant="contained"
            startIcon={<CloudUploadIcon />}
            disabled={loading || !loaded}
          >
            Upload Audio File
            <VisuallyHiddenInput type="file" onChange={handleFileUpload} accept="audio/*" />
          </Button>
        </Box>

        {fileName && (
          <Typography variant="body2" sx={{ mb: 1 }}>
            Selected file: {fileName}
          </Typography>
        )}

        <Box id="waveform" sx={{ mb: 3 }} />

        {wavesurferRef.current && (
          <Button
            variant="contained"
            onClick={togglePlayPause}
            startIcon={isPlaying ? <StopIcon /> : <PlayArrowIcon />}
            sx={{ mb: 3 }}
          >
            {isPlaying ? 'Stop' : 'Play'}
          </Button>
        )}

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mb: 3 }}>
          <FormControl component="fieldset" sx={{ width: '100%' }}>
            <FormLabel component="legend">Quick Presets</FormLabel>
            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))', gap: 2, mt: 2 }}>
              {Object.entries(presets).map(([key, preset]) => (
                <Card 
                  key={key} 
                  sx={{ 
                    cursor: 'pointer',
                    transition: 'transform 0.2s',
                    '&:hover': {
                      transform: 'scale(1.02)',
                    },
                    bgcolor: selectedPreset === key ? 'primary.light' : 'background.paper'
                  }}
                  onClick={() => applyPreset(key)}
                >
                  <CardContent>
                    <Typography variant="h6" component="div">
                      {preset.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {preset.description}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            </Box>
          </FormControl>

          <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
            Manual Controls
          </Typography>

          <FormControl component="fieldset" sx={{ width: '100%' }}>
            <FormLabel component="legend">Volume</FormLabel>
            <Slider
              value={volume}
              onChange={(_, newValue) => setVolume(newValue)}
              onChangeCommitted={() => processAudio('volume')}
              min={0}
              max={200}
              valueLabelDisplay="auto"
              valueLabelFormat={value => `${value}%`}
            />
          </FormControl>

          <FormControl component="fieldset" sx={{ width: '100%' }}>
            <FormLabel component="legend">Speed</FormLabel>
            <Slider
              value={speed}
              onChange={(_, newValue) => setSpeed(newValue)}
              onChangeCommitted={() => processAudio('speed')}
              min={0.5}
              max={2}
              step={0.1}
              valueLabelDisplay="auto"
              valueLabelFormat={value => `${value}x`}
            />
          </FormControl>

          <FormControl component="fieldset" sx={{ width: '100%' }}>
            <FormLabel component="legend">Echo Settings</FormLabel>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Box>
                <Typography variant="body2">Delay (ms)</Typography>
                <Slider
                  value={echoDelay}
                  onChange={(_, newValue) => setEchoDelay(newValue)}
                  onChangeCommitted={() => processAudio('echo')}
                  min={100}
                  max={2000}
                  step={100}
                  valueLabelDisplay="auto"
                  valueLabelFormat={value => `${value}ms`}
                />
              </Box>
              <Box>
                <Typography variant="body2">Decay</Typography>
                <Slider
                  value={echoDecay}
                  onChange={(_, newValue) => setEchoDecay(newValue)}
                  onChangeCommitted={() => processAudio('echo')}
                  min={0.1}
                  max={0.9}
                  step={0.1}
                  valueLabelDisplay="auto"
                />
              </Box>
              <Button
                variant="contained"
                onClick={() => processAudio('echo')}
                disabled={!audioRef.current || loading}
              >
                Apply Echo
              </Button>
            </Box>
          </FormControl>

          <FormControl component="fieldset" sx={{ width: '100%' }}>
            <FormLabel component="legend">Reverse Settings</FormLabel>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Typography variant="body2">Reverse Segment</Typography>
              <Slider
                value={reverseSegment}
                onChange={(_, newValue) => setReverseSegment(newValue)}
                onChangeCommitted={() => processAudio('reverse')}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                valueLabelFormat={value => `${value}%`}
              />
              <Button
                variant="contained"
                onClick={() => processAudio('reverse')}
                disabled={!audioRef.current || loading}
              >
                Apply Reverse
              </Button>
            </Box>
          </FormControl>
        </Box>

        {loading && (
          <Box sx={{ textAlign: 'center', my: 2 }}>
            <CircularProgress variant="determinate" value={progress} />
            <Typography variant="body2" sx={{ mt: 1 }}>
              Processing... {progress}%
            </Typography>
          </Box>
        )}

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}

        {hasProcessedAudio && (
          <Button
            variant="contained"
            color="primary"
            onClick={downloadProcessedAudio}
            disabled={loading}
            sx={{ mt: 2 }}
          >
            Download Processed Audio
          </Button>
        )}
      </Paper>
    </Box>
  );
};

export default AudioEditor;
