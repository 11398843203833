import React, { useState } from 'react';
import { Box, Button, CircularProgress, Typography, Paper, Alert, List, ListItem, LinearProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CloudUpload as CloudUploadIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { PDFDocument } from 'pdf-lib';

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB limit per image

const ImageToPDF = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [progress, setProgress] = useState(0);

  const validateFile = (file) => {
    if (!file.type.startsWith('image/')) {
      throw new Error(`File "${file.name}" is not an image`);
    }
    if (file.size > MAX_FILE_SIZE) {
      throw new Error(`File "${file.name}" exceeds 10MB size limit`);
    }
  };

  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files);
    setError(null);

    try {
      // Validate all files first
      files.forEach(validateFile);
      setSelectedFiles(prev => [...prev, ...files]);
    } catch (err) {
      setError(err.message);
    }
  };

  const removeFile = (index) => {
    setSelectedFiles(prev => prev.filter((_, i) => i !== index));
    setError(null);
  };

  const readFileAsArrayBuffer = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => reject(new Error(`Failed to read file: ${file.name}`));
      reader.readAsArrayBuffer(file);
    });
  };

  const convertToPDF = async () => {
    if (selectedFiles.length === 0) {
      setError('Please select at least one image');
      return;
    }

    try {
      setLoading(true);
      setError(null);
      setProgress(0);

      // Create a new PDF document
      const pdfDoc = await PDFDocument.create();
      
      // Process each image
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        try {
          setProgress((i / selectedFiles.length) * 100);
          console.log(`Processing file: ${file.name}`);

          // Read the image file
          const imageBytes = await readFileAsArrayBuffer(file);
          console.log(`File ${file.name} read successfully, size: ${imageBytes.byteLength} bytes`);

          // Embed the image based on its type
          let image;
          if (file.type === 'image/jpeg') {
            console.log(`Embedding JPEG: ${file.name}`);
            image = await pdfDoc.embedJpg(imageBytes);
          } else if (file.type === 'image/png') {
            console.log(`Embedding PNG: ${file.name}`);
            image = await pdfDoc.embedPng(imageBytes);
          } else {
            throw new Error(`Unsupported image format: ${file.type}`);
          }

          if (!image) {
            throw new Error('Failed to embed image');
          }

          console.log(`Image embedded successfully: ${file.name}`);
          console.log(`Image dimensions: ${image.width}x${image.height}`);

          // Add a new page with image dimensions
          let width = image.width;
          let height = image.height;

          // Set maximum dimensions to prevent oversized pages
          const maxDimension = 14400; // PDF maximum dimension
          if (width > maxDimension || height > maxDimension) {
            const ratio = Math.min(maxDimension / width, maxDimension / height);
            width *= ratio;
            height *= ratio;
          }

          const page = pdfDoc.addPage([width, height]);
          console.log(`Added page with dimensions: ${width}x${height}`);

          // Draw the image on the page
          page.drawImage(image, {
            x: 0,
            y: 0,
            width: width,
            height: height,
          });
          console.log(`Image drawn on page successfully: ${file.name}`);

        } catch (err) {
          console.error(`Error processing ${file.name}:`, err);
          throw new Error(`Failed to process "${file.name}": ${err.message || 'Image processing failed'}`);
        }
      }

      console.log('All images processed, generating PDF...');
      // Save the PDF
      const pdfBytes = await pdfDoc.save();
      console.log('PDF generated successfully');
      
      // Create and trigger download
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'converted_images.pdf';
      link.click();
      URL.revokeObjectURL(url);

      // Clear selected files after successful conversion
      setSelectedFiles([]);
      setProgress(100);
      console.log('PDF download triggered');
      
    } catch (err) {
      console.error('Conversion error:', err);
      setError(err.message || 'Failed to convert images to PDF. Please try again with different images.');
    } finally {
      setLoading(false);
      setProgress(0);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" gutterBottom>
        Image to PDF Converter
      </Typography>
      
      <Paper 
        elevation={3} 
        sx={{ 
          p: 3, 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          gap: 2 
        }}
      >
        <Typography variant="body1" textAlign="center">
          Upload one or more images to convert them into a PDF file.
        </Typography>
        <Typography variant="body2" color="text.secondary" textAlign="center">
          Supported formats: JPEG, PNG (Max 10MB per image)
        </Typography>

        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            component="label"
            variant="contained"
            startIcon={<CloudUploadIcon />}
            disabled={loading}
          >
            Add Images
            <VisuallyHiddenInput 
              type="file" 
              accept="image/jpeg,image/png" 
              multiple 
              onChange={handleFileUpload} 
            />
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={convertToPDF}
            disabled={loading || selectedFiles.length === 0}
          >
            Convert to PDF
          </Button>
        </Box>

        {selectedFiles.length > 0 && (
          <Paper 
            variant="outlined" 
            sx={{ 
              width: '100%', 
              maxWidth: 500,
              maxHeight: 300,
              overflow: 'auto',
              p: 2
            }}
          >
            <List dense>
              {selectedFiles.map((file, index) => (
                <ListItem
                  key={index}
                  secondaryAction={
                    <Button
                      edge="end"
                      onClick={() => removeFile(index)}
                      disabled={loading}
                      startIcon={<DeleteIcon />}
                      color="error"
                      size="small"
                    >
                      Remove
                    </Button>
                  }
                >
                  <Typography noWrap sx={{ maxWidth: '60%' }}>
                    {file.name} ({(file.size / (1024 * 1024)).toFixed(2)} MB)
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Paper>
        )}

        {loading && (
          <Box sx={{ width: '100%', maxWidth: 500 }}>
            <LinearProgress variant="determinate" value={progress} />
            <Typography align="center" sx={{ mt: 1 }}>
              Converting... {Math.round(progress)}%
            </Typography>
          </Box>
        )}

        {error && (
          <Alert severity="error" sx={{ width: '100%', maxWidth: 500 }}>
            {error}
          </Alert>
        )}
      </Paper>
    </Box>
  );
};

export default ImageToPDF;
