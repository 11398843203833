import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Typography,
  Paper,
  CircularProgress,
  Alert,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Slider,
} from '@mui/material';
import {
  PlayArrow as StartIcon,
  Stop as StopIcon,
  Save as SaveIcon,
  ScreenShare as ScreenShareIcon,
  Videocam as CameraIcon,
} from '@mui/icons-material';

const ScreenRecorder = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [error, setError] = useState(null);
  const [recordingTime, setRecordingTime] = useState(0);
  const [selectedSource, setSelectedSource] = useState('screen');
  const [quality, setQuality] = useState(75);
  const [previewUrl, setPreviewUrl] = useState(null);
  
  const mediaRecorderRef = useRef(null);
  const videoRef = useRef(null);
  const timerRef = useRef(null);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  const startRecording = async () => {
    try {
      setError(null);
      setRecordedChunks([]);
      
      let stream;
      if (selectedSource === 'screen') {
        stream = await navigator.mediaDevices.getDisplayMedia({
          video: {
            frameRate: { ideal: 30 },
            width: { ideal: 1920 },
            height: { ideal: 1080 },
          },
          audio: true,
        });
      } else {
        stream = await navigator.mediaDevices.getUserMedia({
          video: {
            width: { ideal: 1920 },
            height: { ideal: 1080 },
            frameRate: { ideal: 30 },
          },
          audio: true,
        });
      }

      videoRef.current.srcObject = stream;
      
      const mediaRecorder = new MediaRecorder(stream, {
        mimeType: 'video/webm;codecs=vp9',
        videoBitsPerSecond: (quality / 100) * 8000000, // 8Mbps max
      });

      mediaRecorderRef.current = mediaRecorder;
      
      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          setRecordedChunks((prev) => [...prev, event.data]);
        }
      };

      mediaRecorder.onstop = () => {
        stream.getTracks().forEach(track => track.stop());
        videoRef.current.srcObject = null;
        
        const blob = new Blob(recordedChunks, { type: 'video/webm' });
        const url = URL.createObjectURL(blob);
        setPreviewUrl(url);
        videoRef.current.src = url;
      };

      mediaRecorder.start(1000); // Collect data every second
      setIsRecording(true);
      
      // Start timer
      let seconds = 0;
      timerRef.current = setInterval(() => {
        seconds++;
        setRecordingTime(seconds);
      }, 1000);

    } catch (err) {
      console.error('Error starting recording:', err);
      setError(err.message || 'Failed to start recording');
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    }
  };

  const saveRecording = () => {
    if (recordedChunks.length === 0) return;
    
    const blob = new Blob(recordedChunks, { type: 'video/webm' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    a.href = url;
    a.download = `screen-recording-${new Date().toISOString()}.webm`;
    a.click();
    
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 3, my: 2 }}>
        <Typography variant="h5" gutterBottom align="center">
          Screen & Camera Recorder
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <Box sx={{ mb: 2 }}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Recording Source</InputLabel>
            <Select
              value={selectedSource}
              onChange={(e) => setSelectedSource(e.target.value)}
              disabled={isRecording}
            >
              <MenuItem value="screen">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <ScreenShareIcon sx={{ mr: 1 }} />
                  Screen
                </Box>
              </MenuItem>
              <MenuItem value="camera">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <CameraIcon sx={{ mr: 1 }} />
                  Camera
                </Box>
              </MenuItem>
            </Select>
          </FormControl>

          <Typography gutterBottom>Quality</Typography>
          <Slider
            value={quality}
            onChange={(e, newValue) => setQuality(newValue)}
            disabled={isRecording}
            valueLabelDisplay="auto"
            step={5}
            marks
            min={25}
            max={100}
          />
        </Box>

        <Box
          sx={{
            width: '100%',
            aspectRatio: '16/9',
            bgcolor: 'black',
            mb: 2,
            position: 'relative',
          }}
        >
          <video
            ref={videoRef}
            autoPlay
            muted
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
          {isRecording && (
            <Box
              sx={{
                position: 'absolute',
                top: 16,
                right: 16,
                bgcolor: 'error.main',
                color: 'white',
                px: 2,
                py: 0.5,
                borderRadius: 1,
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <CircularProgress
                size={16}
                sx={{
                  color: 'white',
                  animation: 'pulse 1s ease-in-out infinite',
                  '@keyframes pulse': {
                    '0%': { opacity: 1 },
                    '50%': { opacity: 0.5 },
                    '100%': { opacity: 1 },
                  },
                }}
              />
              REC {formatTime(recordingTime)}
            </Box>
          )}
        </Box>

        <Stack direction="row" spacing={2} justifyContent="center">
          {!isRecording ? (
            <Button
              variant="contained"
              color="primary"
              startIcon={<StartIcon />}
              onClick={startRecording}
            >
              Start Recording
            </Button>
          ) : (
            <Button
              variant="contained"
              color="error"
              startIcon={<StopIcon />}
              onClick={stopRecording}
            >
              Stop Recording
            </Button>
          )}
          
          <Button
            variant="contained"
            color="success"
            startIcon={<SaveIcon />}
            onClick={saveRecording}
            disabled={recordedChunks.length === 0 || isRecording}
          >
            Save Recording
          </Button>
        </Stack>
      </Paper>
    </Container>
  );
};

export default ScreenRecorder;
