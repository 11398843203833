import React, { useState } from 'react';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
  Box,
  Alert,
  Snackbar,
  IconButton,
  Tooltip,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import {
  ContentCopy as CopyIcon,
  Clear as ClearIcon,
  Check as ValidIcon,
  FormatIndentIncrease as FormatIcon,
  CompressOutlined as CompressIcon,
} from '@mui/icons-material';
import ToolDescription from '../common/ToolDescription';

function JSONTool() {
  const [input, setInput] = useState('');
  const [result, setResult] = useState('');
  const [indentSize, setIndentSize] = useState(2);
  const [sortKeys, setSortKeys] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [isValid, setIsValid] = useState(null);

  const handleCopy = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setSnackbar({
        open: true,
        message: 'Copied to clipboard!',
        severity: 'success',
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to copy text',
        severity: 'error',
      });
    }
  };

  const handleClear = () => {
    setInput('');
    setResult('');
    setIsValid(null);
  };

  const validateJSON = (jsonString) => {
    try {
      JSON.parse(jsonString);
      return true;
    } catch (e) {
      return false;
    }
  };

  const formatJSON = (compact = false) => {
    try {
      // Parse the JSON to validate it
      let parsed = JSON.parse(input);
      
      if (sortKeys) {
        // Recursively sort object keys
        const sortObject = (obj) => {
          if (obj === null || typeof obj !== 'object') return obj;
          
          if (Array.isArray(obj)) {
            return obj.map(sortObject);
          }
          
          return Object.keys(obj)
            .sort()
            .reduce((sorted, key) => {
              sorted[key] = sortObject(obj[key]);
              return sorted;
            }, {});
        };
        
        parsed = sortObject(parsed);
      }

      // Format the JSON
      const formatted = JSON.stringify(
        parsed,
        null,
        compact ? 0 : indentSize
      );

      setResult(formatted);
      setIsValid(true);
      setSnackbar({
        open: true,
        message: 'JSON formatted successfully!',
        severity: 'success',
      });
    } catch (error) {
      setIsValid(false);
      setSnackbar({
        open: true,
        message: `Invalid JSON: ${error.message}`,
        severity: 'error',
      });
    }
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInput(newValue);
    setIsValid(newValue ? validateJSON(newValue) : null);
    setResult('');
  };

  return (
    <Container maxWidth="lg">
      <Paper elevation={3} sx={{ p: 4, my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          JSON Formatter
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          Format, validate, and beautify JSON data. Features include key sorting and custom indentation.
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', gap: 2, mb: 3, flexWrap: 'wrap' }}>
              <Button
                variant="contained"
                onClick={() => formatJSON()}
                startIcon={<FormatIcon />}
                disabled={!input}
              >
                Format JSON
              </Button>
              <Button
                variant="contained"
                onClick={() => formatJSON(true)}
                startIcon={<CompressIcon />}
                disabled={!input}
              >
                Compact
              </Button>
              <Button
                variant="outlined"
                onClick={handleClear}
                startIcon={<ClearIcon />}
              >
                Clear All
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth size="small">
              <InputLabel>Indent Size</InputLabel>
              <Select
                value={indentSize}
                label="Indent Size"
                onChange={(e) => setIndentSize(e.target.value)}
              >
                <MenuItem value={2}>2 spaces</MenuItem>
                <MenuItem value={4}>4 spaces</MenuItem>
                <MenuItem value={8}>8 spaces</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <FormControlLabel
              control={
                <Switch
                  checked={sortKeys}
                  onChange={(e) => setSortKeys(e.target.checked)}
                />
              }
              label="Sort Object Keys"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={8}
              label="JSON Input"
              value={input}
              onChange={handleInputChange}
              placeholder="Paste your JSON here..."
              error={isValid === false}
              helperText={isValid === false && "Invalid JSON"}
              InputProps={{
                endAdornment: isValid && (
                  <Tooltip title="Valid JSON">
                    <ValidIcon color="success" />
                  </Tooltip>
                ),
              }}
            />
          </Grid>

          {result && (
            <Grid item xs={12}>
              <Paper variant="outlined" sx={{ p: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                    Formatted Result
                  </Typography>
                  <Tooltip title="Copy to clipboard">
                    <IconButton onClick={() => handleCopy(result)} size="small">
                      <CopyIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                <TextField
                  fullWidth
                  multiline
                  rows={8}
                  value={result}
                  InputProps={{ readOnly: true }}
                />
              </Paper>
            </Grid>
          )}
        </Grid>
      </Paper>

      <ToolDescription
        title="JSON Formatter & Validator"
        description="A powerful JSON formatting and validation tool that helps you clean, validate, and format JSON data. Whether you're working with API responses, configuration files, or data storage, this tool ensures your JSON is properly structured and readable."
        benefits={[
          "Instant JSON validation and error detection",
          "Multiple formatting options (pretty print and compact)",
          "Automatic indentation with customizable spaces",
          "Sort object keys alphabetically",
          "Copy formatted JSON with one click",
          "Large JSON file support with efficient processing"
        ]}
        useCases={[
          "Debugging API responses",
          "Formatting configuration files",
          "Validating JSON data before use",
          "Preparing JSON for documentation",
          "Cleaning and standardizing JSON data",
          "Learning JSON structure and syntax"
        ]}
        howTo={[
          "Paste your JSON data into the input field",
          "The tool automatically validates your JSON and shows any errors",
          "Choose your preferred formatting options (indentation, sorting)",
          "Click 'Format' to beautify your JSON",
          "Use 'Compact' mode for minified output",
          "Click the copy button to copy the formatted result"
        ]}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default JSONTool;
