import React, { useState } from 'react';
import { 
  Box, 
  Button, 
  Typography, 
  Paper, 
  Alert, 
  CircularProgress, 
  List, 
  ListItem,
  LinearProgress,
  IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile, toBlobURL } from '@ffmpeg/util';

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  height: 1px;
`;

const AudioMerger = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState({ stage: '', percent: 0 });
  const [audioFiles, setAudioFiles] = useState([]);
  const ffmpegRef = React.useRef(null);
  const [loaded, setLoaded] = useState(false);

  const load = async () => {
    try {
      console.log('Initializing FFmpeg...');
      
      // Create new FFmpeg instance if not exists
      if (!ffmpegRef.current) {
        console.log('Creating new FFmpeg instance');
        const ffmpeg = new FFmpeg();
        
        // Add logging handler
        ffmpeg.on('log', ({ message }) => {
          console.log('FFmpeg log:', message);
        });

        // Add progress handler
        ffmpeg.on('progress', ({ ratio }) => {
          const percent = Math.round(ratio * 100);
          console.log('FFmpeg progress:', percent + '%');
          setProgress(prev => ({
            ...prev,
            percent: Math.min(percent, 100)
          }));
        });

        ffmpegRef.current = ffmpeg;
      }

      const ffmpeg = ffmpegRef.current;

      if (!ffmpeg.loaded) {
        console.log('Loading FFmpeg core...');
        
        try {
          const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.2/dist/umd';
          await ffmpeg.load({
            coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
            wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
          });
          console.log('FFmpeg core loaded successfully');
        } catch (loadError) {
          console.error('FFmpeg load error:', {
            message: loadError.message,
            stack: loadError.stack,
            type: loadError.constructor.name
          });
          throw loadError;
        }
      }

      setLoaded(true);
      console.log('FFmpeg initialization complete');
      return ffmpeg;
    } catch (error) {
      console.error('FFmpeg initialization error:', {
        message: error.message,
        stack: error.stack,
        type: error.constructor.name
      });
      throw error;
    }
  };

  React.useEffect(() => {
    load();
  }, []);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setAudioFiles(prev => [
      ...prev,
      ...files.map(file => ({ id: `file-${Date.now()}-${file.name}`, file }))
    ]);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(audioFiles);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setAudioFiles(items);
  };

  const mergeAudioFiles = async () => {
    if (audioFiles.length < 2) {
      setError('Please upload at least 2 files to merge');
      return;
    }

    const startTime = Date.now();

    const updateProgressWithTime = (stage, percent) => {
      const currentTime = Date.now();
      const elapsedSeconds = Math.floor((currentTime - startTime) / 1000);
      const elapsedTime = new Date(elapsedSeconds * 1000).toISOString().substr(11, 8);
      console.log(`Progress: ${stage} - ${percent}% (${elapsedTime})`);
      setProgress({
        stage: `${stage} (Time elapsed: ${elapsedTime})`,
        percent: percent
      });
    };

    try {
      setLoading(true);
      setError(null);
      updateProgressWithTime('Initializing FFmpeg...', 0);

      // Initialize FFmpeg with error handling
      const ffmpeg = await load();
      console.log('FFmpeg status:', {
        loaded: ffmpeg.loaded,
        instance: !!ffmpeg
      });

      // Log input files for debugging
      const fileInfo = audioFiles.map(f => ({
        name: f.file.name,
        size: f.file.size,
        type: f.file.type,
        lastModified: f.file.lastModified
      }));
      console.log('Input files:', fileInfo);

      // Process files one by one
      for (let i = 0; i < audioFiles.length; i++) {
        const file = audioFiles[i].file;
        const fileName = `input${i}.mp3`;
        
        updateProgressWithTime(
          `Processing file ${i + 1} of ${audioFiles.length}: ${file.name}`,
          (i / audioFiles.length) * 100
        );

        try {
          console.log(`Processing file ${i + 1}:`, file.name);
          
          // Write file to FFmpeg
          const fileData = await fetchFile(file);
          await ffmpeg.writeFile(fileName, fileData);
          console.log(`File ${i + 1} written:`, fileName);
          
          // Verify input file was written
          const inputCheck = await ffmpeg.readFile(fileName);
          if (!inputCheck?.length) {
            throw new Error(`Failed to write file: ${file.name}`);
          }
          console.log(`File ${i + 1} verification complete:`, inputCheck.length, 'bytes');

          // Try to get file information
          try {
            await ffmpeg.exec(['-i', fileName]);
          } catch (e) {
            // FFmpeg -i command always throws, we just want to see the output
            console.log(`File ${i + 1} info check complete`);
          }
        } catch (e) {
          console.error(`Error processing file ${i + 1}:`, e);
          throw new Error(`Error processing ${file.name}: ${e.message}`);
        }
      }

      updateProgressWithTime('Creating merge list...', 80);

      // Create concat file with proper line endings
      const fileList = audioFiles
        .map((_, i) => `file input${i}.mp3`)
        .join('\n');

      console.log('Creating concat file with content:', fileList);
      
      await ffmpeg.writeFile('list.txt', fileList);
      
      // Verify concat file
      const listContent = await ffmpeg.readFile('list.txt', { encoding: 'utf8' });
      console.log('Concat file content:', listContent);

      updateProgressWithTime('Merging audio files...', 85);

      // Log FFmpeg state before merge
      const premergeFiles = await ffmpeg.listDir('/');
      console.log('Files before merge:', premergeFiles);

      try {
        // Execute merge command with detailed logging
        console.log('Executing merge command...');
        
        // First try to get input file information
        for (let i = 0; i < audioFiles.length; i++) {
          try {
            await ffmpeg.exec(['-i', `input${i}.mp3`]);
          } catch (e) {
            // FFmpeg -i command always throws, we just want to see the output
            console.log(`Input file ${i} info logged`);
          }
        }

        // Execute the merge
        await ffmpeg.exec([
          '-f', 'concat',
          '-safe', '0',
          '-i', 'list.txt',
          '-c', 'copy',
          '-y',  // Overwrite output if exists
          'output.mp3'
        ]);
        console.log('Merge command completed');

        // Verify output was created
        const outputData = await ffmpeg.readFile('output.mp3');
        if (!outputData?.length) {
          throw new Error('Failed to create merged audio file (output is empty)');
        }
        console.log('Output file size:', outputData.length, 'bytes');

        // Try to get output file information
        try {
          await ffmpeg.exec(['-i', 'output.mp3']);
        } catch (e) {
          // FFmpeg -i command always throws, we just want to see the output
          console.log('Output file info logged');
        }

        updateProgressWithTime('Preparing download...', 90);

        // Create and verify blob
        const blob = new Blob([outputData.buffer], { type: 'audio/mp3' });
        if (!blob.size) {
          throw new Error('Generated blob is empty');
        }
        console.log('Output blob size:', blob.size, 'bytes');

        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'merged_audio.mp3';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);

        const totalTime = ((Date.now() - startTime) / 1000).toFixed(1);
        updateProgressWithTime(`Success! Download started (${totalTime}s)`, 100);

        // Clean up
        setTimeout(() => {
          setLoading(false);
          setProgress({ stage: '', percent: 0 });
        }, 3000);

      } catch (mergeError) {
        console.error('Merge operation error:', mergeError);
        
        // Try to get detailed error information
        try {
          // List all files after error
          const postErrorFiles = await ffmpeg.listDir('/');
          console.log('Files after error:', postErrorFiles);
          
          // Try to get more information about input files
          for (let i = 0; i < audioFiles.length; i++) {
            try {
              const fileContent = await ffmpeg.readFile(`input${i}.mp3`);
              console.log(`Input file ${i} size:`, fileContent.length);
            } catch (e) {
              console.error(`Error reading input file ${i}:`, e);
            }
          }
        } catch (e) {
          console.error('Error getting debug information:', e);
        }
        
        throw mergeError;
      }

    } catch (err) {
      const totalTime = ((Date.now() - startTime) / 1000).toFixed(1);
      
      // Get detailed FFmpeg state
      try {
        const ffmpeg = ffmpegRef.current;
        if (ffmpeg?.loaded) {
          const files = await ffmpeg.listDir('/');
          console.log('FFmpeg filesystem state:', files);
        }
      } catch (e) {
        console.error('Error getting FFmpeg state:', e);
      }

      // Log detailed error information
      console.error('Merging error:', {
        message: err.message,
        stack: err.stack,
        time: totalTime,
        ffmpegStatus: ffmpegRef.current ? {
          loaded: ffmpegRef.current.loaded,
          instance: !!ffmpegRef.current
        } : 'No FFmpeg instance'
      });

      setError(`Error: ${err.message || 'Failed to merge audio files'} (${totalTime}s)`);
      setLoading(false);
      setProgress({ stage: '', percent: 0 });
    }
  };

  // Add a function to play audio safely
  const playAudio = async (audioUrl) => {
    try {
      const audio = new Audio(audioUrl);
      await audio.play();
      console.log('Audio is playing');
    } catch (error) {
      console.error('Error playing audio:', error);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom>
          Audio Merger
        </Typography>
        
        <Typography variant="body1" sx={{ mb: 2 }}>
          Merge multiple audio files into one. Drag and drop to reorder files.
        </Typography>

        <Box sx={{ mb: 3 }}>
          <Button
            component="label"
            variant="contained"
            startIcon={<CloudUploadIcon />}
            disabled={loading || !loaded}
          >
            Upload Audio Files
            <VisuallyHiddenInput 
              type="file" 
              onChange={handleFileChange} 
              accept="audio/*"
              multiple 
            />
          </Button>
        </Box>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="audioFiles">
            {(provided) => (
              <List
                {...provided.droppableProps}
                ref={provided.innerRef}
                sx={{ 
                  width: '100%',
                  bgcolor: 'background.paper',
                  borderRadius: 1,
                  mb: 3
                }}
              >
                {audioFiles.map((file, index) => (
                  <Draggable key={file.id} draggableId={file.id} index={index}>
                    {(provided) => (
                      <ListItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        sx={{
                          border: '1px solid',
                          borderColor: 'divider',
                          borderRadius: 1,
                          mb: 1
                        }}
                      >
                        <Typography>
                          {index + 1}. {file.file.name}
                        </Typography>
                      </ListItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>

        {audioFiles.length > 0 && (
          <Button
            variant="contained"
            onClick={mergeAudioFiles}
            disabled={loading || audioFiles.length < 2}
            sx={{ mb: 3 }}
          >
            Merge Audio Files
          </Button>
        )}

        {loading && (
          <Box sx={{ width: '100%', mb: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <CircularProgress size={20} sx={{ mr: 1 }} />
              <Typography>
                {progress.stage} {progress.percent}%
              </Typography>
            </Box>
            <LinearProgress 
              variant="determinate" 
              value={progress.percent}
              sx={{ 
                height: 8,
                borderRadius: 1,
                '& .MuiLinearProgress-bar': {
                  borderRadius: 1,
                }
              }}
            />
          </Box>
        )}

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Paper>
    </Box>
  );
};

export default AudioMerger;
