import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  Button,
  TextField,
  Alert,
  Grid,
  Tabs,
  Tab,
  IconButton,
  Tooltip,
} from '@mui/material';
import {
  ContentCopy as CopyIcon,
  FormatIndentIncrease as FormatIcon,
  RestartAlt as ResetIcon,
  Code as CodeIcon,
  Preview as PreviewIcon,
} from '@mui/icons-material';
import ToolDescription from '../common/ToolDescription';

function HTMLTool() {
  const [htmlInput, setHtmlInput] = useState('');
  const [formattedHtml, setFormattedHtml] = useState('');
  const [error, setError] = useState('');
  const [copied, setCopied] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [validationErrors, setValidationErrors] = useState([]);

  useEffect(() => {
    // Load prettier from CDN
    const script = document.createElement('script');
    script.src = 'https://unpkg.com/prettier@2.8.8/standalone.js';
    script.async = true;
    document.body.appendChild(script);

    const parserScript = document.createElement('script');
    parserScript.src = 'https://unpkg.com/prettier@2.8.8/parser-html.js';
    parserScript.async = true;
    document.body.appendChild(parserScript);

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(parserScript);
    };
  }, []);

  const formatHTML = () => {
    try {
      if (typeof window.prettier === 'undefined' || typeof window.prettierPlugins === 'undefined') {
        setError('Formatter is still loading. Please try again in a moment.');
        return;
      }

      const formatted = window.prettier.format(htmlInput, {
        parser: 'html',
        plugins: window.prettierPlugins,
        printWidth: 80,
        tabWidth: 2,
        useTabs: false,
        singleQuote: true,
      });
      setFormattedHtml(formatted);
      setError('');
      validateHTML(formatted);
    } catch (err) {
      setError('Failed to format HTML. Please check for syntax errors.');
      setFormattedHtml('');
    }
  };

  const validateHTML = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const errors = [];

    // Check for parsing errors
    const parserErrors = doc.getElementsByTagName('parsererror');
    if (parserErrors.length > 0) {
      errors.push('HTML parsing error: Invalid HTML structure');
    }

    // Check for unclosed tags
    const bodyContent = doc.body.innerHTML;
    const openTags = bodyContent.match(/<[^/][^>]*>/g) || [];
    const closeTags = bodyContent.match(/<\/[^>]+>/g) || [];
    if (openTags.length !== closeTags.length) {
      errors.push('Unclosed tags detected');
    }

    // Check for invalid attributes
    const allElements = doc.getElementsByTagName('*');
    Array.from(allElements).forEach(element => {
      Array.from(element.attributes).forEach(attr => {
        if (attr.name.startsWith('on') && !attr.name.match(/^on[a-z]+$/)) {
          errors.push(`Invalid event attribute: ${attr.name}`);
        }
      });
    });

    setValidationErrors(errors);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(formattedHtml || htmlInput);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleReset = () => {
    setHtmlInput('');
    setFormattedHtml('');
    setError('');
    setValidationErrors([]);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderPreview = () => {
    if (!htmlInput && !formattedHtml) return null;
    
    return (
      <Box
        sx={{
          border: '1px solid #ccc',
          borderRadius: 1,
          p: 2,
          mt: 2,
          bgcolor: '#fff',
          minHeight: '200px',
        }}
        dangerouslySetInnerHTML={{ __html: formattedHtml || htmlInput }}
      />
    );
  };

  return (
    <Container maxWidth="lg">
      <Paper elevation={3} sx={{ p: 4, my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          HTML Editor & Formatter
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          Edit, format, and validate HTML code with live preview
        </Typography>

        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab icon={<CodeIcon />} label="Editor" />
            <Tab icon={<PreviewIcon />} label="Preview" />
          </Tabs>
        </Box>

        <Box sx={{ display: activeTab === 0 ? 'block' : 'none' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                multiline
                rows={12}
                value={htmlInput}
                onChange={(e) => setHtmlInput(e.target.value)}
                fullWidth
                placeholder="Paste your HTML code here..."
                variant="outlined"
                sx={{ fontFamily: 'monospace' }}
              />
            </Grid>
          </Grid>

          <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
            <Button
              variant="contained"
              startIcon={<FormatIcon />}
              onClick={formatHTML}
              disabled={!htmlInput}
            >
              Format HTML
            </Button>
            <Button
              variant="contained"
              startIcon={<CopyIcon />}
              onClick={handleCopy}
              disabled={!htmlInput && !formattedHtml}
            >
              {copied ? 'Copied!' : 'Copy'}
            </Button>
            <Button
              variant="outlined"
              startIcon={<ResetIcon />}
              onClick={handleReset}
              disabled={!htmlInput && !formattedHtml}
            >
              Reset
            </Button>
          </Box>

          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}

          {validationErrors.length > 0 && (
            <Alert severity="warning" sx={{ mt: 2 }}>
              <Typography variant="subtitle2" gutterBottom>
                Validation Warnings:
              </Typography>
              <ul style={{ margin: 0, paddingLeft: '1.5rem' }}>
                {validationErrors.map((err, index) => (
                  <li key={index}>{err}</li>
                ))}
              </ul>
            </Alert>
          )}

          {formattedHtml && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1" gutterBottom>
                Formatted HTML:
              </Typography>
              <TextField
                multiline
                rows={12}
                value={formattedHtml}
                fullWidth
                variant="outlined"
                InputProps={{
                  readOnly: true,
                  sx: { fontFamily: 'monospace' }
                }}
              />
            </Box>
          )}
        </Box>

        <Box sx={{ display: activeTab === 1 ? 'block' : 'none' }}>
          <Typography variant="subtitle1" gutterBottom>
            Live Preview:
          </Typography>
          {renderPreview()}
        </Box>
      </Paper>

      <ToolDescription
        title="HTML Editor & Formatter"
        description="A comprehensive tool for editing, formatting, and validating HTML code with live preview functionality."
        benefits={[
          "Automatic HTML formatting with proper indentation",
          "Real-time HTML validation",
          "Live preview of HTML output",
          "Copy formatted code to clipboard",
          "Syntax error detection",
          "Clean and intuitive interface"
        ]}
        useCases={[
          "Clean up messy HTML code",
          "Format HTML for better readability",
          "Validate HTML structure",
          "Preview HTML changes in real-time",
          "Prepare HTML for web development",
          "Debug HTML issues"
        ]}
        howTo={[
          "Paste your HTML code in the editor",
          "Click 'Format HTML' to beautify the code",
          "View validation warnings if any",
          "Switch to Preview tab to see the rendered HTML",
          "Copy the formatted code using the Copy button",
          "Reset the editor to start fresh"
        ]}
      />
    </Container>
  );
}

export default HTMLTool;
