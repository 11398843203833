import React, { useState, useRef } from 'react';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
  Box,
  Tab,
  Tabs,
  Alert,
  Snackbar,
  IconButton,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import {
  ContentCopy as CopyIcon,
  Clear as ClearIcon,
  SwapVert as SwapIcon,
  Upload as UploadIcon,
  Download as DownloadIcon,
} from '@mui/icons-material';
import ToolDescription from '../common/ToolDescription';

function Base64Tool() {
  const [value, setValue] = useState('');
  const [result, setResult] = useState('');
  const [mode, setMode] = useState('encode');
  const [tab, setTab] = useState(0);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
    setValue('');
    setResult('');
  };

  const handleModeToggle = () => {
    setMode(mode === 'encode' ? 'decode' : 'encode');
    // Swap input and output
    setValue(result);
    setResult(value);
  };

  const handleCopy = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setSnackbar({
        open: true,
        message: 'Copied to clipboard!',
        severity: 'success',
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to copy text',
        severity: 'error',
      });
    }
  };

  const handleClear = () => {
    setValue('');
    setResult('');
  };

  const handleTextProcess = () => {
    try {
      if (mode === 'encode') {
        const encoded = btoa(value);
        setResult(encoded);
      } else {
        const decoded = atob(value);
        setResult(decoded);
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Failed to ${mode} text. Please check your input.`,
        severity: 'error',
      });
    }
  };

  const handleFileSelect = async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setLoading(true);
    try {
      if (mode === 'encode') {
        const buffer = await file.arrayBuffer();
        const base64 = btoa(
          new Uint8Array(buffer).reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
        setResult(base64);
      } else {
        const text = await file.text();
        const decoded = atob(text);
        setResult(decoded);
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Failed to ${mode} file. Please check your input.`,
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = () => {
    if (!result) return;

    try {
      const blob = new Blob([result], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `base64-${mode}d-result.txt`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to download result',
        severity: 'error',
      });
    }
  };

  return (
    <Container maxWidth="lg">
      <Paper elevation={3} sx={{ p: 4, my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Base64 Encoder/Decoder
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          Convert text and files to and from Base64 format.
        </Typography>

        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
          <Tabs value={tab} onChange={handleTabChange}>
            <Tab label="Text" />
            <Tab label="File" />
          </Tabs>
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
              <Button
                variant="contained"
                onClick={handleModeToggle}
                startIcon={<SwapIcon />}
              >
                {mode === 'encode' ? 'Switch to Decode' : 'Switch to Encode'}
              </Button>
              <Button
                variant="outlined"
                onClick={handleClear}
                startIcon={<ClearIcon />}
              >
                Clear All
              </Button>
            </Box>
          </Grid>

          {tab === 0 ? (
            // Text Mode
            <>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={6}
                  label={mode === 'encode' ? 'Text to Encode' : 'Base64 to Decode'}
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  placeholder={mode === 'encode' ? 'Enter text to encode...' : 'Enter Base64 to decode...'}
                />
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                  <Button
                    variant="contained"
                    onClick={handleTextProcess}
                    disabled={!value}
                  >
                    {mode === 'encode' ? 'Encode' : 'Decode'}
                  </Button>
                </Box>
              </Grid>
            </>
          ) : (
            // File Mode
            <Grid item xs={12}>
              <Box
                sx={{
                  border: '2px dashed',
                  borderColor: 'divider',
                  borderRadius: 1,
                  p: 3,
                  textAlign: 'center',
                }}
              >
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileSelect}
                />
                <Button
                  variant="contained"
                  onClick={() => fileInputRef.current?.click()}
                  startIcon={<UploadIcon />}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    `Select File to ${mode === 'encode' ? 'Encode' : 'Decode'}`
                  )}
                </Button>
              </Box>
            </Grid>
          )}

          {result && (
            <Grid item xs={12}>
              <Paper variant="outlined" sx={{ p: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                    Result
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Tooltip title="Copy to clipboard">
                      <IconButton onClick={() => handleCopy(result)} size="small">
                        <CopyIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Download result">
                      <IconButton onClick={handleDownload} size="small">
                        <DownloadIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
                <TextField
                  fullWidth
                  multiline
                  rows={6}
                  value={result}
                  InputProps={{ readOnly: true }}
                />
              </Paper>
            </Grid>
          )}
        </Grid>
      </Paper>

      <ToolDescription
        title="Base64 Encoder/Decoder"
        description="Base64 is a binary-to-text encoding scheme that represents binary data in an ASCII string format. This tool allows you to easily encode text and files to Base64 format and decode Base64 strings back to their original form."
        benefits={[
          "Convert between text and Base64 format instantly",
          "Support for file encoding and decoding",
          "Handle large text inputs efficiently",
          "Copy results to clipboard with one click",
          "Download encoded/decoded content as files",
          "Real-time validation and error checking"
        ]}
        useCases={[
          "Encoding binary data for email attachments",
          "Preparing images for embedding in CSS or HTML",
          "Encoding authentication credentials",
          "Processing data for API requests",
          "Storing binary data in text format",
          "Working with data URIs in web development"
        ]}
        howTo={[
          "Choose between Text or File mode using the tabs",
          "For Text mode: Enter or paste your text in the input field",
          "For File mode: Click 'Select File' to choose a file to process",
          "Click 'Encode' or 'Decode' based on your needs",
          "Use the copy button to copy the result",
          "Click 'Download' to save the result as a file"
        ]}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default Base64Tool;
