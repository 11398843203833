import React, { useState, useRef } from 'react';
import { 
  Box, 
  Button, 
  Typography, 
  Paper, 
  Grid, 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel,
  Slider,
  FormControlLabel,
  Switch,
  Alert
} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
}));

const VideoPreview = styled('video')({
  width: '100%',
  maxHeight: '300px',
  marginTop: '10px',
});

const VideoConverter = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [outputFormat, setOutputFormat] = useState('video/webm');
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState('');
  const [progress, setProgress] = useState(0);
  const [quality, setQuality] = useState(80);
  const [maintainQuality, setMaintainQuality] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const canvasRef = useRef(null);
  const mediaRecorderRef = useRef(null);

  // Check supported formats
  const getSupportedFormats = () => {
    const formats = [
      { mimeType: 'video/webm;codecs=vp8,opus', label: 'WebM (VP8)', ext: 'webm' },
      { mimeType: 'video/webm;codecs=vp9,opus', label: 'WebM (VP9)', ext: 'webm' },
      { mimeType: 'video/mp4;codecs=h264,aac', label: 'MP4 (H.264)', ext: 'mp4' },
      { mimeType: 'video/webm;codecs=h264,opus', label: 'WebM (H.264)', ext: 'webm' }
    ];

    return formats.filter(format => {
      try {
        return MediaRecorder.isTypeSupported(format.mimeType);
      } catch {
        return false;
      }
    });
  };

  const supportedFormats = getSupportedFormats();

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type.startsWith('video/')) {
        setSelectedFile(file);
        setVideoUrl(URL.createObjectURL(file));
        setError('');
      } else {
        setError('Please select a valid video file.');
      }
    }
  };

  const calculateBitrate = (quality) => {
    // Base bitrate of 2.5 Mbps at 80% quality
    const baseBitrate = 2500000;
    return Math.floor(baseBitrate * (quality / 80));
  };

  const convertVideo = async () => {
    if (!selectedFile) {
      setError('Please select a video file.');
      return;
    }

    setProcessing(true);
    setError('');
    setProgress(0);

    try {
      const video = document.createElement('video');
      video.src = videoUrl;
      video.muted = true;

      await new Promise((resolve, reject) => {
        video.onloadedmetadata = resolve;
        video.onerror = reject;
      });

      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      
      // Set canvas size to match video
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      // Set up MediaRecorder with selected format and quality
      const stream = canvas.captureStream(30); // 30 FPS
      const selectedFormat = supportedFormats.find(f => f.mimeType.startsWith(outputFormat));
      
      if (!selectedFormat) {
        throw new Error('Selected format is not supported by your browser.');
      }

      const options = {
        mimeType: selectedFormat.mimeType,
        videoBitsPerSecond: maintainQuality ? undefined : calculateBitrate(quality)
      };

      mediaRecorderRef.current = new MediaRecorder(stream, options);
      const chunks = [];

      mediaRecorderRef.current.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunks.push(e.data);
        }
      };

      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(chunks, { type: selectedFormat.mimeType });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `converted_video.${selectedFormat.ext}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
        setProcessing(false);
        setProgress(100);
      };

      // Start recording and playing
      mediaRecorderRef.current.start();

      video.onended = () => {
        mediaRecorderRef.current.stop();
        video.remove();
      };

      video.ontimeupdate = () => {
        setProgress((video.currentTime / video.duration) * 90);
      };

      video.onplay = () => {
        function drawFrame() {
          if (!video.paused && !video.ended) {
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            requestAnimationFrame(drawFrame);
          }
        }
        drawFrame();
      };

      await video.play();

    } catch (err) {
      console.error('Error converting video:', err);
      setError(err.message || 'Failed to convert video. Please try a different format or browser.');
      setProcessing(false);
    }
  };

  return (
    <Box sx={{ maxWidth: 800, margin: 'auto', padding: 2 }}>
      <StyledPaper>
        <Typography variant="h5" gutterBottom>
          Video Converter
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              component="label"
              fullWidth
              disabled={processing}
            >
              Select Video
              <input
                type="file"
                hidden
                accept="video/*"
                onChange={handleFileSelect}
              />
            </Button>
          </Grid>

          {videoUrl && (
            <Grid item xs={12}>
              <VideoPreview
                src={videoUrl}
                controls
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Output Format</InputLabel>
              <Select
                value={outputFormat}
                label="Output Format"
                onChange={(e) => setOutputFormat(e.target.value)}
                disabled={processing}
              >
                {supportedFormats.map((format, index) => (
                  <MenuItem key={index} value={format.mimeType.split(';')[0]}>
                    {format.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Typography gutterBottom>
              Quality: {quality}%
            </Typography>
            <Slider
              value={quality}
              onChange={(e, value) => setQuality(value)}
              disabled={processing || maintainQuality}
              min={10}
              max={100}
              marks={[
                { value: 10, label: '10%' },
                { value: 50, label: '50%' },
                { value: 100, label: '100%' }
              ]}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={maintainQuality}
                  onChange={(e) => setMaintainQuality(e.target.checked)}
                  disabled={processing}
                />
              }
              label="Maintain Original Quality (may increase file size)"
            />
          </Grid>

          {error && (
            <Grid item xs={12}>
              <Alert severity="error">{error}</Alert>
            </Grid>
          )}

          {processing && (
            <Grid item xs={12}>
              <Typography>Converting: {Math.round(progress)}%</Typography>
            </Grid>
          )}

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={convertVideo}
              disabled={!selectedFile || processing}
              fullWidth
            >
              Convert Video
            </Button>
          </Grid>

          {supportedFormats.length === 0 && (
            <Grid item xs={12}>
              <Alert severity="warning">
                Your browser doesn't support video conversion. Please try Chrome or Firefox.
              </Alert>
            </Grid>
          )}
        </Grid>

        <canvas
          ref={canvasRef}
          style={{ display: 'none' }}
        />
      </StyledPaper>
    </Box>
  );
};

export default VideoConverter;
