import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  IconButton,
} from '@mui/material';
import {
  TextFields as TextFieldsIcon,
  Lock as LockIcon,
  QrCode as QrCodeIcon,
  FormatListNumbered as FormatListNumberedIcon,
  ColorLens as ColorLensIcon,
  Code as CodeIcon,
  Link as LinkIcon,
  Article as ArticleIcon,
  Image as ImageIcon,
  FormatPaint as FormatPaintIcon,
  SwapHoriz as SwapHorizIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Build as BuildIcon,
  Security as SecurityIcon,
  Brush as BrushIcon,
  TextFormat as TextFormatIcon,
  Transform as TransformIcon,
  Compress as CompressIcon,
  AspectRatio as AspectRatioIcon,
  Crop as CropIcon,
  BrandingWatermark as BrandingWatermarkIcon,
  PictureAsPdf as PictureAsPdfIcon,
  Edit as CreateIcon,
  Photo as PhotoIcon,
  Calculate as CalculateIcon,
  EventNote as EventNoteIcon,
  Email as EmailIcon,
} from '@mui/icons-material';

const toolCategories = {
  'Text Tools': {
    icon: <TextFormatIcon fontSize="large" />,
    color: '#2196f3',
    tools: [
      {
        id: 'text-case-converter',
        name: 'Text Case Converter',
        description: 'Convert text between different cases: uppercase, lowercase, title case, and more.',
        icon: <TextFieldsIcon />,
      },
      {
        id: 'word-counter',
        name: 'Word Counter',
        description: 'Count words, characters, and paragraphs in your text.',
        icon: <FormatListNumberedIcon />,
      },
      {
        id: 'markdown-tool',
        name: 'Markdown Tool',
        description: 'Preview and edit Markdown with live preview.',
        icon: <ArticleIcon />,
      },
      {
        id: 'lorem-ipsum',
        name: 'Lorem Ipsum Generator',
        description: 'Generate customizable Lorem Ipsum placeholder text.',
        icon: <ArticleIcon />,
      },
      {
        id: 'character-counter',
        name: 'Character Counter',
        description: 'Count characters in your text with detailed statistics.',
        icon: <FormatListNumberedIcon />,
      },
    ],
  },
  'Developer Tools': {
    icon: <BuildIcon fontSize="large" />,
    color: '#ff9800',
    tools: [
      {
        id: 'code-beautifier',
        name: 'Code Beautifier',
        description: 'Format and beautify code in multiple languages.',
        icon: <CodeIcon />,
      },
      {
        id: 'json-tool',
        name: 'JSON Tool',
        description: 'Format, validate, and convert JSON data.',
        icon: <CodeIcon />,
      },
      {
        id: 'base64-tool',
        name: 'Base64 Tool',
        description: 'Encode and decode Base64 strings.',
        icon: <CodeIcon />,
      },
      {
        id: 'url-tool',
        name: 'URL Tool',
        description: 'Encode and decode URLs.',
        icon: <LinkIcon />,
      },
      {
        id: 'csv-tool',
        name: 'CSV Tool',
        description: 'View and edit CSV files.',
        icon: <ArticleIcon />,
      },
      {
        id: 'csv-to-json',
        name: 'CSV to JSON',
        description: 'Convert CSV files to JSON format.',
        icon: <SwapHorizIcon />,
      },
    ],
  },
  'Image Tools': {
    icon: <ImageIcon fontSize="large" />,
    color: '#4caf50',
    tools: [
      {
        id: 'image-compressor',
        name: 'Image Compressor',
        description: 'Compress images without losing quality.',
        icon: <CompressIcon />,
      },
      {
        id: 'image-resizer',
        name: 'Image Resizer',
        description: 'Resize images to specific dimensions.',
        icon: <AspectRatioIcon />,
      },
      {
        id: 'image-cropper',
        name: 'Image Cropper',
        description: 'Crop images to desired dimensions.',
        icon: <CropIcon />,
      },
      {
        id: 'image-watermark',
        name: 'Image Watermark',
        description: 'Add text or image watermarks.',
        icon: <BrandingWatermarkIcon />,
      },
      {
        id: 'image-color-adjuster',
        name: 'Image Color Adjuster',
        description: 'Adjust image colors and filters.',
        icon: <ColorLensIcon />,
      },
      {
        id: 'image-format-converter',
        name: 'Image Format Converter',
        description: 'Convert images between different formats.',
        icon: <SwapHorizIcon />,
      },
    ],
  },
  'PDF Tools': {
    icon: <PictureAsPdfIcon fontSize="large" />,
    color: '#f44336',
    tools: [
      {
        id: 'pdf-merger',
        name: 'PDF Merger',
        description: 'Combine multiple PDF files into one.',
        icon: <PictureAsPdfIcon />,
      },
      {
        id: 'pdf-splitter',
        name: 'PDF Splitter',
        description: 'Split PDF files into multiple documents.',
        icon: <PictureAsPdfIcon />,
      },
      {
        id: 'pdf-compressor',
        name: 'PDF Compressor',
        description: 'Reduce PDF file size.',
        icon: <CompressIcon />,
      },
      {
        id: 'image-to-pdf',
        name: 'Image to PDF',
        description: 'Convert images to PDF format.',
        icon: <PictureAsPdfIcon />,
      },
    ],
  },
  'Utility Tools': {
    icon: <BuildIcon fontSize="large" />,
    color: '#9c27b0',
    tools: [
      {
        id: 'password-generator',
        name: 'Password Generator',
        description: 'Generate secure passwords.',
        icon: <SecurityIcon />,
      },
      {
        id: 'color-picker',
        name: 'Color Picker',
        description: 'Pick and convert colors.',
        icon: <ColorLensIcon />,
      },
      {
        id: 'qr-code-generator',
        name: 'QR Code Generator',
        description: 'Generate QR codes for various purposes.',
        icon: <QrCodeIcon />,
      },
      {
        id: 'qr-code-scanner',
        name: 'QR Code Scanner',
        description: 'Scan QR codes using your camera.',
        icon: <QrCodeIcon />,
      },
      {
        id: 'unit-converter',
        name: 'Unit Converter',
        description: 'Convert between different units of measurement.',
        icon: <CalculateIcon />,
      },
    ],
  },
  'Professional Tools': {
    icon: <CreateIcon fontSize="large" />,
    color: '#E91E63',
    tools: [
      {
        id: 'resume-builder',
        name: 'Resume Builder',
        description: 'Create professional resumes with customizable templates.',
        icon: <ArticleIcon />,
      },
      {
        id: 'instagram-bio',
        name: 'Instagram Bio Generator',
        description: 'Create engaging Instagram bios with emojis.',
        icon: <CreateIcon />,
      },
    ],
  },
};

function CategoryCard({ category, tools, icon, color }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardContent sx={{ flexGrow: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Box sx={{ 
            backgroundColor: color,
            borderRadius: '50%',
            p: 1,
            mr: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white'
          }}>
            {icon}
          </Box>
          <Typography variant="h5" component="h2">
            {category}
          </Typography>
          <IconButton 
            onClick={() => setExpanded(!expanded)}
            sx={{ ml: 'auto' }}
          >
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>

        {/* Tool names in collapsed view */}
        {!expanded && (
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            {tools.map(tool => tool.name).join(' • ')}
          </Typography>
        )}
        
        <Collapse in={expanded}>
          <List>
            {tools.map((tool) => (
              <ListItem 
                key={tool.id} 
                component={Link} 
                to={`/tools/${tool.id}`}
                sx={{ 
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    borderRadius: 1,
                  }
                }}
              >
                <ListItemIcon sx={{ minWidth: 40 }}>
                  {tool.icon}
                </ListItemIcon>
                <ListItemText 
                  primary={tool.name}
                  secondary={tool.description}
                  primaryTypographyProps={{ variant: 'subtitle1' }}
                  secondaryTypographyProps={{ variant: 'body2' }}
                />
              </ListItem>
            ))}
          </List>
        </Collapse>
      </CardContent>
    </Card>
  );
}

function HomePage() {
  return (
    <Container maxWidth="lg">
      <Box sx={{ mb: 4 }}>
        <Typography variant="h3" component="h1" gutterBottom>
          Tool Hub
        </Typography>
        <Typography variant="h6" color="text.secondary" paragraph>
          A collection of useful online tools to help with your daily tasks
        </Typography>
      </Box>

      <Grid container spacing={3}>
        {Object.entries(toolCategories).map(([category, { tools, icon, color }]) => (
          <Grid item xs={12} sm={6} md={4} key={category}>
            <CategoryCard
              category={category}
              tools={tools}
              icon={icon}
              color={color}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default HomePage;
