import React, { useState } from 'react';
import { Box, Button, Typography, Paper, Alert, CircularProgress, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { toBlobURL } from '@ffmpeg/util';
import { fetchFile } from '@ffmpeg/util';

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

const SUPPORTED_FORMATS = [
  'mp3',
  'wav',
  'ogg',
  'm4a',
  'aac',
  'wma',
  'flac'
];

const AudioConverter = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);
  const [targetFormat, setTargetFormat] = useState('mp3');
  const [fileName, setFileName] = useState('');
  const ffmpegRef = React.useRef(new FFmpeg());
  const [loaded, setLoaded] = useState(false);

  const load = async () => {
    const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.2/dist/umd';
    const ffmpeg = ffmpegRef.current;
    ffmpeg.on('progress', ({ progress }) => {
      setProgress(Math.round(progress * 100));
    });
    await ffmpeg.load({
      coreURL: await toBlobURL(baseURL + '/ffmpeg-core.js', 'text/javascript'),
      wasmURL: await toBlobURL(baseURL + '/ffmpeg-core.wasm', 'application/wasm'),
    });
    setLoaded(true);
  };

  React.useEffect(() => {
    load();
  }, []);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      setLoading(true);
      setError(null);
      setProgress(0);
      setFileName(file.name);

      const ffmpeg = ffmpegRef.current;
      const inputFormat = file.name.split('.').pop().toLowerCase();
      const outputFileName = 'converted_' + file.name.replace(/\.[^/.]+$/, '') + '.' + targetFormat;

      // Write the input file to FFmpeg's virtual file system
      await ffmpeg.writeFile('input.' + inputFormat, await fetchFile(file));

      // Prepare FFmpeg command based on target format
      let ffmpegCommand = ['-i', 'input.' + inputFormat];
      
      // Add format-specific parameters
      switch (targetFormat) {
        case 'm4a':
          ffmpegCommand = ffmpegCommand.concat([
            '-c:a', 'aac',
            '-b:a', '192k',
            '-movflags', '+faststart'
          ]);
          break;
        case 'aac':
          ffmpegCommand = ffmpegCommand.concat([
            '-c:a', 'aac',
            '-b:a', '192k'
          ]);
          break;
        case 'mp3':
          ffmpegCommand = ffmpegCommand.concat([
            '-c:a', 'libmp3lame',
            '-b:a', '192k'
          ]);
          break;
        case 'ogg':
          ffmpegCommand = ffmpegCommand.concat([
            '-c:a', 'libvorbis',
            '-q:a', '4'
          ]);
          break;
        case 'flac':
          ffmpegCommand = ffmpegCommand.concat([
            '-c:a', 'flac'
          ]);
          break;
        case 'wma':
          ffmpegCommand = ffmpegCommand.concat([
            '-c:a', 'wmav2',
            '-b:a', '192k'
          ]);
          break;
        default:
          // For WAV and other formats, use default parameters
          break;
      }

      // Add output filename to command
      ffmpegCommand.push(outputFileName);

      // Run FFmpeg command with format-specific parameters
      await ffmpeg.exec(ffmpegCommand);

      // Read the output file
      const data = await ffmpeg.readFile(outputFileName);
      const blob = new Blob([data.buffer], { type: 'audio/' + targetFormat });
      
      // Create download link
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = outputFileName;
      link.click();
      URL.revokeObjectURL(url);

    } catch (err) {
      console.error('Conversion error:', err);
      setError('Error converting audio: ' + (err.message || 'Unknown error'));
    } finally {
      setLoading(false);
      setProgress(0);
    }
  };

  if (!loaded) {
    return (
      <Box sx={{ p: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
        <Typography sx={{ ml: 2 }}>Loading audio converter...</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" gutterBottom>
        Audio Converter
      </Typography>
      
      <Paper 
        elevation={3} 
        sx={{ 
          p: 3, 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          gap: 2 
        }}
      >
        <Typography variant="body1" textAlign="center">
          Convert your audio files to different formats
        </Typography>

        <FormControl sx={{ m: 1, minWidth: 200 }}>
          <InputLabel>Target Format</InputLabel>
          <Select
            value={targetFormat}
            label="Target Format"
            onChange={(e) => setTargetFormat(e.target.value)}
            disabled={loading}
          >
            {SUPPORTED_FORMATS.map((format) => (
              <MenuItem key={format} value={format}>
                {format.toUpperCase()}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          component="label"
          variant="contained"
          startIcon={<CloudUploadIcon />}
          disabled={loading}
        >
          Upload Audio
          <VisuallyHiddenInput 
            type="file" 
            onChange={handleFileUpload}
            accept=".mp3,.wav,.ogg,.m4a,.aac,.wma,.flac"
          />
        </Button>

        {loading && (
          <Box sx={{ width: '100%', maxWidth: 400 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
              <CircularProgress size={20} />
              <Typography>
                Converting {fileName} to {targetFormat.toUpperCase()}... {progress}%
              </Typography>
            </Box>
          </Box>
        )}

        {error && (
          <Alert severity="error" sx={{ width: '100%', maxWidth: 400 }}>
            {error}
          </Alert>
        )}
      </Paper>
    </Box>
  );
};

export default AudioConverter;
