import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Paper,
  Grid,
  Alert,
  LinearProgress,
  Divider,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  CloudUpload as CloudUploadIcon,
  Description as WordIcon,
} from '@mui/icons-material';
import { Document, Packer, Paragraph, TextRun, HeadingLevel, PageBreak } from 'docx';
import * as pdfjsLib from 'pdfjs-dist/webpack';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
}));

const PDFToWord = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState('');
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    // Initialize PDF.js
    pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;
  }, []);

  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type === 'application/pdf') {
        setSelectedFile(file);
        setError('');
      } else {
        setError('Please select a valid PDF file.');
        setSelectedFile(null);
      }
    }
  };

  const extractTextFromPage = async (page) => {
    try {
      const textContent = await page.getTextContent();
      return textContent.items
        .map(item => item.str)
        .join(' ')
        .replace(/\\s+/g, ' ')
        .trim();
    } catch (err) {
      console.error('Error extracting text:', err);
      return '';
    }
  };

  const convertToWord = async () => {
    if (!selectedFile) {
      setError('Please select a PDF file.');
      return;
    }

    setProcessing(true);
    setError('');
    setProgress(0);

    try {
      const arrayBuffer = await selectedFile.arrayBuffer();
      const loadingTask = pdfjsLib.getDocument({ data: arrayBuffer });
      const pdf = await loadingTask.promise;
      const numPages = pdf.numPages;

      // Create Word document
      const doc = new Document({
        sections: [{
          properties: {},
          children: [
            new Paragraph({
              text: selectedFile.name.replace('.pdf', ''),
              heading: HeadingLevel.TITLE,
              spacing: {
                after: 400,
              },
            }),
          ]
        }]
      });

      // Process each page
      for (let i = 1; i <= numPages; i++) {
        setProgress((i / numPages) * 100);

        const page = await pdf.getPage(i);
        const text = await extractTextFromPage(page);

        // Add page content to Word document
        doc.addSection({
          properties: {},
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: `Page ${i}`,
                  bold: true,
                  size: 28,
                }),
              ],
              spacing: {
                after: 200,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: text || 'No text content found on this page',
                  size: 24,
                }),
              ],
              spacing: {
                after: 200,
              },
            }),
            new Paragraph({
              children: [new PageBreak()],
            }),
          ],
        });
      }

      // Generate Word document as blob
      const blob = await Packer.toBlob(doc);
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${selectedFile.name.replace('.pdf', '')}.docx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);

      setProgress(100);
      setTimeout(() => {
        setProcessing(false);
        setProgress(0);
      }, 1000);
    } catch (err) {
      console.error('Error converting PDF:', err);
      setError('Failed to convert PDF. Please try again with a different file.');
      setProcessing(false);
    }
  };

  return (
    <Box sx={{ maxWidth: 1000, margin: 'auto', padding: 2 }}>
      <StyledPaper>
        <Typography variant="h5" gutterBottom>
          PDF to Word Converter
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              component="label"
              fullWidth
              disabled={processing}
              startIcon={<CloudUploadIcon />}
              sx={{ mb: 2 }}
            >
              Select PDF File
              <input
                type="file"
                hidden
                accept=".pdf"
                onChange={handleFileSelect}
              />
            </Button>
            {!selectedFile && (
              <Typography variant="body2" color="text.secondary" align="center">
                Select a PDF file to convert to Word format
              </Typography>
            )}
            {selectedFile && (
              <Typography variant="body2" color="text.secondary" align="center">
                Selected file: {selectedFile.name}
              </Typography>
            )}
          </Grid>

          {error && (
            <Grid item xs={12}>
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            </Grid>
          )}

          {processing && (
            <Grid item xs={12}>
              <Box sx={{ width: '100%', mb: 2 }}>
                <LinearProgress variant="determinate" value={progress} />
                <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 1 }}>
                  Converting: {Math.round(progress)}%
                </Typography>
              </Box>
            </Grid>
          )}

          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              disabled={!selectedFile || processing}
              onClick={convertToWord}
              startIcon={<WordIcon />}
              color="primary"
            >
              Convert to Word
            </Button>
          </Grid>
        </Grid>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" gutterBottom>
            About PDF to Word Converter
          </Typography>
          <Typography variant="body1" paragraph>
            Convert your PDF documents to editable Word format quickly and easily, right in your browser!
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" gutterBottom>
                Features:
              </Typography>
              <ul>
                <li>Fast, browser-based conversion</li>
                <li>Maintains text content and basic structure</li>
                <li>Supports multi-page documents</li>
                <li>No file size limits</li>
                <li>Secure, client-side processing</li>
                <li>Free to use</li>
              </ul>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" gutterBottom>
                Supported Formats:
              </Typography>
              <ul>
                <li>Input: PDF (.pdf)</li>
                <li>Output: Word (.docx)</li>
              </ul>
              <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
                Tips:
              </Typography>
              <ul>
                <li>For best results, use PDFs with selectable text</li>
                <li>Some complex formatting may not be preserved</li>
                <li>Large files may take longer to process</li>
              </ul>
            </Grid>
          </Grid>
        </Box>
      </StyledPaper>
    </Box>
  );
};

export default PDFToWord;
