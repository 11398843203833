import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Grid,
  Chip,
  IconButton,
  Tooltip,
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ContentCopy as CopyIcon,
  EmojiEmotions as EmojiIcon,
  Refresh as RefreshIcon,
} from '@mui/icons-material';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  margin: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
}));

const bioStyles = {
  professional: [
    "📈 [profession] | [specialty]",
    "💼 [title] at [company]",
    "🎯 Helping [target] achieve [goal]",
    "📚 [credential] | [expertise]",
  ],
  creative: [
    "✨ Creating [art/content] with passion",
    "🎨 [creative field] | [style]",
    "🌈 Making [what] that makes you [feeling]",
    "💫 [catchphrase] | [niche]",
  ],
  personal: [
    "🌟 [hobby] enthusiast | [interest] lover",
    "💖 [passion] | [lifestyle]",
    "🌈 [quote] | [belief]",
    "✨ [location] based [identity]",
  ],
};

const popularEmojis = {
  professional: ['📈', '💼', '🎯', '📚', '💡', '🔍', '📊', '🎓', '💪', '🤝'],
  creative: ['✨', '🎨', '🎭', '🎬', '📸', '🎵', '🎮', '💫', '🌈', '🎪'],
  personal: ['💖', '✌️', '🌟', '🌍', '🏃‍♂️', '🎉', '🌱', '🍕', '📚', '🎵'],
};

const InstagramBioGenerator = () => {
  const [bioText, setBioText] = useState('');
  const [charCount, setCharCount] = useState(0);
  const [selectedStyle, setSelectedStyle] = useState('professional');
  const [copied, setCopied] = useState(false);
  const [keywords, setKeywords] = useState('');
  const [generatedBios, setGeneratedBios] = useState([]);

  useEffect(() => {
    setCharCount(bioText.length);
  }, [bioText]);

  const generateBio = () => {
    const keywordsList = keywords.split(',').map(k => k.trim());
    const style = bioStyles[selectedStyle];
    const emojis = popularEmojis[selectedStyle];
    
    let newBios = [];
    for (let i = 0; i < 3; i++) {
      let template = style[Math.floor(Math.random() * style.length)];
      let bio = template;
      
      // Replace placeholders with keywords if available
      keywordsList.forEach((keyword, index) => {
        const placeholders = template.match(/\[(.*?)\]/g);
        if (placeholders && placeholders[index]) {
          bio = bio.replace(placeholders[index], keyword);
        }
      });
      
      // Add random emojis
      const randomEmojis = Array(2).fill().map(() => 
        emojis[Math.floor(Math.random() * emojis.length)]
      ).join(' ');
      
      bio = bio + ' ' + randomEmojis;
      
      // Replace any remaining placeholders
      bio = bio.replace(/\[(.*?)\]/g, '');
      
      newBios.push(bio);
    }
    
    setGeneratedBios(newBios);
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const addEmoji = (emoji) => {
    setBioText(prev => prev + emoji);
  };

  return (
    <Box sx={{ maxWidth: 800, margin: 'auto', p: 2 }}>
      <Typography variant="h4" gutterBottom>
        Instagram Bio Generator
      </Typography>
      
      <StyledPaper>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Bio Style</InputLabel>
              <Select
                value={selectedStyle}
                onChange={(e) => setSelectedStyle(e.target.value)}
                label="Bio Style"
              >
                <MenuItem value="professional">Professional</MenuItem>
                <MenuItem value="creative">Creative</MenuItem>
                <MenuItem value="personal">Personal</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Enter keywords (comma-separated)"
              value={keywords}
              onChange={(e) => setKeywords(e.target.value)}
              placeholder="e.g., Digital Marketer, SEO, Growth"
              helperText="Enter keywords that describe you or your brand"
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={generateBio}
              startIcon={<RefreshIcon />}
              fullWidth
            >
              Generate Bios
            </Button>
          </Grid>

          {generatedBios.map((bio, index) => (
            <Grid item xs={12} key={index}>
              <Paper sx={{ p: 2, backgroundColor: 'background.default' }}>
                <Typography variant="body1">{bio}</Typography>
                <Box sx={{ mt: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="caption" color="textSecondary">
                    {bio.length}/150 characters
                  </Typography>
                  <Tooltip title="Copy to clipboard">
                    <IconButton onClick={() => handleCopy(bio)} size="small">
                      <CopyIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Paper>
            </Grid>
          ))}

          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Popular Emojis
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {popularEmojis[selectedStyle].map((emoji, index) => (
                <Chip
                  key={index}
                  label={emoji}
                  onClick={() => addEmoji(emoji)}
                  sx={{ cursor: 'pointer' }}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </StyledPaper>

      {copied && (
        <Alert severity="success" sx={{ mt: 2 }}>
          Bio copied to clipboard!
        </Alert>
      )}
    </Box>
  );
};

export default InstagramBioGenerator;
