import React, { useState, useCallback } from 'react';
import {
  Container,
  Paper,
  Typography,
  Button,
  Grid,
  Box,
  Slider,
  CircularProgress,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DownloadIcon from '@mui/icons-material/Download';
import imageCompression from 'browser-image-compression';
import ToolDescription from '../common/ToolDescription';

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

function ImageCompressor() {
  const [originalImage, setOriginalImage] = useState(null);
  const [compressedImage, setCompressedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [quality, setQuality] = useState(80);
  const [maxWidth, setMaxWidth] = useState(1920);
  const [maintainRatio, setMaintainRatio] = useState(true);
  const [originalSize, setOriginalSize] = useState(0);
  const [compressedSize, setCompressedSize] = useState(0);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setOriginalImage(file);
    setOriginalSize(file.size);
    await compressImage(file);
  };

  const compressImage = async (file) => {
    try {
      setLoading(true);
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: maxWidth,
        useWebWorker: true,
        quality: quality / 100,
        maintainAspectRatio: maintainRatio,
      };

      const compressedFile = await imageCompression(file, options);
      setCompressedImage(compressedFile);
      setCompressedSize(compressedFile.size);
    } catch (error) {
      console.error('Error compressing image:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleQualityChange = (_, newValue) => {
    setQuality(newValue);
    if (originalImage) {
      compressImage(originalImage);
    }
  };

  const handleMaxWidthChange = (_, newValue) => {
    setMaxWidth(newValue);
    if (originalImage) {
      compressImage(originalImage);
    }
  };

  const handleDownload = useCallback(() => {
    if (!compressedImage) return;

    const link = document.createElement('a');
    link.href = URL.createObjectURL(compressedImage);
    link.download = `compressed_${compressedImage.name}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [compressedImage]);

  const formatSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const calculateSavings = () => {
    if (!originalSize || !compressedSize) return 0;
    return ((originalSize - compressedSize) / originalSize * 100).toFixed(1);
  };

  return (
    <Container maxWidth="lg">
      <Paper elevation={3} sx={{ p: 4, my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Image Compressor
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          Compress and optimize your images while maintaining quality.
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Button
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
              sx={{ mb: 3 }}
              fullWidth
            >
              Upload Image
              <VisuallyHiddenInput type="file" accept="image/*" onChange={handleImageUpload} />
            </Button>

            <Box sx={{ mb: 3 }}>
              <Typography gutterBottom>Quality: {quality}%</Typography>
              <Slider
                value={quality}
                onChange={handleQualityChange}
                min={0}
                max={100}
                marks={[
                  { value: 0, label: '0%' },
                  { value: 50, label: '50%' },
                  { value: 100, label: '100%' },
                ]}
              />
            </Box>

            <Box sx={{ mb: 3 }}>
              <Typography gutterBottom>Max Width: {maxWidth}px</Typography>
              <Slider
                value={maxWidth}
                onChange={handleMaxWidthChange}
                min={100}
                max={3840}
                step={100}
                marks={[
                  { value: 100, label: '100px' },
                  { value: 1920, label: '1920px' },
                  { value: 3840, label: '3840px' },
                ]}
              />
            </Box>

            <FormControlLabel
              control={
                <Switch
                  checked={maintainRatio}
                  onChange={(e) => setMaintainRatio(e.target.checked)}
                />
              }
              label="Maintain Aspect Ratio"
            />

            {compressedImage && (
              <Button
                variant="contained"
                startIcon={<DownloadIcon />}
                onClick={handleDownload}
                fullWidth
                sx={{ mt: 3 }}
              >
                Download Compressed Image
              </Button>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ textAlign: 'center', mb: 3 }}>
              {loading ? (
                <CircularProgress />
              ) : (
                originalImage && (
                  <>
                    <Typography variant="h6" gutterBottom>
                      Compression Results
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      Original Size: {formatSize(originalSize)}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      Compressed Size: {formatSize(compressedSize)}
                    </Typography>
                    <Typography variant="body1" color="success.main" sx={{ fontWeight: 'bold' }}>
                      Space Saved: {calculateSavings()}%
                    </Typography>
                  </>
                )
              )}
            </Box>

            {compressedImage && (
              <Box
                sx={{
                  width: '100%',
                  height: '300px',
                  border: '1px solid',
                  borderColor: 'divider',
                  borderRadius: 1,
                  overflow: 'hidden',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img
                  src={URL.createObjectURL(compressedImage)}
                  alt="Compressed preview"
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'contain',
                  }}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </Paper>

      <ToolDescription
        title="Image Compressor"
        description="A powerful tool to compress and optimize images for web use while maintaining quality. Reduce file sizes without significantly impacting visual quality."
        benefits={[
          "Reduce image file sizes significantly",
          "Maintain image quality with adjustable compression",
          "Optimize images for web use",
          "Save storage space and bandwidth",
          "Faster website loading times",
          "Batch processing support"
        ]}
        useCases={[
          "Optimizing images for websites",
          "Reducing email attachment sizes",
          "Preparing images for social media",
          "E-commerce product images",
          "Blog post images",
          "Portfolio optimization"
        ]}
        howTo={[
          "Click 'Upload Image' to select an image",
          "Adjust the quality slider as needed",
          "Set maximum width if required",
          "Toggle aspect ratio maintenance",
          "Preview the compressed result",
          "Download the compressed image"
        ]}
      />
    </Container>
  );
}

export default ImageCompressor;
