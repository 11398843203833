import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useTheme,
  useMediaQuery,
  ListItemIcon,
} from '@mui/material';
import {
  Menu as MenuIcon,
  Transform as TransformIcon,
  Image as ImageIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  PictureAsPdf as PictureAsPdfIcon,
  VideoFile as VideoIcon,
  Merge as MergeIcon,
  ContentCut as ContentCutIcon,
} from '@mui/icons-material';

const toolCategories = [
  {
    category: 'Conversion Tools',
    icon: <TransformIcon />,
    tools: [
      { name: 'Unit Converter', icon: <TransformIcon />, path: '/tools/unit-converter' },
      { name: 'QR Code Scanner', icon: <TransformIcon />, path: '/tools/qr-code-scanner' },
    ],
  },
  {
    category: 'Data Tools',
    icon: <TransformIcon />,
    tools: [
      { name: 'CSV Tool', icon: <TransformIcon />, path: '/tools/csv-tool' },
      { name: 'HTML Tool', icon: <TransformIcon />, path: '/tools/html-tool' },
      { name: 'SQL Tool', icon: <TransformIcon />, path: '/tools/sql-tool' },
    ],
  },
  {
    category: 'Image Tools',
    icon: <ImageIcon />,
    tools: [
      { name: 'Image Resizer', icon: <ImageIcon />, path: '/tools/image-resizer' },
      { name: 'Image Cropper', icon: <ImageIcon />, path: '/tools/image-cropper' },
      { name: 'Image Color Adjuster', icon: <ImageIcon />, path: '/tools/image-color-adjuster' },
      { name: 'Image Format Converter', icon: <ImageIcon />, path: '/tools/image-format-converter' },
      { name: 'Image Watermark', icon: <ImageIcon />, path: '/tools/image-watermark' },
    ],
  },
  {
    category: 'Video Tools',
    icon: <VideoIcon />,
    tools: [
      { name: 'Video Watermark', icon: <VideoIcon />, path: '/tools/video-watermark' },
      { name: 'Video Trimmer', icon: <VideoIcon />, path: '/tools/video-trimmer' },
      { name: 'Video Compressor', icon: <VideoIcon />, path: '/tools/video-compressor' },
      { name: 'Video Thumbnail', icon: <VideoIcon />, path: '/tools/video-thumbnail' },
      { name: 'Video Merger', icon: <VideoIcon />, path: '/tools/video-merger' },
      { name: 'Video Converter', icon: <VideoIcon />, path: '/tools/video-converter' },
      { name: 'Video Editor', icon: <VideoIcon />, path: '/tools/video-editor' },
    ],
  },
  {
    category: 'PDF Tools',
    icon: <PictureAsPdfIcon />,
    tools: [
      { name: 'PDF Merger', icon: <MergeIcon />, path: '/tools/pdf-merger' },
      { name: 'PDF Splitter', icon: <ContentCutIcon />, path: '/tools/pdf-splitter' },
      { name: 'PDF to Word', icon: <PictureAsPdfIcon />, path: '/tools/pdf-to-word' },
    ],
  },
];

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClick = (event, category) => {
    setAnchorEl(event.currentTarget);
    setSelectedCategory(category);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedCategory(null);
  };

  return (
    <AppBar position="static">
      <Container>
        <Toolbar>
          <Typography
            variant="h6"
            component={RouterLink}
            to="/"
            sx={{
              textDecoration: 'none',
              color: 'inherit',
              flexGrow: 1,
            }}
          >
            Tool Hub
          </Typography>

          {isMobile ? (
            <>
              <IconButton
                color="inherit"
                onClick={(e) => handleClick(e, null)}
                edge="end"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {toolCategories.map((category) => (
                  <div key={category.category}>
                    <MenuItem
                      onClick={() => setSelectedCategory(category)}
                      sx={{ fontWeight: 'bold' }}
                    >
                      <ListItemIcon>{category.icon}</ListItemIcon>
                      {category.category}
                    </MenuItem>
                    {selectedCategory?.category === category.category &&
                      category.tools.map((tool) => (
                        <MenuItem
                          key={tool.name}
                          component={RouterLink}
                          to={tool.path}
                          onClick={handleClose}
                          sx={{ pl: 4 }}
                        >
                          <ListItemIcon>{tool.icon}</ListItemIcon>
                          {tool.name}
                        </MenuItem>
                      ))}
                  </div>
                ))}
              </Menu>
            </>
          ) : (
            <Box sx={{ display: 'flex', gap: 2 }}>
              {toolCategories.map((category) => (
                <div key={category.category}>
                  <Button
                    color="inherit"
                    onClick={(e) => handleClick(e, category)}
                    endIcon={<KeyboardArrowDownIcon />}
                  >
                    {category.category}
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl) && selectedCategory?.category === category.category}
                    onClose={handleClose}
                  >
                    {category.tools.map((tool) => (
                      <MenuItem
                        key={tool.name}
                        component={RouterLink}
                        to={tool.path}
                        onClick={handleClose}
                      >
                        <ListItemIcon>{tool.icon}</ListItemIcon>
                        {tool.name}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              ))}
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
