import React, { useState, useRef } from 'react';
import {
  Box,
  Button,
  Typography,
  Paper,
  TextField,
  CircularProgress,
  Alert,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { CloudUpload as CloudUploadIcon, ContentCopy as ContentCopyIcon } from '@mui/icons-material';

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

const VoiceToText = () => {
  const [file, setFile] = useState(null);
  const [transcription, setTranscription] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [transcriptionEngine, setTranscriptionEngine] = useState('browser');
  const [language, setLanguage] = useState('en-US');
  const fileRef = useRef(null);

  const languages = [
    { code: 'en-US', name: 'English (US)' },
    { code: 'es-ES', name: 'Spanish' },
    { code: 'fr-FR', name: 'French' },
    { code: 'de-DE', name: 'German' },
    { code: 'it-IT', name: 'Italian' },
    { code: 'pt-BR', name: 'Portuguese' },
    { code: 'hi-IN', name: 'Hindi' },
    { code: 'ja-JP', name: 'Japanese' },
    { code: 'ko-KR', name: 'Korean' },
    { code: 'zh-CN', name: 'Chinese (Simplified)' },
  ];

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      setFile(uploadedFile);
      setTranscription('');
      setError(null);
    }
  };

  const transcribeUsingBrowser = async () => {
    try {
      setLoading(true);
      setError(null);

      const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
      recognition.lang = language;
      recognition.continuous = true;
      recognition.interimResults = false;

      const audioUrl = URL.createObjectURL(file);
      const audio = new Audio(audioUrl);

      return new Promise((resolve, reject) => {
        let fullTranscript = '';

        recognition.onresult = (event) => {
          const transcript = Array.from(event.results)
            .map(result => result[0].transcript)
            .join(' ');
          fullTranscript += transcript + ' ';
          setTranscription(fullTranscript.trim());
        };

        recognition.onerror = (event) => {
          reject(new Error(`Speech recognition error: ${event.error}`));
        };

        recognition.onend = () => {
          resolve(fullTranscript);
        };

        audio.onended = () => {
          recognition.stop();
        };

        recognition.start();
        audio.play();
      });

    } catch (err) {
      throw new Error('Browser transcription failed: ' + err.message);
    }
  };

  const transcribeUsingWhisper = async () => {
    try {
      setLoading(true);
      setError(null);

      const formData = new FormData();
      formData.append('file', file);
      formData.append('language', language.split('-')[0]);

      const response = await fetch('YOUR_WHISPER_API_ENDPOINT', {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': 'Bearer YOUR_API_KEY'
        }
      });

      if (!response.ok) {
        throw new Error('Whisper API request failed');
      }

      const data = await response.json();
      setTranscription(data.text);

    } catch (err) {
      throw new Error('Whisper transcription failed: ' + err.message);
    }
  };

  const handleTranscribe = async () => {
    if (!file) {
      setError('Please upload an audio file first');
      return;
    }

    try {
      setLoading(true);
      setError(null);

      if (transcriptionEngine === 'browser') {
        await transcribeUsingBrowser();
      } else {
        await transcribeUsingWhisper();
      }

    } catch (err) {
      console.error('Transcription error:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(transcription)
      .then(() => {
        // Could add a toast notification here
        console.log('Transcription copied to clipboard');
      })
      .catch(err => {
        console.error('Failed to copy:', err);
      });
  };

  return (
    <Paper elevation={3} sx={{ p: 3, maxWidth: 800, mx: 'auto', my: 4 }}>
      <Typography variant="h5" gutterBottom>
        Voice to Text Converter
      </Typography>

      <Box sx={{ mb: 3 }}>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <FormLabel>Transcription Engine</FormLabel>
          <Select
            value={transcriptionEngine}
            onChange={(e) => setTranscriptionEngine(e.target.value)}
            disabled={loading}
          >
            <MenuItem value="browser">Browser (Free, Limited)</MenuItem>
            <MenuItem value="whisper">Whisper API (Paid, Accurate)</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ mb: 2 }}>
          <FormLabel>Language</FormLabel>
          <Select
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
            disabled={loading}
          >
            {languages.map((lang) => (
              <MenuItem key={lang.code} value={lang.code}>
                {lang.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          component="label"
          variant="contained"
          startIcon={<CloudUploadIcon />}
          disabled={loading}
          fullWidth
        >
          Upload Audio File
          <VisuallyHiddenInput 
            type="file" 
            accept="audio/*"
            onChange={handleFileUpload}
            ref={fileRef}
          />
        </Button>

        {file && (
          <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary' }}>
            Selected file: {file.name}
          </Typography>
        )}
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      <Button
        variant="contained"
        onClick={handleTranscribe}
        disabled={!file || loading}
        fullWidth
        sx={{ mb: 3 }}
      >
        {loading ? <CircularProgress size={24} /> : 'Transcribe Audio'}
      </Button>

      {transcription && (
        <Box sx={{ mb: 3 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
            <Typography variant="h6">Transcription</Typography>
            <Button
              startIcon={<ContentCopyIcon />}
              onClick={copyToClipboard}
              size="small"
            >
              Copy
            </Button>
          </Box>
          <TextField
            multiline
            rows={6}
            value={transcription}
            onChange={(e) => setTranscription(e.target.value)}
            fullWidth
            variant="outlined"
          />
        </Box>
      )}

      <Alert severity="info" sx={{ mt: 2 }}>
        {transcriptionEngine === 'browser' ? (
          'Browser transcription is free but has limitations. For better accuracy, switch to Whisper API.'
        ) : (
          'Whisper API provides more accurate transcription but requires an API key.'
        )}
      </Alert>
    </Paper>
  );
};

export default VoiceToText;
