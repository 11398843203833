import React, { useState } from 'react';
import {
  Container,
  Paper,
  Typography,
  Grid,
  Box,
  TextField,
  IconButton,
  Card,
  CardContent,
  Tooltip,
  Snackbar,
  Alert,
  Slider,
} from '@mui/material';
import {
  ContentCopy as CopyIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
  Palette as PaletteIcon,
} from '@mui/icons-material';
import { SketchPicker } from 'react-color';
import ToolDescription from '../common/ToolDescription';

function ColorPicker() {
  const [color, setColor] = useState('#1976d2');
  const [savedColors, setSavedColors] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [analogousAngle, setAnalogousAngle] = useState(30);

  // Convert HEX to RGB
  const hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  };

  // Convert RGB to HSL
  const rgbToHsl = (r, g, b) => {
    r /= 255;
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;

    if (max === min) {
      h = s = 0;
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r: h = (g - b) / d + (g < b ? 6 : 0); break;
        case g: h = (b - r) / d + 2; break;
        case b: h = (r - g) / d + 4; break;
        default: h = 0;
      }
      h /= 6;
    }

    return {
      h: Math.round(h * 360),
      s: Math.round(s * 100),
      l: Math.round(l * 100)
    };
  };

  // Generate color variations
  const generateColorPalette = () => {
    const rgb = hexToRgb(color);
    const hsl = rgbToHsl(rgb.r, rgb.g, rgb.b);
    
    // Generate analogous colors
    const analogous = [
      { h: (hsl.h - analogousAngle + 360) % 360, s: hsl.s, l: hsl.l },
      { h: (hsl.h + analogousAngle) % 360, s: hsl.s, l: hsl.l },
    ];

    // Generate monochromatic colors
    const monochromatic = [
      { ...hsl, l: Math.max(0, hsl.l - 30) },
      { ...hsl, l: Math.min(100, hsl.l + 30) },
    ];

    return {
      analogous,
      monochromatic,
    };
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setSnackbar({
      open: true,
      message: 'Color code copied to clipboard!',
      severity: 'success'
    });
  };

  const handleSaveColor = () => {
    if (savedColors.includes(color)) {
      setSnackbar({
        open: true,
        message: 'Color already saved!',
        severity: 'warning'
      });
      return;
    }
    setSavedColors([...savedColors, color]);
    setSnackbar({
      open: true,
      message: 'Color saved to palette!',
      severity: 'success'
    });
  };

  const handleDeleteColor = (colorToDelete) => {
    setSavedColors(savedColors.filter(c => c !== colorToDelete));
  };

  const ColorCard = ({ title, colorCode, onCopy }) => (
    <Card variant="outlined" sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="subtitle2" gutterBottom>
          {title}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              width: 40,
              height: 40,
              borderRadius: 1,
              mr: 2,
              bgcolor: colorCode,
              border: '1px solid rgba(0, 0, 0, 0.12)',
            }}
          />
          <Typography variant="body2" sx={{ flexGrow: 1 }}>
            {colorCode}
          </Typography>
          <IconButton size="small" onClick={() => onCopy(colorCode)}>
            <CopyIcon fontSize="small" />
          </IconButton>
        </Box>
      </CardContent>
    </Card>
  );

  const rgb = hexToRgb(color);
  const hsl = rgb ? rgbToHsl(rgb.r, rgb.g, rgb.b) : { h: 0, s: 0, l: 0 };
  const palette = generateColorPalette();

  return (
    <Container maxWidth="lg">
      <Paper elevation={3} sx={{ p: 4, my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Color Picker & Converter
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          Pick colors, convert between formats, and generate palettes.
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Box sx={{ mb: 4 }}>
              <SketchPicker
                color={color}
                onChange={(newColor) => setColor(newColor.hex)}
                disableAlpha
                width="100%"
              />
            </Box>

            <Typography variant="h6" gutterBottom>
              Color Codes
            </Typography>
            <ColorCard
              title="HEX"
              colorCode={color}
              onCopy={handleCopy}
            />
            <ColorCard
              title="RGB"
              colorCode={rgb ? `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})` : ''}
              onCopy={handleCopy}
            />
            <ColorCard
              title="HSL"
              colorCode={`hsl(${hsl.h}, ${hsl.s}%, ${hsl.l}%)`}
              onCopy={handleCopy}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ mb: 4 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6" sx={{ flexGrow: 1 }}>
                  Saved Colors
                </Typography>
                <Tooltip title="Save current color">
                  <IconButton onClick={handleSaveColor} color="primary">
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <Grid container spacing={1}>
                {savedColors.map((savedColor, index) => (
                  <Grid item key={index}>
                    <Box sx={{ position: 'relative' }}>
                      <Box
                        sx={{
                          width: 50,
                          height: 50,
                          bgcolor: savedColor,
                          borderRadius: 1,
                          border: '1px solid rgba(0, 0, 0, 0.12)',
                          cursor: 'pointer',
                          '&:hover': {
                            opacity: 0.9,
                          },
                        }}
                        onClick={() => setColor(savedColor)}
                      />
                      <IconButton
                        size="small"
                        sx={{
                          position: 'absolute',
                          top: -8,
                          right: -8,
                          bgcolor: 'background.paper',
                          '&:hover': { bgcolor: 'background.paper' },
                        }}
                        onClick={() => handleDeleteColor(savedColor)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Typography variant="h6" gutterBottom>
              Color Variations
            </Typography>
            
            <Typography variant="subtitle2" gutterBottom>
              Analogous Angle: {analogousAngle}°
            </Typography>
            <Slider
              value={analogousAngle}
              onChange={(_, newValue) => setAnalogousAngle(newValue)}
              min={10}
              max={60}
              step={1}
              marks={[
                { value: 10, label: '10°' },
                { value: 30, label: '30°' },
                { value: 60, label: '60°' },
              ]}
              sx={{ mb: 3 }}
            />

            <Typography variant="subtitle1" gutterBottom>
              Analogous Colors
            </Typography>
            <Grid container spacing={1} sx={{ mb: 3 }}>
              {palette.analogous.map((color, index) => (
                <Grid item xs={6} key={index}>
                  <Box
                    sx={{
                      height: 50,
                      bgcolor: `hsl(${color.h}, ${color.s}%, ${color.l}%)`,
                      borderRadius: 1,
                      border: '1px solid rgba(0, 0, 0, 0.12)',
                    }}
                  />
                </Grid>
              ))}
            </Grid>

            <Typography variant="subtitle1" gutterBottom>
              Monochromatic Colors
            </Typography>
            <Grid container spacing={1}>
              {palette.monochromatic.map((color, index) => (
                <Grid item xs={6} key={index}>
                  <Box
                    sx={{
                      height: 50,
                      bgcolor: `hsl(${color.h}, ${color.s}%, ${color.l}%)`,
                      borderRadius: 1,
                      border: '1px solid rgba(0, 0, 0, 0.12)',
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <ToolDescription
        title="Color Picker"
        description="A versatile color selection tool that helps you find, create, and manage colors for your design projects. Features multiple color formats, color palettes, and easy copying options for seamless integration into your workflow."
        benefits={[
          "Multiple color format support (HEX, RGB, HSL)",
          "Color palette generation",
          "Copy colors in different formats",
          "Color shade and tint generation",
          "Save favorite colors",
          "Real-time color preview"
        ]}
        useCases={[
          "Web design color selection",
          "Creating color schemes for projects",
          "Finding accessible color combinations",
          "Extracting colors from images",
          "Creating gradient color stops",
          "Matching brand colors"
        ]}
        howTo={[
          "Use the color picker to select a base color",
          "Adjust hue, saturation, and lightness with sliders",
          "View color in different formats (HEX, RGB, HSL)",
          "Generate color variations and palettes",
          "Copy color codes in your preferred format",
          "Save colors to your favorites for later use"
        ]}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default ColorPicker;
