import React, { useState, useRef, useCallback } from 'react';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
  Box,
  Slider,
  FormControl,
  InputLabel,
  Switch,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import { SketchPicker } from 'react-color';
import DownloadIcon from '@mui/icons-material/Download';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SettingsIcon from '@mui/icons-material/Settings';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ToolDescription from '../common/ToolDescription';

function QRCodeTool() {
  const [text, setText] = useState('');
  const [size, setSize] = useState(256);
  const [fgColor, setFgColor] = useState('#000000');
  const [bgColor, setBgColor] = useState('#ffffff');
  const [errorLevel, setErrorLevel] = useState('H');
  const [showFgPicker, setShowFgPicker] = useState(false);
  const [showBgPicker, setShowBgPicker] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [textBelow, setTextBelow] = useState('');
  const [logo, setLogo] = useState('');
  const [showLogo, setShowLogo] = useState(false);
  const [resolution, setResolution] = useState(1000);
  const qrRef = useRef(null);

  const generateQRCodeImage = useCallback(() => {
    return new Promise((resolve) => {
      const canvas = document.createElement('canvas');
      canvas.width = resolution;
      canvas.height = resolution;
      const ctx = canvas.getContext('2d');
      
      // Draw QR Code
      const qrSize = resolution * 0.8; // 80% of the resolution
      const qrPosition = (resolution - qrSize) / 2;
      const svgString = new XMLSerializer().serializeToString(qrRef.current.querySelector('svg'));
      const img = new Image();
      img.onload = () => {
        ctx.drawImage(img, qrPosition, qrPosition, qrSize, qrSize);
        
        // Draw text below
        if (textBelow) {
          const fontSize = resolution * 0.05;
          ctx.font = `bold ${fontSize}px Arial`;
          ctx.fillStyle = 'black';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'top';
          const textY = qrPosition + qrSize + (resolution * 0.05);
          ctx.fillText(textBelow, resolution / 2, textY);
        }
        
        resolve(canvas.toDataURL('image/png'));
      };
      img.src = 'data:image/svg+xml;base64,' + btoa(svgString);
    });
  }, [resolution, textBelow]);

  const handleDownload = async () => {
    try {
      const dataUrl = await generateQRCodeImage();
      const link = document.createElement('a');
      link.download = 'qr-code.png';
      link.href = dataUrl;
      link.click();
    } catch (err) {
      console.error(err);
    }
  };

  const copyToClipboard = async () => {
    try {
      const dataUrl = await generateQRCodeImage();
      const blob = await fetch(dataUrl).then(res => res.blob());
      await navigator.clipboard.write([
        new ClipboardItem({ 'image/png': blob })
      ]);
    } catch (err) {
      console.error(err);
    }
  };

  const ColorButton = ({ color, onClick }) => (
    <Button
      onClick={onClick}
      sx={{
        width: '100%',
        height: '40px',
        backgroundColor: color,
        border: '1px solid #ccc',
        '&:hover': {
          backgroundColor: color,
          opacity: 0.9,
        },
      }}
    />
  );

  return (
    <Container maxWidth="lg">
      <Paper elevation={3} sx={{ p: 4, my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          QR Code Generator
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          Create customized QR codes with advanced features like logos and custom text.
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Text or URL"
              value={text}
              onChange={(e) => setText(e.target.value)}
              multiline
              rows={4}
              sx={{ mb: 3 }}
            />

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <SettingsIcon sx={{ mr: 1 }} />
                  <Typography>Advanced Options</Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <TextField
                  fullWidth
                  label="Text Below QR Code"
                  value={textBelow}
                  onChange={(e) => setTextBelow(e.target.value)}
                  sx={{ mb: 2 }}
                />
                
                <TextField
                  fullWidth
                  label="Logo URL"
                  value={logo}
                  onChange={(e) => setLogo(e.target.value)}
                  sx={{ mb: 2 }}
                />

                <FormControlLabel
                  control={
                    <Switch
                      checked={showLogo}
                      onChange={(e) => setShowLogo(e.target.checked)}
                    />
                  }
                  label="Show Logo in QR Code"
                  sx={{ mb: 2 }}
                />

                <Typography gutterBottom>Resolution: {resolution}x{resolution}</Typography>
                <Slider
                  value={resolution}
                  onChange={(_, newValue) => setResolution(newValue)}
                  min={100}
                  max={1000}
                  step={10}
                  marks={[
                    { value: 100, label: '100' },
                    { value: 500, label: '500' },
                    { value: 1000, label: '1000' },
                  ]}
                  sx={{ mb: 3 }}
                />

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography gutterBottom>Foreground Color</Typography>
                    <ColorButton color={fgColor} onClick={() => setShowFgPicker(!showFgPicker)} />
                    {showFgPicker && (
                      <Box sx={{ position: 'absolute', zIndex: 2, mt: 1 }}>
                        <Box
                          sx={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0 }}
                          onClick={() => setShowFgPicker(false)}
                        />
                        <SketchPicker
                          color={fgColor}
                          onChange={(color) => setFgColor(color.hex)}
                        />
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography gutterBottom>Background Color</Typography>
                    <ColorButton color={bgColor} onClick={() => setShowBgPicker(!showBgPicker)} />
                    {showBgPicker && (
                      <Box sx={{ position: 'absolute', zIndex: 2, mt: 1 }}>
                        <Box
                          sx={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0 }}
                          onClick={() => setShowBgPicker(false)}
                        />
                        <SketchPicker
                          color={bgColor}
                          onChange={(color) => setBgColor(color.hex)}
                        />
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Box sx={{ mt: 3 }}>
              <Button
                variant="contained"
                onClick={handleDownload}
                startIcon={<DownloadIcon />}
                disabled={!text || isGenerating}
                sx={{ mr: 2 }}
              >
                Download
              </Button>
              <Button
                variant="contained"
                onClick={copyToClipboard}
                startIcon={<ContentCopyIcon />}
                disabled={!text || isGenerating}
              >
                Copy
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box
              ref={qrRef}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '300px',
                bgcolor: 'background.paper',
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 1,
                p: 2,
              }}
            >
              {text ? (
                <QRCodeSVG
                  value={text}
                  size={size}
                  level={errorLevel}
                  fgColor={fgColor}
                  bgColor={bgColor}
                  includeMargin
                />
              ) : (
                <Typography variant="body1" color="text.secondary">
                  Enter text to generate QR code
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <ToolDescription
        title="QR Code Generator"
        description="Create customizable QR codes with advanced features. Generate QR codes for URLs, text, contact information, with options for logos, custom text, colors, and more."
        benefits={[
          "Advanced customization options",
          "Logo integration support",
          "Custom text below QR code",
          "Adjustable resolution",
          "Color customization with picker",
          "High-quality PNG download"
        ]}
        useCases={[
          "Creating branded QR codes",
          "Adding logos to QR codes",
          "Generating high-resolution QR codes",
          "Custom marketing materials",
          "Professional business cards",
          "Product packaging and labels"
        ]}
        howTo={[
          "Enter your text or URL in the input field",
          "Use Advanced Options for customization",
          "Add a logo URL if desired",
          "Add text below the QR code",
          "Adjust colors using the color picker",
          "Download or copy the final QR code"
        ]}
      />
    </Container>
  );
}

export default QRCodeTool;
