import React, { useState, useRef } from 'react';
import { Box, Button, CircularProgress, Typography, Paper, Alert, FormControlLabel, Checkbox } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as pdfjsLib from 'pdfjs-dist';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import JSZip from 'jszip';

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

const PDFToImage = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);
  const [zipDownload, setZipDownload] = useState(true);
  const canvasRef = useRef(null);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (file.type !== 'application/pdf') {
      setError('Please upload a PDF file');
      return;
    }

    try {
      setLoading(true);
      setError(null);
      setProgress(0);

      const arrayBuffer = await file.arrayBuffer();
      const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
      const totalPages = pdf.numPages;
      const images = [];

      // Create canvas if it doesn't exist
      if (!canvasRef.current) {
        canvasRef.current = document.createElement('canvas');
      }
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');

      // Process each page
      for (let pageNum = 1; pageNum <= totalPages; pageNum++) {
        // Update progress
        setProgress(Math.round((pageNum / totalPages) * 100));

        // Get the page
        const page = await pdf.getPage(pageNum);
        const viewport = page.getViewport({ scale: 1.5 });

        // Set canvas dimensions
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        // Render PDF page to canvas
        await page.render({
          canvasContext: context,
          viewport: viewport
        }).promise;

        // Convert to image and store
        const image = canvas.toDataURL('image/png');
        images.push({
          data: image,
          name: `${file.name.replace('.pdf', '')}_page_${pageNum}.png`
        });
      }

      // Download images
      if (zipDownload && totalPages > 1) {
        // Create ZIP file
        const zip = new JSZip();
        images.forEach(img => {
          // Remove data URL prefix and convert to binary
          const imageData = img.data.replace(/^data:image\/(png|jpg);base64,/, '');
          zip.file(img.name, imageData, { base64: true });
        });

        // Generate and download ZIP
        const zipBlob = await zip.generateAsync({ type: 'blob' });
        const zipUrl = URL.createObjectURL(zipBlob);
        const link = document.createElement('a');
        link.href = zipUrl;
        link.download = `${file.name.replace('.pdf', '')}_images.zip`;
        link.click();
        URL.revokeObjectURL(zipUrl);
      } else {
        // Download individual images
        images.forEach(img => {
          const link = document.createElement('a');
          link.href = img.data;
          link.download = img.name;
          link.click();
        });
      }

    } catch (err) {
      setError('Error converting PDF to image: ' + err.message);
    } finally {
      setLoading(false);
      setProgress(0);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" gutterBottom>
        PDF to Image Converter
      </Typography>
      
      <Paper 
        elevation={3} 
        sx={{ 
          p: 3, 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          gap: 2 
        }}
      >
        <Typography variant="body1" textAlign="center" gutterBottom>
          Upload a PDF file to convert all its pages to PNG images.
        </Typography>

        <FormControlLabel
          control={
            <Checkbox
              checked={zipDownload}
              onChange={(e) => setZipDownload(e.target.checked)}
              disabled={loading}
            />
          }
          label="Download as ZIP file (recommended for multiple pages)"
        />

        <Button
          component="label"
          variant="contained"
          startIcon={<CloudUploadIcon />}
          disabled={loading}
        >
          Upload PDF
          <VisuallyHiddenInput type="file" accept=".pdf" onChange={handleFileUpload} />
        </Button>

        {loading && (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
            <CircularProgress variant="determinate" value={progress} />
            <Typography>Converting page {Math.round(progress)}%</Typography>
          </Box>
        )}

        {error && (
          <Alert severity="error" sx={{ width: '100%' }}>
            {error}
          </Alert>
        )}
      </Paper>
    </Box>
  );
};

export default PDFToImage;
