import React, { useState } from 'react';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Grid,
  Box,
  Alert,
  Snackbar,
  IconButton,
  Tooltip,
  Tabs,
  Tab,
  Button,
} from '@mui/material';
import {
  ContentCopy as CopyIcon,
  Clear as ClearIcon,
  Download as DownloadIcon,
} from '@mui/icons-material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import ToolDescription from '../common/ToolDescription';

const defaultMarkdown = `# Welcome to Markdown Preview

## Basic Syntax

### Text Formatting
**Bold text** and *italic text*
~~Strikethrough text~~

### Lists
1. First item
2. Second item
   - Nested item
   - Another nested item

### Links and Images
[Visit OpenAI](https://www.openai.com)
![Alt text](https://via.placeholder.com/150)

### Code
Inline \`code\` example

\`\`\`javascript
function greet(name) {
  console.log(\`Hello, \${name}!\`);
}
\`\`\`

### Tables
| Header 1 | Header 2 |
|----------|----------|
| Cell 1   | Cell 2   |

### Blockquotes
> This is a blockquote
> Multiple lines

### Task Lists
- [x] Completed task
- [ ] Pending task

### Horizontal Rule
---
`;

function MarkdownTool() {
  const [input, setInput] = useState('');
  const [tab, setTab] = useState(0);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const handleCopy = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setSnackbar({
        open: true,
        message: 'Copied to clipboard!',
        severity: 'success',
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to copy text',
        severity: 'error',
      });
    }
  };

  const handleClear = () => {
    setInput('');
  };

  const handleLoadExample = () => {
    setInput(defaultMarkdown);
  };

  const handleDownload = () => {
    try {
      const blob = new Blob([input], { type: 'text/markdown' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'document.md';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to download markdown',
        severity: 'error',
      });
    }
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Container maxWidth="lg">
      <Paper elevation={3} sx={{ p: 4, my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Markdown Preview
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          Write and preview Markdown with real-time rendering. Supports GitHub Flavored Markdown.
        </Typography>

        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
          <Tabs value={tab} onChange={handleTabChange}>
            <Tab label="Split View" />
            <Tab label="Editor" />
            <Tab label="Preview" />
          </Tabs>
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', gap: 2, mb: 3, flexWrap: 'wrap' }}>
              <Button
                variant="outlined"
                onClick={handleLoadExample}
              >
                Load Example
              </Button>
              <Button
                variant="outlined"
                onClick={handleClear}
                startIcon={<ClearIcon />}
              >
                Clear
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleCopy(input)}
                startIcon={<CopyIcon />}
              >
                Copy Markdown
              </Button>
              <Button
                variant="outlined"
                onClick={handleDownload}
                startIcon={<DownloadIcon />}
                disabled={!input}
              >
                Download .md
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              {/* Editor */}
              {(tab === 0 || tab === 1) && (
                <Grid item xs={tab === 0 ? 6 : 12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={20}
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    placeholder="Write your Markdown here..."
                    sx={{
                      '& .MuiInputBase-root': {
                        fontFamily: 'monospace',
                      },
                    }}
                  />
                </Grid>
              )}

              {/* Preview */}
              {(tab === 0 || tab === 2) && (
                <Grid item xs={tab === 0 ? 6 : 12}>
                  <Paper
                    variant="outlined"
                    sx={{
                      p: 2,
                      minHeight: '500px',
                      '& img': {
                        maxWidth: '100%',
                        height: 'auto',
                      },
                      '& table': {
                        borderCollapse: 'collapse',
                        width: '100%',
                        mb: 2,
                      },
                      '& th, & td': {
                        border: '1px solid rgba(0, 0, 0, 0.12)',
                        padding: '8px',
                      },
                      '& pre': {
                        margin: 0,
                      },
                    }}
                  >
                    <ReactMarkdown
                      remarkPlugins={[remarkGfm, remarkBreaks]}
                      components={{
                        code({ node, inline, className, children, ...props }) {
                          const match = /language-(\w+)/.exec(className || '');
                          return !inline && match ? (
                            <SyntaxHighlighter
                              style={materialLight}
                              language={match[1]}
                              PreTag="div"
                              {...props}
                            >
                              {String(children).replace(/\n$/, '')}
                            </SyntaxHighlighter>
                          ) : (
                            <code className={className} {...props}>
                              {children}
                            </code>
                          );
                        },
                      }}
                    >
                      {input}
                    </ReactMarkdown>
                  </Paper>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <ToolDescription
        title="Markdown Preview"
        description="A feature-rich Markdown editor and previewer that supports GitHub Flavored Markdown (GFM). Write and preview your Markdown content in real-time, with support for tables, code syntax highlighting, task lists, and more. Perfect for documentation, README files, and content creation."
        benefits={[
          "Real-time Markdown preview as you type",
          "GitHub Flavored Markdown support",
          "Code syntax highlighting for multiple languages",
          "Multiple view modes (split, editor-only, preview-only)",
          "Support for tables and task lists",
          "Export to Markdown or HTML"
        ]}
        useCases={[
          "Writing documentation and README files",
          "Creating blog posts and articles",
          "Taking formatted notes",
          "Writing technical specifications",
          "Preparing content for GitHub repositories",
          "Creating formatted messages for communication"
        ]}
        howTo={[
          "Type or paste your Markdown content in the editor",
          "See the rendered preview update in real-time",
          "Use the view mode buttons to switch between split, editor, or preview mode",
          "Click 'Load Example' to see Markdown syntax examples",
          "Use the copy button to copy the Markdown or HTML",
          "Download your content as a .md file"
        ]}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default MarkdownTool;
