import React, { useState } from 'react';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
  Box,
  Snackbar,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ToolDescription from '../common/ToolDescription';

function TextCaseConverter() {
  const [inputText, setInputText] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleTextChange = (event) => {
    setInputText(event.target.value);
  };

  const convertCase = (type) => {
    switch (type) {
      case 'upper':
        setInputText(inputText.toUpperCase());
        break;
      case 'lower':
        setInputText(inputText.toLowerCase());
        break;
      case 'title':
        setInputText(
          inputText
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
        );
        break;
      case 'sentence':
        setInputText(
          inputText.toLowerCase().replace(/(^\w|\.\s+\w)/g, letter => letter.toUpperCase())
        );
        break;
      default:
        break;
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(inputText).then(() => {
      setOpenSnackbar(true);
    });
  };

  return (
    <Container maxWidth="lg">
      <Paper elevation={3} sx={{ p: 4, my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Text Case Converter
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          Convert text between different cases instantly. Supports multiple case formats and special characters.
        </Typography>

        <TextField
          fullWidth
          multiline
          rows={6}
          variant="outlined"
          value={inputText}
          onChange={handleTextChange}
          placeholder="Enter your text here..."
          sx={{ mb: 3 }}
        />

        <Grid container spacing={2} sx={{ mb: 3 }}>
          <Grid item xs={12} sm={6} md={3}>
            <Button
              fullWidth
              variant="contained"
              onClick={() => convertCase('upper')}
              sx={{ height: '100%' }}
            >
              UPPERCASE
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Button
              fullWidth
              variant="contained"
              onClick={() => convertCase('lower')}
              sx={{ height: '100%' }}
            >
              lowercase
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Button
              fullWidth
              variant="contained"
              onClick={() => convertCase('title')}
              sx={{ height: '100%' }}
            >
              Title Case
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Button
              fullWidth
              variant="contained"
              onClick={() => convertCase('sentence')}
              sx={{ height: '100%' }}
            >
              Sentence case
            </Button>
          </Grid>
        </Grid>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="outlined"
            startIcon={<ContentCopyIcon />}
            onClick={copyToClipboard}
            disabled={!inputText}
          >
            Copy to Clipboard
          </Button>
        </Box>
      </Paper>

      <ToolDescription
        title="Text Case Converter"
        description="The Text Case Converter is a powerful tool that allows you to transform text between different case formats with a single click. Whether you need to format titles, prepare code variables, or standardize text formatting, this tool makes it quick and easy."
        benefits={[
          "Instantly convert text between multiple case formats",
          "Preserve special characters and punctuation",
          "Copy converted text with one click",
          "Process multiple lines of text at once",
          "Real-time preview of conversions",
          "Support for programming-friendly formats like camelCase and snake_case"
        ]}
        useCases={[
          "Formatting titles and headings for articles or documents",
          "Converting variable names between different programming conventions",
          "Standardizing text formatting in databases",
          "Preparing text for social media posts",
          "Creating consistent naming conventions in documentation",
          "Converting between title case and sentence case for citations"
        ]}
        howTo={[
          "Paste or type your text in the input field",
          "Choose your desired case format from the available options",
          "The converted text will appear automatically in the result field",
          "Click the copy button to copy the converted text to your clipboard",
          "Use the clear button to start fresh with new text",
          "For multiple conversions, simply change the case format again"
        ]}
      />

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        message="Text copied to clipboard!"
      />
    </Container>
  );
}

export default TextCaseConverter;
