import React, { useState } from 'react';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Slider,
  Box,
  Snackbar,
  Grid,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RefreshIcon from '@mui/icons-material/Refresh';
import ToolDescription from '../common/ToolDescription';

function PasswordGenerator() {
  const [password, setPassword] = useState('');
  const [length, setLength] = useState(12);
  const [options, setOptions] = useState({
    uppercase: true,
    lowercase: true,
    numbers: true,
    symbols: true,
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const characters = {
    uppercase: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    lowercase: 'abcdefghijklmnopqrstuvwxyz',
    numbers: '0123456789',
    symbols: '!@#$%^&*()_+-=[]{}|;:,.<>?',
  };

  const generatePassword = () => {
    let charset = '';
    let newPassword = '';

    // Build character set based on selected options
    Object.keys(options).forEach((option) => {
      if (options[option]) {
        charset += characters[option];
      }
    });

    // Ensure at least one option is selected
    if (charset === '') {
      setOptions({ ...options, lowercase: true });
      charset = characters.lowercase;
    }

    // Generate password
    for (let i = 0; i < length; i++) {
      newPassword += charset.charAt(Math.floor(Math.random() * charset.length));
    }

    setPassword(newPassword);
  };

  const handleOptionChange = (option) => {
    setOptions({
      ...options,
      [option]: !options[option],
    });
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(password).then(() => {
      setOpenSnackbar(true);
    });
  };

  return (
    <Container maxWidth="lg">
      <Paper elevation={3} sx={{ p: 4, my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Password Generator
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          Generate secure passwords with customizable options.
        </Typography>

        <Box sx={{ mb: 4 }}>
          <TextField
            fullWidth
            value={password}
            placeholder="Generated password will appear here"
            InputProps={{
              readOnly: true,
              endAdornment: (
                <Button
                  variant="contained"
                  startIcon={<ContentCopyIcon />}
                  onClick={copyToClipboard}
                  disabled={!password}
                  sx={{ ml: 1 }}
                >
                  Copy
                </Button>
              ),
            }}
          />
        </Box>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography gutterBottom>Password Length: {length}</Typography>
            <Slider
              value={length}
              onChange={(_, newValue) => setLength(newValue)}
              min={8}
              max={32}
              marks={[
                { value: 8, label: '8' },
                { value: 20, label: '20' },
                { value: 32, label: '32' },
              ]}
              sx={{ mb: 3 }}
            />

            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={options.uppercase}
                    onChange={() => handleOptionChange('uppercase')}
                  />
                }
                label="Include Uppercase Letters (A-Z)"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={options.lowercase}
                    onChange={() => handleOptionChange('lowercase')}
                  />
                }
                label="Include Lowercase Letters (a-z)"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={options.numbers}
                    onChange={() => handleOptionChange('numbers')}
                  />
                }
                label="Include Numbers (0-9)"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={options.symbols}
                    onChange={() => handleOptionChange('symbols')}
                  />
                }
                label="Include Symbols (!@#$%^&*)"
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                p: 3,
                bgcolor: 'action.hover',
                borderRadius: 1,
              }}
            >
              <Typography variant="h6" gutterBottom>
                Password Strength Tips
              </Typography>
              <Typography variant="body2" color="text.secondary" paragraph>
                • Use at least 12 characters
                <br />
                • Mix uppercase and lowercase letters
                <br />
                • Include numbers and symbols
                <br />
                • Avoid personal information
                <br />
                • Use unique passwords for each account
              </Typography>
              <Button
                variant="contained"
                size="large"
                startIcon={<RefreshIcon />}
                onClick={generatePassword}
                sx={{ mt: 2 }}
              >
                Generate Password
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <ToolDescription
        title="Password Generator"
        description="A secure password generator that creates strong, random passwords based on your specific requirements. Generate passwords that meet modern security standards while being customizable to match various platform requirements."
        benefits={[
          "Generate cryptographically secure passwords",
          "Customize password length and character types",
          "Copy passwords with one click",
          "Password strength indicator",
          "Multiple passwords generation",
          "Platform-specific password requirements"
        ]}
        useCases={[
          "Creating secure account passwords",
          "Generating passwords for multiple accounts",
          "Meeting specific password requirements",
          "Creating temporary access credentials",
          "Setting up new user accounts",
          "Updating old passwords to more secure ones"
        ]}
        howTo={[
          "Select desired password length using the slider",
          "Choose character types (uppercase, lowercase, numbers, symbols)",
          "Click 'Generate' to create a new password",
          "Use 'Copy' to copy the password to clipboard",
          "Generate multiple passwords if needed",
          "Check password strength indicator for security level"
        ]}
      />

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        message="Password copied to clipboard!"
      />
    </Container>
  );
}

export default PasswordGenerator;
