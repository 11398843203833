import React, { useState, useCallback } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Alert,
  IconButton,
  Tooltip,
} from '@mui/material';
import {
  Upload as UploadIcon,
  Download as DownloadIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
  ContentCopy as CopyIcon,
} from '@mui/icons-material';
import ToolDescription from '../common/ToolDescription';

function CSVTool() {
  const [headers, setHeaders] = useState([]);
  const [data, setData] = useState([]);
  const [error, setError] = useState('');
  const [copied, setCopied] = useState(false);

  const handleFileUpload = (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const text = e.target.result;
        const lines = text.split('\n');
        if (lines.length === 0) {
          setError('The CSV file appears to be empty');
          return;
        }

        // Parse headers
        const headerRow = lines[0].split(',').map(h => h.trim());
        setHeaders(headerRow);

        // Parse data rows
        const dataRows = lines.slice(1)
          .filter(line => line.trim() !== '')
          .map(line => {
            const values = line.split(',').map(cell => cell.trim());
            // Pad with empty strings if row is shorter than headers
            while (values.length < headerRow.length) {
              values.push('');
            }
            return values;
          });
        setData(dataRows);
        setError('');
      } catch (err) {
        setError('Failed to parse CSV file. Please check the format.');
      }
    };
    reader.onerror = () => {
      setError('Failed to read the file');
    };
    reader.readAsText(file);
  };

  const downloadCSV = () => {
    if (headers.length === 0) {
      setError('No data to download');
      return;
    }

    const csvContent = [
      headers.join(','),
      ...data.map(row => row.join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'exported_data.csv';
    link.click();
  };

  const addRow = () => {
    setData([...data, new Array(headers.length).fill('')]);
  };

  const deleteRow = (index) => {
    setData(data.filter((_, i) => i !== index));
  };

  const updateCell = (rowIndex, colIndex, value) => {
    const newData = [...data];
    newData[rowIndex][colIndex] = value;
    setData(newData);
  };

  const copyToClipboard = () => {
    const csvContent = [
      headers.join(','),
      ...data.map(row => row.join(','))
    ].join('\n');

    navigator.clipboard.writeText(csvContent);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <Container maxWidth="lg">
      <Paper elevation={3} sx={{ p: 4, my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          CSV Editor
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          Upload, edit, and download CSV files with ease
        </Typography>

        <Box sx={{ mb: 4, display: 'flex', gap: 2 }}>
          <Button
            variant="contained"
            component="label"
            startIcon={<UploadIcon />}
          >
            Upload CSV
            <input
              type="file"
              hidden
              accept=".csv"
              onChange={handleFileUpload}
            />
          </Button>

          <Button
            variant="contained"
            startIcon={<DownloadIcon />}
            onClick={downloadCSV}
            disabled={headers.length === 0}
          >
            Download CSV
          </Button>

          <Button
            variant="contained"
            startIcon={<CopyIcon />}
            onClick={copyToClipboard}
            disabled={headers.length === 0}
          >
            Copy to Clipboard
          </Button>

          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={addRow}
            disabled={headers.length === 0}
          >
            Add Row
          </Button>
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {copied && (
          <Alert severity="success" sx={{ mb: 2 }}>
            CSV content copied to clipboard!
          </Alert>
        )}

        {headers.length > 0 && (
          <TableContainer component={Paper} sx={{ mb: 4 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  {headers.map((header, index) => (
                    <TableCell key={index}>{header}</TableCell>
                  ))}
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    <TableCell>{rowIndex + 1}</TableCell>
                    {row.map((cell, colIndex) => (
                      <TableCell key={colIndex}>
                        <TextField
                          size="small"
                          value={cell}
                          onChange={(e) => updateCell(rowIndex, colIndex, e.target.value)}
                          fullWidth
                        />
                      </TableCell>
                    ))}
                    <TableCell>
                      <Tooltip title="Delete Row">
                        <IconButton
                          size="small"
                          onClick={() => deleteRow(rowIndex)}
                          color="error"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>

      <ToolDescription
        title="CSV Editor"
        description="A powerful tool for working with CSV (Comma-Separated Values) files. Upload, edit, and download CSV files with an intuitive interface."
        benefits={[
          "Easy CSV file upload and download",
          "Interactive table editor",
          "Add and delete rows",
          "Copy to clipboard functionality",
          "Real-time editing capabilities",
          "Mobile-friendly interface"
        ]}
        useCases={[
          "Edit data spreadsheets",
          "Clean up CSV data",
          "Create new CSV files from scratch",
          "Quick data table modifications",
          "Format CSV files",
          "Prepare data for import into other tools"
        ]}
        howTo={[
          "Upload a CSV file using the 'Upload CSV' button",
          "Edit cells directly in the table",
          "Add new rows with the 'Add Row' button",
          "Delete rows using the delete icon",
          "Download the modified CSV file",
          "Copy the entire CSV content to clipboard"
        ]}
      />
    </Container>
  );
}

export default CSVTool;
