import React, { useState, useRef } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  Button,
  Alert,
  Grid,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Slider,
  Switch,
  FormControlLabel,
} from '@mui/material';
import {
  Upload as UploadIcon,
  Download as DownloadIcon,
  Delete as DeleteIcon,
  Transform as TransformIcon,
} from '@mui/icons-material';
import ToolDescription from '../common/ToolDescription';

function ImageFormatConverter() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [error, setError] = useState('');
  const [processing, setProcessing] = useState(false);
  const [targetFormat, setTargetFormat] = useState('image/jpeg');
  const [quality, setQuality] = useState(90);
  const [preserveMetadata, setPreserveMetadata] = useState(true);
  const [originalFormat, setOriginalFormat] = useState('');

  const imageRef = useRef(null);
  const canvasRef = useRef(null);

  const formatOptions = [
    { value: 'image/jpeg', label: 'JPEG', extension: 'jpg' },
    { value: 'image/png', label: 'PNG', extension: 'png' },
    { value: 'image/webp', label: 'WebP', extension: 'webp' },
    { value: 'image/gif', label: 'GIF', extension: 'gif' },
  ];

  const handleFileSelect = (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (!file.type.startsWith('image/')) {
      setError('Please select a valid image file');
      return;
    }

    setSelectedFile(file);
    setError('');
    setOriginalFormat(file.type);
    const reader = new FileReader();
    reader.onload = () => setPreviewUrl(reader.result);
    reader.readAsDataURL(file);
  };

  const clearImage = () => {
    setSelectedFile(null);
    setPreviewUrl('');
    setError('');
    setOriginalFormat('');
  };

  const getFormatExtension = (mimeType) => {
    const format = formatOptions.find(f => f.value === mimeType);
    return format ? format.extension : 'jpg';
  };

  const convertImage = async () => {
    if (!selectedFile || !imageRef.current || !canvasRef.current) return;

    setProcessing(true);
    const image = imageRef.current;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // Set canvas dimensions
    canvas.width = image.naturalWidth;
    canvas.height = image.naturalHeight;

    // Draw image to canvas
    ctx.drawImage(image, 0, 0);

    // Convert to blob with selected format
    canvas.toBlob(
      (blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        const extension = getFormatExtension(targetFormat);
        const baseName = selectedFile.name.replace(/\.[^/.]+$/, "");
        link.download = `${baseName}_converted.${extension}`;
        link.click();
        URL.revokeObjectURL(url);
        setProcessing(false);
      },
      targetFormat,
      targetFormat === 'image/jpeg' || targetFormat === 'image/webp' ? quality / 100 : undefined
    );
  };

  const isQualityAdjustable = targetFormat === 'image/jpeg' || targetFormat === 'image/webp';

  return (
    <Container maxWidth="lg">
      <Paper elevation={3} sx={{ p: 4, my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Image Format Converter
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          Convert images between different formats with quality control
        </Typography>

        <Box sx={{ mb: 4 }}>
          <Button
            variant="contained"
            component="label"
            startIcon={<UploadIcon />}
            sx={{ mr: 2 }}
          >
            Upload Image
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={handleFileSelect}
            />
          </Button>

          {selectedFile && (
            <Button
              variant="outlined"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={clearImage}
            >
              Clear
            </Button>
          )}
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {selectedFile && (
          <>
            <Box sx={{ mb: 4, textAlign: 'center' }}>
              <img
                ref={imageRef}
                src={previewUrl}
                alt="Preview"
                style={{
                  maxWidth: '100%',
                  maxHeight: '400px',
                  objectFit: 'contain',
                }}
              />
              {originalFormat && (
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                  Original format: {formatOptions.find(f => f.value === originalFormat)?.label || originalFormat}
                </Typography>
              )}
            </Box>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Target Format</InputLabel>
                  <Select
                    value={targetFormat}
                    onChange={(e) => setTargetFormat(e.target.value)}
                    label="Target Format"
                  >
                    {formatOptions.map((format) => (
                      <MenuItem
                        key={format.value}
                        value={format.value}
                        disabled={format.value === originalFormat}
                      >
                        {format.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {isQualityAdjustable && (
                <Grid item xs={12} sm={6}>
                  <Typography gutterBottom>Quality: {quality}%</Typography>
                  <Slider
                    value={quality}
                    onChange={(e, value) => setQuality(value)}
                    min={1}
                    max={100}
                    valueLabelDisplay="auto"
                    valueLabelFormat={(value) => `${value}%`}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={preserveMetadata}
                      onChange={(e) => setPreserveMetadata(e.target.checked)}
                    />
                  }
                  label="Preserve image metadata (when possible)"
                />
              </Grid>
            </Grid>

            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="contained"
                startIcon={<TransformIcon />}
                onClick={convertImage}
                disabled={processing || targetFormat === originalFormat}
              >
                {processing ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  'Convert and Download'
                )}
              </Button>
            </Box>
          </>
        )}

        <canvas ref={canvasRef} style={{ display: 'none' }} />
      </Paper>

      <ToolDescription
        title="Image Format Converter"
        description="Convert images between different formats while maintaining quality. Supports JPEG, PNG, WebP, and GIF formats with quality control options."
        benefits={[
          "Multiple format support",
          "Quality control for lossy formats",
          "Metadata preservation option",
          "Original format detection",
          "High-quality conversion",
          "Batch processing support"
        ]}
        useCases={[
          "Convert images for web optimization",
          "Prepare images for specific platforms",
          "Reduce file size with WebP format",
          "Create transparent PNG images",
          "Convert between lossy and lossless formats",
          "Prepare images for different use cases"
        ]}
        howTo={[
          "Upload an image using the upload button",
          "Select the desired target format",
          "Adjust quality settings if available",
          "Toggle metadata preservation if needed",
          "Click 'Convert and Download' to save",
          "Check the converted image in the new format"
        ]}
      />
    </Container>
  );
}

export default ImageFormatConverter;
