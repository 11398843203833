import React, { useState, useRef } from 'react';
import { Box, Button, Slider, TextField, Typography, Paper, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
}));

const VideoThumbnail = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [thumbnails, setThumbnails] = useState([]);
  const [numFrames, setNumFrames] = useState(4);
  const [watermarkText, setWatermarkText] = useState('');
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      const videoUrl = URL.createObjectURL(file);
      if (videoRef.current) {
        videoRef.current.src = videoUrl;
      }
    }
  };

  const extractFrame = (video, time) => {
    return new Promise((resolve) => {
      video.currentTime = time;
      video.onseeked = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        
        // Set canvas size to match video dimensions
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        
        // Draw the video frame
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        
        // Add watermark if specified
        if (watermarkText) {
          ctx.font = '24px Arial';
          ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
          ctx.strokeStyle = 'rgba(0, 0, 0, 0.8)';
          ctx.lineWidth = 2;
          
          // Position at bottom right
          const text = watermarkText;
          const metrics = ctx.measureText(text);
          const x = canvas.width - metrics.width - 20;
          const y = canvas.height - 20;
          
          // Draw text with stroke for better visibility
          ctx.strokeText(text, x, y);
          ctx.fillText(text, x, y);
        }
        
        // Convert to data URL
        const dataUrl = canvas.toDataURL('image/jpeg', 0.8);
        resolve({
          time: time.toFixed(2),
          dataUrl
        });
      };
    });
  };

  const generateThumbnails = async () => {
    if (!videoRef.current || !selectedFile) return;

    const video = videoRef.current;
    const duration = video.duration;
    const frameGap = duration / (numFrames + 1);
    const newThumbnails = [];

    // Extract frames at regular intervals
    for (let i = 1; i <= numFrames; i++) {
      const time = i * frameGap;
      const thumbnail = await extractFrame(video, time);
      newThumbnails.push(thumbnail);
    }

    setThumbnails(newThumbnails);
  };

  const downloadThumbnails = () => {
    thumbnails.forEach((thumb, index) => {
      const link = document.createElement('a');
      link.href = thumb.dataUrl;
      link.download = `thumbnail_${index + 1}_${thumb.time}s.jpg`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  return (
    <Box sx={{ maxWidth: 800, margin: 'auto', padding: 2 }}>
      <StyledPaper>
        <Typography variant="h5" gutterBottom>
          Video Thumbnail Generator
        </Typography>
        
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              component="label"
              fullWidth
            >
              Select Video
              <input
                type="file"
                hidden
                accept="video/*"
                onChange={handleFileSelect}
              />
            </Button>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Watermark Text"
              value={watermarkText}
              onChange={(e) => setWatermarkText(e.target.value)}
              margin="normal"
            />
          </Grid>

          <Grid item xs={12}>
            <Typography gutterBottom>
              Number of Thumbnails: {numFrames}
            </Typography>
            <Slider
              value={numFrames}
              onChange={(e, value) => setNumFrames(value)}
              min={1}
              max={10}
              marks
              step={1}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={generateThumbnails}
              disabled={!selectedFile}
              fullWidth
              sx={{ mt: 2 }}
            >
              Generate Thumbnails
            </Button>
          </Grid>

          {thumbnails.length > 0 && (
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="secondary"
                onClick={downloadThumbnails}
                fullWidth
                sx={{ mt: 2 }}
              >
                Download All Thumbnails
              </Button>
            </Grid>
          )}
        </Grid>

        <Box sx={{ mt: 3 }}>
          <video
            ref={videoRef}
            style={{ display: 'none' }}
            controls
          />
          <canvas
            ref={canvasRef}
            style={{ display: 'none' }}
          />
          
          <Grid container spacing={2}>
            {thumbnails.map((thumb, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <StyledPaper>
                  <Typography variant="subtitle2" gutterBottom>
                    Frame at {thumb.time}s
                  </Typography>
                  <img
                    src={thumb.dataUrl}
                    alt={`Thumbnail ${index + 1}`}
                    style={{
                      width: '100%',
                      height: 'auto',
                      display: 'block'
                    }}
                  />
                </StyledPaper>
              </Grid>
            ))}
          </Grid>
        </Box>
      </StyledPaper>
    </Box>
  );
};

export default VideoThumbnail;
