import React, { useState } from 'react';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Snackbar,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FormatClearIcon from '@mui/icons-material/FormatClear';
import { js_beautify as jsBeautify } from 'js-beautify';
import 'js-beautify/js/lib/beautify-css';
import 'js-beautify/js/lib/beautify-html';
import ToolDescription from '../common/ToolDescription';

const languageOptions = [
  { value: 'javascript', label: 'JavaScript' },
  { value: 'html', label: 'HTML' },
  { value: 'css', label: 'CSS' },
  { value: 'json', label: 'JSON' },
];

const defaultOptions = {
  indent_size: 2,
  indent_char: ' ',
  max_preserve_newlines: 2,
  preserve_newlines: true,
  keep_array_indentation: false,
  break_chained_methods: false,
  indent_scripts: 'normal',
  brace_style: 'collapse',
  space_before_conditional: true,
  unescape_strings: false,
  jslint_happy: false,
  end_with_newline: true,
  wrap_line_length: 0,
  indent_inner_html: false,
  comma_first: false,
  e4x: false,
  indent_empty_lines: false,
};

function CodeBeautifier() {
  const [code, setCode] = useState('');
  const [language, setLanguage] = useState('javascript');
  const [beautifiedCode, setBeautifiedCode] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });

  const beautifyCode = () => {
    if (!code.trim()) return;

    try {
      let result = '';
      switch (language) {
        case 'javascript':
          result = jsBeautify(code, { ...defaultOptions });
          break;
        case 'html':
          result = jsBeautify.html(code, {
            ...defaultOptions,
            unformatted: ['code', 'pre', 'em', 'strong', 'span'],
          });
          break;
        case 'css':
          result = jsBeautify.css(code, { ...defaultOptions });
          break;
        case 'json':
          result = JSON.stringify(JSON.parse(code), null, 2);
          break;
        default:
          result = code;
      }
      setBeautifiedCode(result);
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Error: ${error.message}`,
      });
    }
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(beautifiedCode);
      setSnackbar({
        open: true,
        message: 'Code copied to clipboard!',
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to copy code.',
      });
    }
  };

  const handleClear = () => {
    setCode('');
    setBeautifiedCode('');
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Container maxWidth="lg">
      <Paper elevation={3} sx={{ p: 4, my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Code Beautifier
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          Format and beautify your code with support for multiple languages.
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12}>
            <FormControl fullWidth sx={{ mb: 3 }}>
              <InputLabel>Language</InputLabel>
              <Select
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
                label="Language"
              >
                {languageOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              Input Code
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={15}
              value={code}
              onChange={(e) => setCode(e.target.value)}
              placeholder="Paste your code here..."
              variant="outlined"
              sx={{
                mb: 2,
                fontFamily: 'monospace',
                '& .MuiInputBase-input': {
                  fontFamily: 'monospace',
                },
              }}
            />
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                variant="contained"
                onClick={beautifyCode}
                disabled={!code.trim()}
                sx={{ flexGrow: 1 }}
              >
                Beautify
              </Button>
              <IconButton
                onClick={handleClear}
                disabled={!code.trim()}
                color="error"
                sx={{ border: 1, borderColor: 'divider' }}
              >
                <FormatClearIcon />
              </IconButton>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              Beautified Code
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={15}
              value={beautifiedCode}
              variant="outlined"
              InputProps={{ readOnly: true }}
              sx={{
                mb: 2,
                fontFamily: 'monospace',
                '& .MuiInputBase-input': {
                  fontFamily: 'monospace',
                },
              }}
            />
            <Button
              variant="contained"
              startIcon={<ContentCopyIcon />}
              onClick={handleCopy}
              disabled={!beautifiedCode}
              fullWidth
            >
              Copy Beautified Code
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <ToolDescription
        title="Code Beautifier"
        description="A powerful code formatting tool that supports multiple programming languages. Format and beautify your code with customizable options for better readability and maintainability."
        benefits={[
          "Instant code formatting for multiple languages",
          "Consistent code style and indentation",
          "Improved code readability",
          "Easy copy-paste functionality",
          "Support for JavaScript, HTML, CSS, and JSON",
          "Preserves code functionality while beautifying"
        ]}
        useCases={[
          "Formatting minified code",
          "Standardizing code style in projects",
          "Cleaning up generated code",
          "Preparing code for documentation",
          "Debugging complex code structures",
          "Learning proper code formatting"
        ]}
        howTo={[
          "Select the programming language",
          "Paste your code in the input field",
          "Click the 'Beautify' button",
          "Review the formatted code",
          "Copy the beautified code using the copy button",
          "Clear the input to start over"
        ]}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbar.message}
      />
    </Container>
  );
}

export default CodeBeautifier;
