import React from 'react';
import { createBrowserRouter, RouterProvider, createRoutesFromElements, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// Layout components
import Layout from './components/layout/Layout';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import UnitConverter from './components/tools/UnitConverter';
import QRCodeScanner from './components/tools/QRCodeScanner';
import CSVTool from './components/tools/CSVTool';
import HTMLTool from './components/tools/HTMLTool';
import SQLTool from './components/tools/SQLTool';
import ImageResizer from './components/tools/ImageResizer';
import ImageCropper from './components/tools/ImageCropper';
import ImageColorAdjuster from './components/tools/ImageColorAdjuster';
import ImageFormatConverter from './components/tools/ImageFormatConverter';
import ImageWatermark from './components/tools/ImageWatermark';
import VideoWatermark from './components/tools/VideoWatermark';
import VideoTrimmer from './components/tools/VideoTrimmer';
import VideoCompressor from './components/tools/VideoCompressor';
import VideoThumbnail from './components/tools/VideoThumbnail';
import VideoMerger from './components/tools/VideoMerger';
import VideoConverter from './components/tools/VideoConverter';
import VideoEditor from './components/tools/VideoEditor';
import PDFToWord from './components/tools/PDFToWord';
import PDFMerger from './components/tools/PDFMerger';
import PDFSplitter from './components/tools/PDFSplitter';
import PDFToImage from './components/tools/PDFToImage';
import ImageToPDF from './components/tools/ImageToPDF';
import PDFCompressor from './components/tools/PDFCompressor';
import AudioConverter from './components/tools/AudioConverter';
import AudioTrimmer from './components/tools/AudioTrimmer';
import AudioMerger from './components/tools/AudioMerger';
import VoiceRecorder from './components/tools/VoiceRecorder';
import AudioEditor from './components/tools/AudioEditor';
import VoiceToText from './components/tools/VoiceToText';
import SubtitleTool from './components/tools/SubtitleTool';
import ScreenRecorder from './components/tools/ScreenRecorder';
import MemeGenerator from './components/tools/MemeGenerator';
import InstagramBioGenerator from './components/tools/InstagramBioGenerator';
import ResumeBuilder from './components/tools/ResumeBuilder';

// Pages
import HomePage from './pages/HomePage';
import ToolPage from './pages/ToolPage';

const theme = createTheme({
  palette: {
    mode: 'light',
  },
});

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Layout />}>
        <Route index element={<HomePage />} />
        <Route path="tools">
          <Route path=":toolId" element={<ToolPage />} />
          <Route path="unit-converter" element={<UnitConverter />} />
          <Route path="qr-code-scanner" element={<QRCodeScanner />} />
          <Route path="csv-tool" element={<CSVTool />} />
          <Route path="html-tool" element={<HTMLTool />} />
          <Route path="sql-tool" element={<SQLTool />} />
          <Route path="image-resizer" element={<ImageResizer />} />
          <Route path="image-cropper" element={<ImageCropper />} />
          <Route path="image-color-adjuster" element={<ImageColorAdjuster />} />
          <Route path="image-format-converter" element={<ImageFormatConverter />} />
          <Route path="image-watermark" element={<ImageWatermark />} />
          <Route path="video-watermark" element={<VideoWatermark />} />
          <Route path="video-trimmer" element={<VideoTrimmer />} />
          <Route path="video-compressor" element={<VideoCompressor />} />
          <Route path="video-thumbnail" element={<VideoThumbnail />} />
          <Route path="video-merger" element={<VideoMerger />} />
          <Route path="video-converter" element={<VideoConverter />} />
          <Route path="video-editor" element={<VideoEditor />} />
          <Route path="pdf-to-word" element={<PDFToWord />} />
          <Route path="pdf-merger" element={<PDFMerger />} />
          <Route path="pdf-splitter" element={<PDFSplitter />} />
          <Route path="pdf-to-image" element={<PDFToImage />} />
          <Route path="image-to-pdf" element={<ImageToPDF />} />
          <Route path="pdf-compressor" element={<PDFCompressor />} />
          <Route path="audio-converter" element={<AudioConverter />} />
          <Route path="audio-trimmer" element={<AudioTrimmer />} />
          <Route path="audio-merger" element={<AudioMerger />} />
          <Route path="voice-recorder" element={<VoiceRecorder />} />
          <Route path="audio-editor" element={<AudioEditor />} />
          <Route path="voice-to-text" element={<VoiceToText />} />
          <Route path="subtitle-tool" element={<SubtitleTool />} />
          <Route path="screen-recorder" element={<ScreenRecorder />} />
          <Route path="meme-generator" element={<MemeGenerator />} />
          <Route path="resume-builder" element={<ResumeBuilder />} />
          <Route path="instagram-bio" element={<InstagramBioGenerator />} />
        </Route>
      </Route>
    ),
    {
      future: {
        v7_startTransition: true,
        v7_relativeSplatPath: true
      }
    }
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
