import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Paper,
  Grid,
  Slider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Alert,
  Divider,
  LinearProgress,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Brightness6 as BrightnessIcon,
  Contrast as ContrastIcon,
  Rotate90DegreesCcw as RotateIcon,
  Crop as CropIcon,
  Save as SaveIcon,
  Undo as UndoIcon,
  CloudUpload as CloudUploadIcon,
} from '@mui/icons-material';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
}));

const VideoPreview = styled('video')({
  width: '100%',
  maxHeight: '400px',
  marginTop: '10px',
});

const ControlButton = styled(IconButton)(({ theme }) => ({
  margin: theme.spacing(1),
}));

const VideoEditor = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [videoUrl, setVideoUrl] = useState('');
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState('');
  const [progress, setProgress] = useState(0);
  const [brightness, setBrightness] = useState(100);
  const [contrast, setContrast] = useState(100);
  const [rotation, setRotation] = useState(0);
  const [isCropping, setIsCropping] = useState(false);
  const [crop, setCrop] = useState(null);
  const [completedCrop, setCompletedCrop] = useState(null);

  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const requestRef = useRef();

  useEffect(() => {
    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
      if (videoRef.current) {
        videoRef.current.pause();
      }
    };
  }, []);

  useEffect(() => {
    if (videoRef.current && previewCanvasRef.current && !isCropping) {
      updatePreview();
    }
  }, [brightness, contrast, rotation, isCropping]);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type.startsWith('video/')) {
        setSelectedFile(file);
        const url = URL.createObjectURL(file);
        setVideoUrl(url);
        setError('');
        // Reset all adjustments
        setBrightness(100);
        setContrast(100);
        setRotation(0);
        setCrop(null);
        setCompletedCrop(null);
      } else {
        setError('Please select a valid video file.');
      }
    }
  };

  const applyFilters = (ctx, sourceX, sourceY, sourceWidth, sourceHeight) => {
    ctx.filter = `brightness(${brightness}%) contrast(${contrast}%)`;
    
    // Apply rotation
    if (rotation !== 0) {
      const centerX = ctx.canvas.width / 2;
      const centerY = ctx.canvas.height / 2;
      ctx.save();
      ctx.translate(centerX, centerY);
      ctx.rotate((rotation * Math.PI) / 180);
      ctx.translate(-centerX, -centerY);
    }

    // Draw the frame with crop if active
    if (completedCrop) {
      ctx.drawImage(
        videoRef.current,
        completedCrop.x,
        completedCrop.y,
        completedCrop.width,
        completedCrop.height,
        0,
        0,
        ctx.canvas.width,
        ctx.canvas.height
      );
    } else {
      ctx.drawImage(videoRef.current, sourceX, sourceY, sourceWidth, sourceHeight);
    }

    if (rotation !== 0) {
      ctx.restore();
    }

    ctx.filter = 'none';
  };

  const updatePreview = () => {
    if (!videoRef.current || !previewCanvasRef.current) return;

    const video = videoRef.current;
    const canvas = previewCanvasRef.current;
    const ctx = canvas.getContext('2d');

    // Set canvas size
    if (rotation % 180 === 0) {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
    } else {
      canvas.width = video.videoHeight;
      canvas.height = video.videoWidth;
    }

    // Clear canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    
    // Apply current filters and transformations
    applyFilters(ctx, 0, 0, video.videoWidth, video.videoHeight);

    // Request next frame only if video is playing
    if (!video.paused && !video.ended) {
      requestRef.current = requestAnimationFrame(updatePreview);
    }
  };

  const startPreview = () => {
    if (requestRef.current) {
      cancelAnimationFrame(requestRef.current);
    }
    
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
    }
    updatePreview();
  };

  const saveVideo = async () => {
    if (!selectedFile) {
      setError('Please select a video file.');
      return;
    }

    if (processing) {
      return;
    }

    setProcessing(true);
    setError('');
    setProgress(0);

    try {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');

      // Pause preview updates
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
      video.pause();

      // Set canvas size based on rotation and crop
      if (rotation % 180 === 0) {
        canvas.width = completedCrop ? completedCrop.width : video.videoWidth;
        canvas.height = completedCrop ? completedCrop.height : video.videoHeight;
      } else {
        canvas.width = completedCrop ? completedCrop.height : video.videoHeight;
        canvas.height = completedCrop ? completedCrop.width : video.videoWidth;
      }

      // Draw first frame to ensure stream is not empty
      applyFilters(ctx, 0, 0, video.videoWidth, video.videoHeight);

      // Set up MediaRecorder
      let mimeType = 'video/webm;codecs=vp8,opus';
      if (!MediaRecorder.isTypeSupported(mimeType)) {
        mimeType = 'video/webm';
      }

      const stream = canvas.captureStream(30);
      const mediaRecorder = new MediaRecorder(stream, {
        mimeType,
        videoBitsPerSecond: 2500000
      });

      const chunks = [];
      let frameCount = 0;
      const totalFrames = Math.floor(video.duration * 30); // 30 fps

      mediaRecorder.ondataavailable = (e) => {
        if (e.data && e.data.size > 0) {
          chunks.push(e.data);
        }
      };

      // Process frames
      const processFrame = () => {
        if (video.currentTime < video.duration) {
          applyFilters(ctx, 0, 0, video.videoWidth, video.videoHeight);
          frameCount++;
          const progress = (frameCount / totalFrames) * 100;
          setProgress(Math.min(Math.round(progress), 99));
          requestAnimationFrame(processFrame);
        } else {
          mediaRecorder.stop();
        }
      };

      // Handle recording completion
      mediaRecorder.onstop = () => {
        const blob = new Blob(chunks, { type: mimeType });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `edited_video_${Date.now()}.webm`;
        link.click();
        URL.revokeObjectURL(url);
        
        setProgress(100);
        setTimeout(() => {
          setProcessing(false);
          setProgress(0);
          startPreview();
        }, 1000);
      };

      // Start recording and processing
      mediaRecorder.start(1000); // Collect data every second
      video.currentTime = 0;
      
      video.addEventListener('seeked', function onSeeked() {
        video.removeEventListener('seeked', onSeeked);
        processFrame();
        video.play();
      });

      // Set video time to 0 to trigger seeked event
      video.currentTime = 0;

    } catch (err) {
      console.error('Error processing video:', err);
      setError('Failed to process video. Please try again with a different video or browser.');
      setProcessing(false);
      setProgress(0);
      startPreview();
    }
  };

  const resetAdjustments = () => {
    setBrightness(100);
    setContrast(100);
    setRotation(0);
    setCrop(null);
    setCompletedCrop(null);
    setIsCropping(false);
  };

  return (
    <Box sx={{ maxWidth: 1000, margin: 'auto', padding: 2 }}>
      <StyledPaper>
        <Typography variant="h5" gutterBottom>
          Video Editor
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              component="label"
              fullWidth
              disabled={processing}
              startIcon={<CloudUploadIcon />}
              sx={{ mb: 2 }}
            >
              Select Video
              <input
                type="file"
                hidden
                accept="video/*"
                onChange={handleFileSelect}
              />
            </Button>
            {!selectedFile && (
              <Typography variant="body2" color="text.secondary" align="center">
                Select a video file to start editing
              </Typography>
            )}
          </Grid>

          {error && (
            <Grid item xs={12}>
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            </Grid>
          )}

          {processing && (
            <Grid item xs={12}>
              <Box sx={{ width: '100%', mb: 2 }}>
                <LinearProgress variant="determinate" value={progress} />
                <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 1 }}>
                  Processing: {Math.round(progress)}%
                </Typography>
              </Box>
            </Grid>
          )}

          {videoUrl && (
            <>
              <Grid item xs={12}>
                <Paper 
                  elevation={3} 
                  sx={{ 
                    p: 2, 
                    backgroundColor: 'background.default',
                    border: '1px solid',
                    borderColor: 'divider'
                  }}
                >
                  {isCropping ? (
                    <ReactCrop
                      crop={crop}
                      onChange={(c) => setCrop(c)}
                      onComplete={(c) => setCompletedCrop(c)}
                      aspect={undefined}
                    >
                      <video
                        ref={videoRef}
                        src={videoUrl}
                        onLoadedMetadata={startPreview}
                        style={{ maxWidth: '100%', display: 'block' }}
                        controls
                      />
                    </ReactCrop>
                  ) : (
                    <>
                      <video
                        ref={videoRef}
                        src={videoUrl}
                        onLoadedMetadata={startPreview}
                        style={{ display: 'none' }}
                        controls
                        loop
                      />
                      <canvas
                        ref={previewCanvasRef}
                        style={{
                          maxWidth: '100%',
                          display: 'block',
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          if (videoRef.current) {
                            if (videoRef.current.paused) {
                              videoRef.current.play();
                            } else {
                              videoRef.current.pause();
                            }
                          }
                        }}
                      />
                    </>
                  )}
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Paper sx={{ p: 2, mb: 2 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <Tooltip title="Toggle Crop Mode">
                      <ControlButton
                        onClick={() => setIsCropping(!isCropping)}
                        color={isCropping ? 'primary' : 'default'}
                      >
                        <CropIcon />
                      </ControlButton>
                    </Tooltip>
                    <Tooltip title="Rotate 90°">
                      <ControlButton onClick={() => setRotation((r) => (r + 90) % 360)}>
                        <RotateIcon />
                      </ControlButton>
                    </Tooltip>
                    <Tooltip title="Reset All">
                      <ControlButton onClick={resetAdjustments}>
                        <UndoIcon />
                      </ControlButton>
                    </Tooltip>
                    <Tooltip title="Save Video">
                      <ControlButton
                        onClick={saveVideo}
                        disabled={processing}
                        color="primary"
                      >
                        <SaveIcon />
                      </ControlButton>
                    </Tooltip>
                  </Box>

                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Typography gutterBottom>
                        Brightness: {brightness}%
                      </Typography>
                      <Slider
                        value={brightness}
                        onChange={(e, value) => setBrightness(value)}
                        min={0}
                        max={200}
                        disabled={processing}
                        marks={[
                          { value: 0, label: '0%' },
                          { value: 100, label: '100%' },
                          { value: 200, label: '200%' },
                        ]}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography gutterBottom>
                        Contrast: {contrast}%
                      </Typography>
                      <Slider
                        value={contrast}
                        onChange={(e, value) => setContrast(value)}
                        min={0}
                        max={200}
                        disabled={processing}
                        marks={[
                          { value: 0, label: '0%' },
                          { value: 100, label: '100%' },
                          { value: 200, label: '200%' },
                        ]}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </>
          )}
        </Grid>

        <canvas
          ref={canvasRef}
          style={{ display: 'none' }}
        />

        <Divider sx={{ my: 3 }} />

        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" gutterBottom>
            About Video Editor
          </Typography>
          <Typography variant="body1" paragraph>
            Edit your videos with our easy-to-use video editor. Rotate, crop, adjust brightness and contrast - all in your browser!
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" gutterBottom>
                Features:
              </Typography>
              <ul>
                <li>Rotate video in 90° increments</li>
                <li>Crop video to desired dimensions</li>
                <li>Adjust brightness (0-200%)</li>
                <li>Adjust contrast (0-200%)</li>
                <li>Real-time preview</li>
                <li>Client-side processing</li>
              </ul>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" gutterBottom>
                Supported Formats:
              </Typography>
              <ul>
                <li>Input: MP4, WebM, MOV (browser supported formats)</li>
                <li>Output: WebM (VP8 codec)</li>
              </ul>
              <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
                Tips:
              </Typography>
              <ul>
                <li>Click the preview to play/pause</li>
                <li>Use reset button to undo all changes</li>
                <li>Save button exports the edited video</li>
              </ul>
            </Grid>
          </Grid>
        </Box>
      </StyledPaper>
    </Box>
  );
};

export default VideoEditor;
