import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Grid,
  Card,
  CardContent,
  IconButton,
  Button,
  Snackbar,
} from '@mui/material';
import {
  Clear as ClearIcon,
  ContentCopy as CopyIcon,
} from '@mui/icons-material';
import ToolDescription from '../common/ToolDescription';

function WordCounterTool() {
  const [text, setText] = useState('');
  const [stats, setStats] = useState({
    characters: 0,
    charactersNoSpaces: 0,
    words: 0,
    sentences: 0,
    paragraphs: 0,
    readingTime: 0,
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
  });

  useEffect(() => {
    calculateStats(text);
  }, [text]);

  const calculateStats = (text) => {
    const characters = text.length;
    const charactersNoSpaces = text.replace(/\s/g, '').length;
    const words = text.trim() ? text.trim().split(/\s+/).length : 0;
    const sentences = text.split(/[.!?]+\s*/).filter(Boolean).length;
    const paragraphs = text.split(/\n\s*\n/).filter(Boolean).length;
    const readingTime = Math.ceil(words / 200); // Average reading speed: 200 words per minute

    setStats({
      characters,
      charactersNoSpaces,
      words,
      sentences,
      paragraphs,
      readingTime,
    });
  };

  const handleClear = () => {
    setText('');
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(
      Object.entries(stats)
        .map(([key, value]) => `${key}: ${value}`)
        .join('\n')
    ).then(() => {
      setSnackbar({
        open: true,
        message: 'Statistics copied to clipboard!',
      });
    });
  };

  const StatCard = ({ title, value, description }) => (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Typography variant="h4" component="div" color="primary" gutterBottom>
          {value}
        </Typography>
        <Typography variant="h6" component="div" gutterBottom>
          {title}
        </Typography>
        {description && (
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        )}
      </CardContent>
    </Card>
  );

  return (
    <Container maxWidth="lg">
      <Paper elevation={3} sx={{ p: 4, my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Word Counter
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          Count words, characters, sentences, and more. Get detailed text statistics instantly.
        </Typography>

        <TextField
          fullWidth
          multiline
          minRows={6}
          maxRows={12}
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder="Type or paste your text here..."
          variant="outlined"
          sx={{ mb: 3 }}
        />

        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item>
            <Button
              variant="contained"
              startIcon={<ClearIcon />}
              onClick={handleClear}
              disabled={!text}
            >
              Clear
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              startIcon={<CopyIcon />}
              onClick={handleCopy}
              disabled={!text}
            >
              Copy Stats
            </Button>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <StatCard
              title="Words"
              value={stats.words}
              description="Total word count"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <StatCard
              title="Characters"
              value={stats.characters}
              description="Including spaces"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <StatCard
              title="Characters (no spaces)"
              value={stats.charactersNoSpaces}
              description="Excluding spaces"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <StatCard
              title="Sentences"
              value={stats.sentences}
              description="Number of sentences"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <StatCard
              title="Paragraphs"
              value={stats.paragraphs}
              description="Number of paragraphs"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <StatCard
              title="Reading Time"
              value={`${stats.readingTime} min`}
              description="Estimated reading time"
            />
          </Grid>
        </Grid>
      </Paper>

      <ToolDescription
        title="Word Counter"
        description="A comprehensive text analysis tool that provides detailed statistics about your text, including word count, character count, sentence count, and estimated reading time. Perfect for writers, students, and content creators who need to track text metrics."
        benefits={[
          "Instant word and character counting",
          "Multiple text statistics in real-time",
          "Reading time estimation",
          "Paragraph and sentence counting",
          "Copy statistics with one click",
          "Clean and intuitive interface"
        ]}
        useCases={[
          "Writing essays and academic papers",
          "Content creation and blogging",
          "Social media post planning",
          "SEO content optimization",
          "Document length verification",
          "Reading time estimation for articles"
        ]}
        howTo={[
          "Type or paste your text in the input field",
          "View real-time statistics in the cards below",
          "Use 'Copy Stats' to copy all statistics",
          "Click 'Clear' to start over with new text",
          "Check reading time for content planning",
          "Monitor character count for social media posts"
        ]}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        message={snackbar.message}
      />
    </Container>
  );
}

export default WordCounterTool;
