import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Typography, Paper, Alert, CircularProgress, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { Mic as MicIcon, Stop as StopIcon, PlayArrow as PlayArrowIcon } from '@mui/icons-material';
import WaveSurfer from 'wavesurfer.js';

const VoiceRecorder = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [error, setError] = useState(null);
  const [audioBlob, setAudioBlob] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [format, setFormat] = useState('wav');
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  
  const canvasRef = useRef(null);
  const audioContextRef = useRef(null);
  const waveformRef = useRef(null);
  const wavesurferRef = useRef(null);
  const timerRef = useRef(null);
  const mediaStreamRef = useRef(null);
  const analyserRef = useRef(null);
  const animationRef = useRef(null);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      if (wavesurferRef.current) {
        wavesurferRef.current.destroy();
      }
      if (mediaStreamRef.current) {
        mediaStreamRef.current.getTracks().forEach(track => track.stop());
      }
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
      if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
        try {
          audioContextRef.current.close();
        } catch (err) {
          console.warn('Error closing AudioContext on unmount:', err);
        }
      }
    };
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return minutes + ':' + (remainingSeconds < 10 ? '0' : '') + remainingSeconds;
  };

  const setupWaveform = async (blob) => {
    try {
      // Wait for the container to be available
      if (!waveformRef.current) {
        console.error('Waveform container not found');
        return;
      }

      // Cleanup previous instance
      if (wavesurferRef.current) {
        wavesurferRef.current.destroy();
        wavesurferRef.current = null;
      }

      // Create new instance
      console.log('Creating new WaveSurfer instance');
      const wavesurfer = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: '#4a9eff',
        progressColor: '#1976d2',
        cursorColor: '#1976d2',
        barWidth: 2,
        barRadius: 3,
        responsive: true,
        height: 100,
        normalize: true,
        partialRender: true,
      });

      // Set up event handlers
      wavesurfer.on('ready', () => {
        console.log('WaveSurfer is ready');
        wavesurferRef.current = wavesurfer;
      });
      
      wavesurfer.on('play', () => {
        console.log('Playing audio');
        setIsPlaying(true);
      });
      
      wavesurfer.on('pause', () => {
        console.log('Audio paused');
        setIsPlaying(false);
      });
      
      wavesurfer.on('finish', () => {
        console.log('Audio finished');
        setIsPlaying(false);
      });
      
      wavesurfer.on('error', err => {
        console.error('WaveSurfer error:', err);
        setError('Error in audio playback: ' + err.message);
      });

      // Load the audio blob
      console.log('Loading audio blob...');
      await wavesurfer.loadBlob(blob);
      console.log('Audio blob loaded successfully');
      
    } catch (err) {
      console.error('Error in setupWaveform:', err);
      setError('Error setting up audio playback: ' + err.message);
    }
  };

  const visualize = (stream) => {
    try {
      if (!audioContextRef.current) {
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
      }

      const audioContext = audioContextRef.current;
      const source = audioContext.createMediaStreamSource(stream);
      const analyser = audioContext.createAnalyser();
      
      analyser.fftSize = 2048;
      source.connect(analyser);
      analyserRef.current = analyser;

      const canvas = canvasRef.current;
      if (!canvas) return;

      const canvasCtx = canvas.getContext('2d');
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      const draw = () => {
        if (!isRecording) return;

        animationRef.current = requestAnimationFrame(draw);
        analyser.getByteTimeDomainData(dataArray);

        canvasCtx.fillStyle = 'rgb(200, 200, 200)';
        canvasCtx.fillRect(0, 0, canvas.width, canvas.height);
        canvasCtx.lineWidth = 2;
        canvasCtx.strokeStyle = '#1976d2';
        canvasCtx.beginPath();

        const sliceWidth = canvas.width * 1.0 / bufferLength;
        let x = 0;

        for (let i = 0; i < bufferLength; i++) {
          const v = dataArray[i] / 128.0;
          const y = v * canvas.height / 2;

          if (i === 0) {
            canvasCtx.moveTo(x, y);
          } else {
            canvasCtx.lineTo(x, y);
          }

          x += sliceWidth;
        }

        canvasCtx.lineTo(canvas.width, canvas.height / 2);
        canvasCtx.stroke();
      };

      draw();
    } catch (err) {
      console.error('Error setting up visualization:', err);
    }
  };

  const startRecording = async () => {
    try {
      setIsLoading(true);
      setError(null);
      
      // Reset audio context if it exists and is not closed
      if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
        try {
          await audioContextRef.current.close();
        } catch (err) {
          console.warn('Error closing AudioContext:', err);
        }
        audioContextRef.current = null;
      }

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaStreamRef.current = stream;
      
      visualize(stream);
      
      const recorder = new MediaRecorder(stream);
      const chunks = [];

      recorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunks.push(e.data);
        }
      };

      recorder.onstop = async () => {
        try {
          const blob = new Blob(chunks, { type: 'audio/' + format });
          setAudioBlob(blob);
          await setupWaveform(blob);
        } catch (err) {
          console.error('Error processing recording:', err);
          setError('Error processing recording: ' + err.message);
        } finally {
          setIsLoading(false);
        }
      };

      recorder.start();
      setMediaRecorder(recorder);
      setAudioChunks(chunks);
      setIsRecording(true);
      setRecordingTime(0);

      timerRef.current = setInterval(() => {
        setRecordingTime(prev => prev + 1);
      }, 1000);

    } catch (err) {
      console.error('Recording error:', err);
      setError('Error accessing microphone: ' + (err.message || 'Unknown error'));
      setIsLoading(false);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder && mediaRecorder.state !== 'inactive') {
      mediaRecorder.stop();
      clearInterval(timerRef.current);
      setIsRecording(false);
      
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
        animationRef.current = null;
      }
      
      if (analyserRef.current) {
        try {
          analyserRef.current.disconnect();
        } catch (err) {
          console.warn('Error disconnecting analyser:', err);
        }
        analyserRef.current = null;
      }

      if (mediaStreamRef.current) {
        mediaStreamRef.current.getTracks().forEach(track => track.stop());
        mediaStreamRef.current = null;
      }

      // Don't close AudioContext here, let it be reused
    }
  };

  const togglePlayPause = () => {
    if (wavesurferRef.current) {
      wavesurferRef.current.playPause();
    }
  };

  const downloadRecording = () => {
    if (audioBlob) {
      const url = URL.createObjectURL(audioBlob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'recording.' + format;
      link.click();
      URL.revokeObjectURL(url);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom>
          Voice Recorder
        </Typography>
        
        <Typography variant="body1" sx={{ mb: 2 }}>
          Record audio using your microphone and save it in different formats.
        </Typography>

        <FormControl sx={{ mb: 3, minWidth: 120 }}>
          <InputLabel id="format-label">Format</InputLabel>
          <Select
            labelId="format-label"
            value={format}
            label="Format"
            onChange={(e) => setFormat(e.target.value)}
            disabled={isRecording}
          >
            <MenuItem value="wav">WAV</MenuItem>
            <MenuItem value="mp3">MP3</MenuItem>
            <MenuItem value="ogg">OGG</MenuItem>
          </Select>
        </FormControl>

        <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
          {!isRecording ? (
            <Button
              variant="contained"
              color="primary"
              onClick={startRecording}
              startIcon={<MicIcon />}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} /> : 'Start Recording'}
            </Button>
          ) : (
            <Button
              variant="contained"
              color="error"
              onClick={stopRecording}
              startIcon={<StopIcon />}
            >
              Stop Recording
            </Button>
          )}

          {recordingTime > 0 && (
            <Typography variant="body1" sx={{ lineHeight: '36px' }}>
              Recording Time: {formatTime(recordingTime)}
            </Typography>
          )}
        </Box>

        {/* Live visualization during recording */}
        <Box sx={{ mb: 3, display: isRecording ? 'block' : 'none' }}>
          <canvas 
            ref={canvasRef}
            width="600" 
            height="100" 
            style={{ 
              width: '100%', 
              height: '100px',
              backgroundColor: '#f5f5f5',
              borderRadius: '4px'
            }}
          />
        </Box>

        {/* Waveform for recorded audio */}
        {audioBlob && (
          <>
            <Box 
              ref={waveformRef} 
              sx={{ 
                mb: 3, 
                backgroundColor: '#f5f5f5', 
                borderRadius: '4px',
                minHeight: '100px', // Ensure container has height
                width: '100%'       // Ensure container has width
              }} 
            />

            <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
              <Button
                variant="contained"
                onClick={togglePlayPause}
                startIcon={isPlaying ? <StopIcon /> : <PlayArrowIcon />}
              >
                {isPlaying ? 'Stop' : 'Play'}
              </Button>

              <Button
                variant="contained"
                onClick={downloadRecording}
              >
                Download
              </Button>
            </Box>
          </>
        )}

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Paper>
    </Box>
  );
};

export default VoiceRecorder;
