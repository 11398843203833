import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Grid,
  Container,
  Card,
  CardContent,
  Slider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Divider,
  Tooltip,
  ListSubheader,
  Stack,
} from '@mui/material';
import {
  CloudUpload as UploadIcon,
  Delete as DeleteIcon,
  Download as DownloadIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
  FormatBold as BoldIcon,
  FormatItalic as ItalicIcon,
  FormatUnderlined as UnderlineIcon,
  ColorLens as ColorIcon,
  Close as CloseIcon,
  MusicNote as MusicNoteIcon,
  ShapeLine as ShapeLineIcon,
  EmojiEmotions as EmojiEmotionsIcon,
  Brush as BrushIcon,
  Videocam as VideocamIcon,
  Audiotrack as AudiotrackIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

// Styled components
const Input = styled('input')({
  display: 'none',
});

const CanvasContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  margin: '20px 0',
  textAlign: 'center',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const fontFamilies = [
  { name: 'Arial', value: 'Arial', type: 'system' },
  { name: 'Times New Roman', value: 'Times New Roman', type: 'system' },
  { name: 'Comic Sans MS', value: 'Comic Sans MS', type: 'system' },
  { name: 'Impact', value: 'Impact', type: 'system' },
  // Google Fonts
  { name: 'Roboto', value: "'Roboto'", type: 'google' },
  { name: 'Open Sans', value: "'Open Sans'", type: 'google' },
  { name: 'Noto Sans', value: "'Noto Sans'", type: 'google' },
  // Indic Fonts
  { name: 'Noto Sans Devanagari', value: "'Noto Sans Devanagari'", type: 'google', script: 'devanagari' },
  { name: 'Noto Sans Bengali', value: "'Noto Sans Bengali'", type: 'google', script: 'bengali' },
  { name: 'Noto Sans Tamil', value: "'Noto Sans Tamil'", type: 'google', script: 'tamil' },
  { name: 'Noto Sans Telugu', value: "'Noto Sans Telugu'", type: 'google', script: 'telugu' },
  { name: 'Noto Sans Kannada', value: "'Noto Sans Kannada'", type: 'google', script: 'kannada' },
  { name: 'Noto Sans Malayalam', value: "'Noto Sans Malayalam'", type: 'google', script: 'malayalam' },
  { name: 'Noto Sans Gujarati', value: "'Noto Sans Gujarati'", type: 'google', script: 'gujarati' },
  // Other Language Fonts
  { name: 'Noto Sans JP', value: "'Noto Sans JP'", type: 'google', script: 'japanese' },
  { name: 'Noto Sans KR', value: "'Noto Sans KR'", type: 'google', script: 'korean' },
  { name: 'Noto Sans SC', value: "'Noto Sans SC'", type: 'google', script: 'chinese-simplified' },
  { name: 'Noto Sans TC', value: "'Noto Sans TC'", type: 'google', script: 'chinese-traditional' },
  { name: 'Noto Sans Arabic', value: "'Noto Sans Arabic'", type: 'google', script: 'arabic' },
];

const supportedLanguages = [
  { code: 'en', name: 'English', direction: 'ltr' },
  { code: 'hi', name: 'हिंदी', direction: 'ltr', font: 'Noto Sans Devanagari' },
  { code: 'bn', name: 'বাংলা', direction: 'ltr', font: 'Noto Sans Bengali' },
  { code: 'ta', name: 'தமிழ்', direction: 'ltr', font: 'Noto Sans Tamil' },
  { code: 'te', name: 'తెలుగు', direction: 'ltr', font: 'Noto Sans Telugu' },
  { code: 'kn', name: 'ಕನ್ನಡ', direction: 'ltr', font: 'Noto Sans Kannada' },
  { code: 'ml', name: 'മലയാളം', direction: 'ltr', font: 'Noto Sans Malayalam' },
  { code: 'gu', name: 'ગુજરાતી', direction: 'ltr', font: 'Noto Sans Gujarati' },
  { code: 'ja', name: '日本語', direction: 'ltr', font: 'Noto Sans JP' },
  { code: 'ko', name: '한국어', direction: 'ltr', font: 'Noto Sans KR' },
  { code: 'zh-CN', name: '简体中文', direction: 'ltr', font: 'Noto Sans SC' },
  { code: 'zh-TW', name: '繁體中文', direction: 'ltr', font: 'Noto Sans TC' },
  { code: 'ar', name: 'العربية', direction: 'rtl', font: 'Noto Sans Arabic' },
];

// Custom keyboard layouts for different languages
const keyboardLayouts = {
  en: {
    layout: {
      default: [
        "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
        "{tab} q w e r t y u i o p [ ] \\",
        "{lock} a s d f g h j k l ; ' {enter}",
        "{shift} z x c v b n m , . / {shift}",
        "{space}"
      ],
      shift: [
        "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
        "{tab} Q W E R T Y U I O P { } |",
        '{lock} A S D F G H J K L : " {enter}',
        "{shift} Z X C V B N M < > ? {shift}",
        "{space}"
      ]
    }
  },
  hi: {
    layout: {
      default: [
        "॰ १ २ ३ ४ ५ ६ ७ ८ ९ ० - ृ {bksp}",
        "{tab} ौ ै ा ी ू ब ह ग द ज ड ़ ॉ",
        "{lock} ो े ् ि ु प र क त च ट {enter}",
        "{shift} ं म न व ल स य भ , . / {shift}",
        "{space}"
      ],
      shift: [
        "ऒ ऍ ॅ ्र र्  ज्ञ त्र क्ष श्र ् ः ऋ {bksp}",
        "{tab} औ ऐ आ ई ऊ भ ङ घ ध झ ढ ञ ऑ",
        "{lock} ओ ए अ इ उ फ ऱ ख थ छ ठ {enter}",
        "{shift} ँ ण न ऴ ळ श ष . ? . {shift}",
        "{space}"
      ]
    }
  },
  bn: {
    layout: {
      default: [
        "` ১ ২ ৩ ৪ ৫ ৬ ৭ ৮ ৯ ০ - = {bksp}",
        "{tab} ৌ ৈ া ী ূ ব হ গ দ জ ড ় ঞ",
        "{lock} ো ে ্ ি ু প র ক ত চ ট {enter}",
        "{shift} ং ম ন ব ল স য় , . / {shift}",
        "{space}"
      ],
      shift: [
        "~ ! @ # ৳ % ^ & * ( ) _ + {bksp}",
        "{tab} ঔ ঐ আ ঈ ঊ ভ ঙ ঘ ধ ঝ ঢ ঃ ঞ",
        "{lock} ও এ অ ই উ ফ ড় খ থ ছ ঠ {enter}",
        "{shift} ঁ ণ ন ব ল শ য , . ? {shift}",
        "{space}"
      ]
    }
  },
  ta: {
    layout: {
      default: [
        "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
        "{tab} ௌ ை ா ீ ூ ப ஹ க த ச ட ஞ",
        "{lock} ோ ே ் ி ு ற ர க த ச ட {enter}",
        "{shift} ங ம ன வ ல ள ய , . / {shift}",
        "{space}"
      ],
      shift: [
        "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
        "{tab} ஔ ஐ ஆ ஈ ஊ ப ங க த ச ட ஞ",
        "{lock} ஓ ஏ அ இ உ ற ர க த ச ட {enter}",
        "{shift} ங ம ன வ ல ள ய , . ? {shift}",
        "{space}"
      ]
    }
  },
  te: {
    layout: {
      default: [
        "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
        "{tab} ౌ ై ా ీ ూ బ హ గ ద జ డ ఞ",
        "{lock} ో ే ్ ి ు ప ర క త చ ట {enter}",
        "{shift} ం మ న వ ల స య , . / {shift}",
        "{space}"
      ],
      shift: [
        "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
        "{tab} ఔ ఐ ఆ ఈ ఊ భ ఙ ఘ ధ ఝ ఢ ఞ",
        "{lock} ఓ ఏ అ ఇ ఉ ఫ ఱ ఖ థ ఛ ఠ {enter}",
        "{shift} ఁ ణ న వ ళ శ ష , . ? {shift}",
        "{space}"
      ]
    }
  },
  kn: {
    layout: {
      default: [
        "` ೧ ೨ ೩ ೪ ೫ ೬ ೭ ೮ ೯ ೦ - = {bksp}",
        "{tab} ೌ ೈ ಾ ೀ ೂ ಬ ಹ ಗ ದ ಜ ಡ ಞ",
        "{lock} ೋ ೇ ್ ಿ ು ಪ ರ ಕ ತ ಚ ಟ {enter}",
        "{shift} ಂ ಮ ನ ವ ಲ ಸ ಯ , . / {shift}",
        "{space}"
      ],
      shift: [
        "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
        "{tab} ಔ ಐ ಆ ಈ ಊ ಭ ಙ ಘ ಧ ಝ ಢ ಞ",
        "{lock} ಓ ಏ ಅ ಇ ಉ ಫ ರ ಖ ಥ ಛ ಠ {enter}",
        "{shift} ಁ ಣ ನ ವ ಳ ಶ ಷ , . ? {shift}",
        "{space}"
      ]
    }
  },
  ml: {
    layout: {
      default: [
        "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
        "{tab} ൌ ൈ ാ ീ ൂ ബ ഹ ഗ ദ ജ ഡ ഞ",
        "{lock} ോ േ ് ി ു പ ര ക ത ച ട {enter}",
        "{shift} ം മ ന വ ല സ യ , . / {shift}",
        "{space}"
      ],
      shift: [
        "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
        "{tab} ഔ ഐ ആ ഈ ഊ ഭ ങ ഘ ധ ഝ ഢ ഞ",
        "{lock} ഓ ഏ അ ഇ ഉ ഫ റ ഖ ഥ ഛ ഠ {enter}",
        "{shift} ഁ ണ ന വ ള ശ ഷ , . ? {shift}",
        "{space}"
      ]
    }
  },
  gu: {
    layout: {
      default: [
        "` ૧ ૨ ૩ ૪ ૫ ૬ ૭ ૮ ૯ ૦ - = {bksp}",
        "{tab} ૌ ૈ ા ી ૂ બ હ ગ દ જ ડ ઞ",
        "{lock} ો ે ્ િ ુ પ ર ક ત ચ ટ {enter}",
        "{shift} ં મ ન વ લ સ ય , . / {shift}",
        "{space}"
      ],
      shift: [
        "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
        "{tab} ઔ ઐ આ ઈ ઊ ભ ઙ ઘ ધ ઝ ઢ ઞ",
        "{lock} ઓ એ અ ઇ ઉ ફ ર ખ થ છ ઠ {enter}",
        "{shift} ઁ ણ ન વ ળ શ ષ , . ? {shift}",
        "{space}"
      ]
    }
  }
};

const MemeGenerator = () => {
  const [image, setImage] = useState(null);
  const [texts, setTexts] = useState([{ 
    text: '', 
    x: 50, 
    y: 50, 
    size: 32, 
    color: '#ffffff',
    outlineColor: '#000000',
    outlineWidth: 2,
    rotation: 0,
    bold: true, 
    italic: false, 
    underline: false,
    effect: 'none', // none, shadow, glow
    fontFamily: 'Arial',
    language: 'en',
    direction: 'ltr'
  }]);
  const [selectedTextIndex, setSelectedTextIndex] = useState(0);
  const canvasRef = useRef(null);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [filters, setFilters] = useState({
    brightness: 100,
    contrast: 100,
    saturation: 100
  });
  const [keyboardInstance, setKeyboardInstance] = useState(null);
  const keyboardContainerRef = useRef(null);
  const [currentLanguage, setCurrentLanguage] = useState('en');
  const [showKeyboard, setShowKeyboard] = useState(false);
  const [activeTextIndex, setActiveTextIndex] = useState(0);
  const [useTransliteration, setUseTransliteration] = useState(false);
  const [inputBuffer, setInputBuffer] = useState('');

  // Enhanced Hindi transliteration mapping with matras and common combinations
  const hindiMapping = {
    // Common Names
    'amit': 'अमित',
    'ankit': 'अंकित',
    'kumar': 'कुमार',
    'raj': 'राज',
    'singh': 'सिंह',
    'sharma': 'शर्मा',
    'verma': 'वर्मा',
    'rahul': 'राहुल',
    'rohit': 'रोहित',
    'sanjay': 'संजय',
    'vijay': 'विजय',
    
    // Consonant clusters
    'nk': 'ंक',
    'nd': 'ंद',
    'mb': 'ंब',
    'mp': 'ंप',
    'nt': 'ंत',
    'nc': 'ंच',
    
    // Vowels
    'a': 'अ', 'aa': 'आ', 'i': 'इ', 'ee': 'ई', 'u': 'उ', 'oo': 'ऊ',
    'e': 'ए', 'ai': 'ऐ', 'o': 'ओ', 'au': 'औ',
    
    // Matras (vowel signs)
    'aa': 'ा', 'i': 'ि', 'ee': 'ी', 'u': 'ु', 'oo': 'ू',
    'e': 'े', 'ai': 'ै', 'o': 'ो', 'au': 'ौ',
    
    // Consonants
    'k': 'क', 'kh': 'ख', 'g': 'ग', 'gh': 'घ', 'ng': 'ङ',
    'ch': 'च', 'chh': 'छ', 'j': 'ज', 'jh': 'झ', 'ny': 'ञ',
    't': 'त', 'th': 'थ', 'd': 'द', 'dh': 'ध', 'n': 'न',
    'p': 'प', 'ph': 'फ', 'b': 'ब', 'bh': 'भ', 'm': 'म',
    'y': 'य', 'r': 'र', 'l': 'ल', 'v': 'व', 'w': 'व',
    'sh': 'श', 'Sh': 'ष', 's': 'स', 'h': 'ह',
    
    // Special characters
    'om': 'ॐ',
    '.': '।',
    ',': ',',
    '?': '?',
    '!': '!',
    ' ': ' ',
    
    // Anusvara and Chandrabindu
    'n': 'न',
    'M': 'ं',
    'm': 'म',
    'N': 'ँ',
  };

  // Function to convert English to Hindi with improved word handling
  const convertToHindi = (text) => {
    // Split input into words
    const words = text.toLowerCase().split(' ');
    
    // Process each word
    const convertedWords = words.map(word => {
      // Check if it's a common word or name first
      if (hindiMapping[word]) {
        return hindiMapping[word];
      }
      
      let result = '';
      let i = 0;
      
      while (i < word.length) {
        let matched = false;
        
        // Try consonant clusters first
        if (i <= word.length - 2) {
          const cluster = word.slice(i, i + 2);
          if (hindiMapping[cluster] && 'aeiou'.includes(word[i + 2])) {
            result += hindiMapping[cluster];
            i += 2;
            matched = true;
            continue;
          }
        }
        
        // Try consonant + vowel combinations
        if (i <= word.length - 2 && !matched) {
          const consonant = word[i];
          const nextChar = word[i + 1];
          
          if ('kgcjtnpdblvshm'.includes(consonant)) {
            if ('aeiou'.includes(nextChar)) {
              if (nextChar === 'a') {
                result += hindiMapping[consonant] || consonant;
                i += 2;
                matched = true;
              } else {
                const vowelMatra = hindiMapping[nextChar];
                if (vowelMatra) {
                  result += hindiMapping[consonant] + vowelMatra;
                  i += 2;
                  matched = true;
                }
              }
              continue;
            }
          }
        }
        
        // Try three-character combinations
        if (i <= word.length - 3 && !matched) {
          const threeChars = word.slice(i, i + 3);
          if (hindiMapping[threeChars]) {
            result += hindiMapping[threeChars];
            i += 3;
            matched = true;
            continue;
          }
        }
        
        // Try two-character combinations
        if (i <= word.length - 2 && !matched) {
          const twoChars = word.slice(i, i + 2);
          if (hindiMapping[twoChars]) {
            result += hindiMapping[twoChars];
            i += 2;
            matched = true;
            continue;
          }
        }
        
        // Single character
        if (!matched) {
          const char = word[i];
          if (hindiMapping[char]) {
            result += hindiMapping[char];
          } else {
            result += char;
          }
          i++;
        }
      }
      
      return result;
    });
    
    return convertedWords.join(' ');
  };

  const handleLanguageChange = (index, value) => {
    const lang = supportedLanguages.find(l => l.code === value);
    if (!lang) return;

    handleTextChange(index, 'language', value);
    handleTextChange(index, 'direction', lang.direction);
    if (lang.font) {
      handleTextChange(index, 'fontFamily', lang.font);
    }
    setCurrentLanguage(value);
    setShowKeyboard(true);
    setActiveTextIndex(index);
    setUseTransliteration(value !== 'en');
  };

  const handleTransliteratedInput = (e) => {
    const newInput = e.target.value;
    setInputBuffer(newInput);
    
    if (currentLanguage === 'hi') {
      const transliterated = convertToHindi(newInput);
      const updatedTexts = [...texts];
      updatedTexts[activeTextIndex] = {
        ...updatedTexts[activeTextIndex],
        text: transliterated
      };
      setTexts(updatedTexts);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      const currentText = texts[activeTextIndex].text;
      const updatedTexts = [...texts];
      updatedTexts[activeTextIndex] = {
        ...updatedTexts[activeTextIndex],
        text: currentText + '\n'
      };
      setTexts(updatedTexts);
      setInputBuffer('');
    }
  };

  const transliterate = async (text, sourceLang, targetLang) => {
    try {
      const response = await fetch(`https://inputtools.google.com/request?text=${text}&itc=${targetLang}-t-i0-und&num=5`);
      const data = await response.json();
      if (data[0] === 'SUCCESS' && data[1] && data[1][0] && data[1][0][1]) {
        return data[1][0][1][0];
      }
      return text;
    } catch (error) {
      console.error('Transliteration error:', error);
      return text;
    }
  };

  // Load popular meme templates
  useEffect(() => {
    // Template categories with more templates
    const popularTemplates = [
      // Classic Memes
      { name: 'Drake', url: '/meme-templates/drake.jpg', category: 'classic' },
      { name: 'Distracted Boyfriend', url: '/meme-templates/distracted.jpg', category: 'classic' },
      { name: 'Two Buttons', url: '/meme-templates/buttons.jpg', category: 'classic' },
      { name: 'One Does Not Simply', url: '/meme-templates/one-does-not-simply.jpg', category: 'classic' },
      { name: 'Success Kid', url: '/meme-templates/success-kid.jpg', category: 'classic' },
      
      // Reaction Memes
      { name: 'Surprised Pikachu', url: '/meme-templates/surprised-pikachu.jpg', category: 'reaction' },
      { name: 'Think About It', url: '/meme-templates/think-about-it.jpg', category: 'reaction' },
      { name: 'Woman Yelling at Cat', url: '/meme-templates/woman-yelling-cat.jpg', category: 'reaction' },
      { name: 'This Is Fine', url: '/meme-templates/this-is-fine.jpg', category: 'reaction' },
      
      // Modern Memes
      { name: 'Stonks', url: '/meme-templates/stonks.jpg', category: 'modern' },
      { name: 'Always Has Been', url: '/meme-templates/always-has-been.jpg', category: 'modern' },
      { name: 'Trade Offer', url: '/meme-templates/trade-offer.jpg', category: 'modern' },
      
      // Wholesome Memes
      { name: 'Wholesome Seal', url: '/meme-templates/wholesome-seal.jpg', category: 'wholesome' },
      { name: 'Everyone Liked That', url: '/meme-templates/everyone-liked-that.jpg', category: 'wholesome' },
      { name: 'You Are Awesome', url: '/meme-templates/you-are-awesome.jpg', category: 'wholesome' }
    ];
    setTemplates(popularTemplates);
  }, []);

  // Load Google Fonts
  useEffect(() => {
    const loadGoogleFonts = async () => {
      const WebFont = (await import('webfontloader')).default;
      const googleFonts = fontFamilies
        .filter(font => font.type === 'google')
        .map(font => font.value.replace(/[']/g, ''));

      WebFont.load({
        google: {
          families: googleFonts
        }
      });
    };
    loadGoogleFonts();
  }, []);

  // Initialize keyboard
  useEffect(() => {
    const initKeyboard = () => {
      if (!keyboardContainerRef.current || keyboardInstance) return;

      try {
        const newKeyboard = new Keyboard({
          onChange: input => onKeyPress(input),
          container: keyboardContainerRef.current,
          layout: {
            default: [
              "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
              "{tab} q w e r t y u i o p [ ] \\",
              "{lock} a s d f g h j k l ; ' {enter}",
              "{shift} z x c v b n m , . / {shift}",
              "{space}"
            ]
          },
          display: {
            '{bksp}': '⌫',
            '{enter}': '↵',
            '{shift}': '⇧',
            '{space}': ' ',
            '{tab}': '⇥',
            '{lock}': '⇪'
          }
        });
        setKeyboardInstance(newKeyboard);
      } catch (error) {
        console.error('Error initializing keyboard:', error);
      }
    };

    initKeyboard();

    return () => {
      if (keyboardInstance) {
        keyboardInstance.destroy();
      }
    };
  }, []);

  // Update keyboard layout when language changes
  useEffect(() => {
    if (keyboardInstance && currentLanguage && keyboardLayouts[currentLanguage]) {
      try {
        const layout = keyboardLayouts[currentLanguage].layout || keyboardLayouts.en.layout;
        keyboardInstance.setOptions({
          layout,
          display: {
            '{bksp}': '⌫',
            '{enter}': '↵',
            '{shift}': '⇧',
            '{space}': ' ',
            '{tab}': '⇥',
            '{lock}': '⇪'
          }
        });
      } catch (error) {
        console.error('Error updating keyboard layout:', error);
      }
    }
  }, [currentLanguage, keyboardInstance]);

  // Function to apply filters to canvas
  const applyFilters = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;

    // Apply brightness
    const brightnessAdjust = (filters.brightness - 100) * 2.55; // Convert percentage to pixel value
    for (let i = 0; i < data.length; i += 4) {
      data[i] = Math.min(255, Math.max(0, data[i] + brightnessAdjust));     // Red
      data[i + 1] = Math.min(255, Math.max(0, data[i + 1] + brightnessAdjust)); // Green
      data[i + 2] = Math.min(255, Math.max(0, data[i + 2] + brightnessAdjust)); // Blue
    }

    // Apply contrast
    const contrastFactor = Math.pow((filters.contrast + 100) / 100, 2);
    for (let i = 0; i < data.length; i += 4) {
      for (let j = 0; j < 3; j++) {
        const channel = data[i + j];
        data[i + j] = Math.min(255, Math.max(0, 
          Math.round(((channel / 255 - 0.5) * contrastFactor + 0.5) * 255)
        ));
      }
    }

    // Apply saturation
    const saturationFactor = filters.saturation / 100;
    for (let i = 0; i < data.length; i += 4) {
      const gray = 0.2989 * data[i] + 0.5870 * data[i + 1] + 0.1140 * data[i + 2];
      data[i] = Math.min(255, Math.max(0, gray + (data[i] - gray) * saturationFactor));
      data[i + 1] = Math.min(255, Math.max(0, gray + (data[i + 1] - gray) * saturationFactor));
      data[i + 2] = Math.min(255, Math.max(0, gray + (data[i + 2] - gray) * saturationFactor));
    }

    ctx.putImageData(imageData, 0, 0);
  };

  // Function to handle filter changes
  const handleFilterChange = (type, value) => {
    setFilters(prev => ({
      ...prev,
      [type]: value
    }));
  };

  // Function to draw image on canvas
  const drawImage = (img) => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    
    const ctx = canvas.getContext('2d');
    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    
    // Apply any active filters
    applyFilters();
  };

  // Function to handle image upload
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          setImage(img);
          drawImage(img);
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  // Function to get canvas position
  const getCanvasPosition = (e, canvas) => {
    if (!canvas) return { x: 0, y: 0 };
    const rect = canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    return { x, y };
  };

  // Function to ensure canvas is ready
  const ensureCanvasReady = () => {
    const canvas = canvasRef.current;
    if (!canvas || !image) {
      alert('Please upload an image first');
      return false;
    }
    return true;
  };

  // Tool States
  const [showAudioTool, setShowAudioTool] = useState(false);
  const [showVideoTool, setShowVideoTool] = useState(false);
  const [showShapeTool, setShowShapeTool] = useState(false);
  const [showStickerTool, setShowStickerTool] = useState(false);
  const [showDrawingTool, setShowDrawingTool] = useState(false);

  // Tool-specific states
  const [selectedAudio, setSelectedAudio] = useState(null);
  const [video, setVideo] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const videoRef = useRef(null);
  const [selectedShape, setSelectedShape] = useState('rectangle');
  const [shapeColor, setShapeColor] = useState('#000000');
  const [selectedSticker, setSelectedSticker] = useState(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [drawingColor, setDrawingColor] = useState('#000000');
  const [drawingSize, setDrawingSize] = useState(5);

  // Add new state for shapes and stickers
  const [shapes, setShapes] = useState([]);
  const [stickers, setStickers] = useState([]);

  // Function to add shape
  const addShape = (shapeType, color) => {
    if (!ensureCanvasReady()) return;
    const canvas = canvasRef.current;
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    
    const newShape = {
      type: shapeType,
      x: centerX,
      y: centerY,
      width: 100,
      height: 100,
      color: color
    };
    
    setShapes(prevShapes => [...prevShapes, newShape]);
  };

  // Function to add sticker
  const addSticker = (emoji) => {
    if (!ensureCanvasReady()) return;
    const canvas = canvasRef.current;
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    
    const newSticker = {
      emoji,
      x: centerX,
      y: centerY,
      size: 40
    };
    
    setStickers(prevStickers => [...prevStickers, newSticker]);
  };

  // Function to draw shapes
  const drawShapes = (ctx) => {
    shapes.forEach(shape => {
      ctx.fillStyle = shape.color;
      ctx.beginPath();
      
      switch (shape.type) {
        case 'rectangle':
          ctx.fillRect(shape.x - shape.width/2, shape.y - shape.height/2, shape.width, shape.height);
          break;
        case 'circle':
          ctx.beginPath();
          ctx.arc(shape.x, shape.y, shape.width/2, 0, Math.PI * 2);
          ctx.fill();
          break;
        case 'triangle':
          ctx.beginPath();
          ctx.moveTo(shape.x, shape.y - shape.height/2);
          ctx.lineTo(shape.x - shape.width/2, shape.y + shape.height/2);
          ctx.lineTo(shape.x + shape.width/2, shape.y + shape.height/2);
          ctx.closePath();
          ctx.fill();
          break;
      }
    });
  };

  // Function to draw stickers
  const drawStickers = (ctx) => {
    stickers.forEach(sticker => {
      ctx.font = `${sticker.size}px Arial`;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(sticker.emoji, sticker.x, sticker.y);
    });
  };

  // Audio Tool Component
  const AudioTool = () => {
    const audioInputRef = useRef(null);

    const handleAudioUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
        setSelectedAudio(URL.createObjectURL(file));
      }
    };

    return (
      <Box sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>Audio Tool</Typography>
        <input
          type="file"
          accept="audio/*"
          ref={audioInputRef}
          style={{ display: 'none' }}
          onChange={handleAudioUpload}
        />
        <Button
          variant="contained"
          onClick={() => audioInputRef.current.click()}
          startIcon={<MusicNoteIcon />}
        >
          Upload Audio
        </Button>
        {selectedAudio && (
          <Box sx={{ mt: 2 }}>
            <audio controls src={selectedAudio} />
            <Button
              variant="outlined"
              color="error"
              onClick={() => setSelectedAudio(null)}
              sx={{ mt: 1 }}
            >
              Remove Audio
            </Button>
          </Box>
        )}
      </Box>
    );
  };

  // Video Tool Component
  const VideoTool = () => {
    const videoInputRef = useRef(null);

    const handleVideoUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
        const url = URL.createObjectURL(file);
        setVideo(file);
        setVideoUrl(url);
      }
    };

    const handleRemoveVideo = () => {
      if (videoUrl) {
        URL.revokeObjectURL(videoUrl);
      }
      setVideo(null);
      setVideoUrl(null);
      if (videoInputRef.current) {
        videoInputRef.current.value = '';
      }
    };

    return (
      <StyledPaper>
        <Typography variant="h6" gutterBottom>
          Video Tool
        </Typography>
        <Box sx={{ mb: 2 }}>
          <input
            ref={videoInputRef}
            type="file"
            accept="video/*"
            onChange={handleVideoUpload}
            style={{ display: 'none' }}
          />
          <Button
            variant="contained"
            onClick={() => videoInputRef.current.click()}
            startIcon={<VideocamIcon />}
            sx={{ mr: 1 }}
          >
            Upload Video
          </Button>
          {video && (
            <Button
              variant="outlined"
              color="error"
              onClick={handleRemoveVideo}
              startIcon={<DeleteIcon />}
            >
              Remove
            </Button>
          )}
        </Box>
        {videoUrl && (
          <Box sx={{ width: '100%', mt: 2 }}>
            <video
              ref={videoRef}
              src={videoUrl}
              controls
              style={{
                width: '100%',
                maxHeight: '300px',
                objectFit: 'contain'
              }}
            >
              Your browser does not support the video tag.
            </video>
            <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  if (videoRef.current) {
                    videoRef.current.currentTime = 0;
                    videoRef.current.play();
                  }
                }}
              >
                Restart
              </Button>
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  if (videoRef.current) {
                    if (videoRef.current.paused) {
                      videoRef.current.play();
                    } else {
                      videoRef.current.pause();
                    }
                  }
                }}
              >
                Play/Pause
              </Button>
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  if (videoRef.current) {
                    videoRef.current.currentTime += 5;
                  }
                }}
              >
                +5s
              </Button>
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  if (videoRef.current) {
                    videoRef.current.currentTime -= 5;
                  }
                }}
              >
                -5s
              </Button>
            </Stack>
          </Box>
        )}
      </StyledPaper>
    );
  };

  // Shape Tool Component
  const ShapeTool = () => {
    const shapes = ['rectangle', 'circle', 'triangle'];

    const addShape = (shape) => {
      if (!ensureCanvasReady()) return;
      
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      ctx.fillStyle = shapeColor;

      // Get center position
      const centerX = canvas.width / 2;
      const centerY = canvas.height / 2;
      const size = Math.min(canvas.width, canvas.height) / 4;

      switch (shape) {
        case 'rectangle':
          ctx.fillRect(centerX - size/2, centerY - size/2, size, size * 0.8);
          break;
        case 'circle':
          ctx.beginPath();
          ctx.arc(centerX, centerY, size/2, 0, Math.PI * 2);
          ctx.fill();
          break;
        case 'triangle':
          ctx.beginPath();
          ctx.moveTo(centerX, centerY - size/2);
          ctx.lineTo(centerX - size/2, centerY + size/2);
          ctx.lineTo(centerX + size/2, centerY + size/2);
          ctx.closePath();
          ctx.fill();
          break;
      }
    };

    return (
      <Box sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>Shape Tool</Typography>
        <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
          {shapes.map((shape) => (
            <Button
              key={shape}
              variant={selectedShape === shape ? 'contained' : 'outlined'}
              onClick={() => setSelectedShape(shape)}
            >
              {shape}
            </Button>
          ))}
        </Stack>
        <Box sx={{ mb: 2 }}>
          <Typography gutterBottom>Color</Typography>
          <input
            type="color"
            value={shapeColor}
            onChange={(e) => setShapeColor(e.target.value)}
          />
        </Box>
        <Button
          variant="contained"
          onClick={() => addShape(selectedShape)}
          disabled={!image}
        >
          Add Shape
        </Button>
      </Box>
    );
  };

  // Sticker Tool Component
  const StickerTool = () => {
    const stickers = [
      '😀', '😂', '😍', '🎉', '🌟', '💖',
      '🔥', '👍', '🎵', '🌈', '⭐', '💯'
    ];

    const addSticker = (sticker) => {
      if (!ensureCanvasReady()) return;
      
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      
      // Calculate center position
      const centerX = canvas.width / 2;
      const centerY = canvas.height / 2;
      
      // Set font size relative to canvas size
      const fontSize = Math.min(canvas.width, canvas.height) / 10;
      ctx.font = `${fontSize}px Arial`;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(sticker, centerX, centerY);
    };

    return (
      <Box sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>Sticker Tool</Typography>
        <Grid container spacing={1}>
          {stickers.map((sticker) => (
            <Grid item key={sticker}>
              <Button
                variant={selectedSticker === sticker ? 'contained' : 'outlined'}
                onClick={() => {
                  setSelectedSticker(sticker);
                  addSticker(sticker);
                }}
                sx={{ fontSize: '24px', minWidth: '48px' }}
                disabled={!image}
              >
                {sticker}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  // Drawing Tool Component
  const DrawingTool = () => {
    const startDrawing = (e) => {
      if (!ensureCanvasReady()) return;
      
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      const { x, y } = getCanvasPosition(e, canvas);

      ctx.beginPath();
      ctx.moveTo(x, y);
      ctx.strokeStyle = drawingColor;
      ctx.lineWidth = drawingSize;
      ctx.lineCap = 'round';
      setIsDrawing(true);
    };

    const draw = (e) => {
      if (!isDrawing || !canvasRef.current) return;
      
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      const { x, y } = getCanvasPosition(e, canvas);

      ctx.lineTo(x, y);
      ctx.stroke();
    };

    const stopDrawing = () => {
      setIsDrawing(false);
    };

    return (
      <Box sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>Drawing Tool</Typography>
        <Box sx={{ mb: 2 }}>
          <Typography gutterBottom>Color</Typography>
          <input
            type="color"
            value={drawingColor}
            onChange={(e) => setDrawingColor(e.target.value)}
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography gutterBottom>Size</Typography>
          <Slider
            value={drawingSize}
            onChange={(e, newValue) => setDrawingSize(newValue)}
            min={1}
            max={20}
            valueLabelDisplay="auto"
          />
        </Box>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          {image ? 'Click and drag to draw' : 'Please upload an image first'}
        </Typography>
      </Box>
    );
  };

  const handleTemplateSelect = (template) => {
    const img = new Image();
    img.onload = () => {
      setImage(img);
      setSelectedTemplate(template);
    };
    img.src = template.url;
  };

  const handleAddText = () => {
    setTexts([...texts, { 
      text: '', 
      x: 50, 
      y: 50 + texts.length * 50, 
      size: 32, 
      color: '#ffffff',
      outlineColor: '#000000',
      outlineWidth: 2,
      rotation: 0,
      bold: true, 
      italic: false, 
      underline: false,
      effect: 'none', // none, shadow, glow
      fontFamily: 'Arial',
      language: 'en',
      direction: 'ltr'
    }]);
    setSelectedTextIndex(texts.length);
  };

  const handleRemoveText = (index) => {
    setTexts(texts.filter((_, i) => i !== index));
    setSelectedTextIndex(Math.max(0, selectedTextIndex - 1));
  };

  const handleTextChange = (index, field, value) => {
    const newTexts = [...texts];
    newTexts[index] = { ...newTexts[index], [field]: value };
    setTexts(newTexts);
  };

  const applyTextEffect = (ctx, text, x, y, effect) => {
    switch(effect) {
      case 'shadow':
        ctx.shadowColor = 'rgba(0, 0, 0, 0.5)';
        ctx.shadowBlur = 5;
        ctx.shadowOffsetX = 3;
        ctx.shadowOffsetY = 3;
        break;
      case 'glow':
        ctx.shadowColor = 'rgba(255, 255, 255, 0.8)';
        ctx.shadowBlur = 10;
        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = 0;
        break;
      default:
        ctx.shadowColor = 'transparent';
        ctx.shadowBlur = 0;
        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = 0;
    }
  };

  const drawMeme = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    if (image) {
      canvas.width = image.width;
      canvas.height = image.height;
      
      ctx.filter = `brightness(${filters.brightness}%) contrast(${filters.contrast}%) saturate(${filters.saturation}%)`;
      ctx.drawImage(image, 0, 0);
      ctx.filter = 'none';

      // Draw shapes and stickers before text
      drawShapes(ctx);
      drawStickers(ctx);

      texts.forEach(({ text, x, y, size, color, outlineColor, outlineWidth, rotation, bold, italic, underline, effect, fontFamily, direction }) => {
        ctx.save();
        
        const xPos = x * canvas.width / 100;
        const yPos = y * canvas.height / 100;
        ctx.translate(xPos, yPos);
        ctx.rotate(rotation * Math.PI / 180);
        
        let fontString = `${italic ? 'italic ' : ''}${bold ? 'bold ' : ''}${size}px ${fontFamily}`;
        ctx.font = fontString;
        ctx.textAlign = 'center';
        ctx.direction = direction;
        
        applyTextEffect(ctx, text, 0, 0, effect);
        
        if (outlineWidth > 0) {
          ctx.strokeStyle = outlineColor;
          ctx.lineWidth = outlineWidth;
          ctx.strokeText(text, 0, 0);
        }
        
        ctx.fillStyle = color;
        ctx.fillText(text, 0, 0);
        
        if (underline) {
          const metrics = ctx.measureText(text);
          const textWidth = metrics.width;
          ctx.beginPath();
          ctx.moveTo(-textWidth / 2, 5);
          ctx.lineTo(textWidth / 2, 5);
          ctx.strokeStyle = color;
          ctx.stroke();
        }
        
        ctx.restore();
      });
    }
  };

  useEffect(() => {
    drawMeme();
  }, [image, texts, filters]);

  const handleDownload = () => {
    const canvas = canvasRef.current;
    const link = document.createElement('a');
    link.download = 'meme.png';
    link.href = canvas.toDataURL('image/png');
    link.click();
  };

  const onKeyPress = async (button) => {
    if (button === "{shift}" || button === "{lock}") return;

    const updatedTexts = [...texts];
    let currentText = updatedTexts[activeTextIndex].text;
    
    if (button === "{bksp}") {
      if (inputBuffer) {
        setInputBuffer(inputBuffer.slice(0, -1));
      } else {
        updatedTexts[activeTextIndex].text = currentText.slice(0, -1);
      }
    } else if (button === "{space}") {
      if (useTransliteration && inputBuffer) {
        const transliteratedText = await transliterate(
          inputBuffer,
          'en',
          currentLanguage
        );
        updatedTexts[activeTextIndex].text = currentText + transliteratedText + " ";
        setInputBuffer('');
      } else {
        updatedTexts[activeTextIndex].text = currentText + " ";
      }
    } else if (button === "{enter}") {
      if (useTransliteration && inputBuffer) {
        const transliteratedText = await transliterate(
          inputBuffer,
          'en',
          currentLanguage
        );
        updatedTexts[activeTextIndex].text = currentText + transliteratedText + "\n";
        setInputBuffer('');
      } else {
        updatedTexts[activeTextIndex].text = currentText + "\n";
      }
    } else if (button === "{tab}") {
      if (useTransliteration && inputBuffer) {
        const transliteratedText = await transliterate(
          inputBuffer,
          'en',
          currentLanguage
        );
        updatedTexts[activeTextIndex].text = currentText + transliteratedText + "\t";
        setInputBuffer('');
      } else {
        updatedTexts[activeTextIndex].text = currentText + "\t";
      }
    } else {
      if (useTransliteration) {
        setInputBuffer(inputBuffer + button);
      } else {
        updatedTexts[activeTextIndex].text = currentText + button;
      }
    }
    
    setTexts(updatedTexts);
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>
        Meme Generator
      </Typography>

      {/* Main Tool */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <StyledPaper>
            {/* Typing Mode Section */}
            {currentLanguage === 'hi' && (
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle1" gutterBottom>
                  Typing Mode: Hindi
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={2}
                  variant="outlined"
                  value={inputBuffer}
                  onChange={handleTransliteratedInput}
                  onKeyDown={handleKeyPress}
                  placeholder="Type in English for Hindi conversion (e.g., namaste → नमस्ते)"
                  sx={{ mb: 2 }}
                />
              </Box>
            )}

            <CanvasContainer>
              <canvas ref={canvasRef} style={{ maxWidth: '100%', height: 'auto' }} />
            </CanvasContainer>
            
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', gap: 2 }}>
              <label htmlFor="upload-image">
                <Input accept="image/*" id="upload-image" type="file" onChange={handleImageUpload} />
                <Button variant="contained" component="span" startIcon={<UploadIcon />}>
                  Upload Image
                </Button>
              </label>
              <Button
                variant="contained"
                color="primary"
                onClick={handleDownload}
                disabled={!image}
                startIcon={<DownloadIcon />}
              >
                Download Meme
              </Button>
            </Box>
          </StyledPaper>
        </Grid>

        <Grid item xs={12} md={4}>
          <StyledPaper>
            <Typography variant="h6" gutterBottom>
              Text Controls
            </Typography>
            <Box sx={{ mb: 2 }}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleAddText}
                fullWidth
              >
                Add Text
              </Button>
            </Box>

            {texts.map((text, index) => (
              <Card key={index} sx={{ mb: 2 }}>
                <CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <Typography variant="subtitle1">Text {index + 1}</Typography>
                    <IconButton onClick={() => handleRemoveText(index)} disabled={texts.length === 1}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>

                  <Box sx={{ mb: 2 }}>
                    <Typography gutterBottom>Language</Typography>
                    <Select
                      value={text.language}
                      onChange={(e) => handleLanguageChange(index, e.target.value)}
                      fullWidth
                    >
                      {supportedLanguages.map((lang) => (
                        <MenuItem key={lang.code} value={lang.code}>
                          {lang.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>

                  <Box sx={{ mb: 2 }}>
                    <TextField
                      fullWidth
                      multiline
                      label="Text"
                      value={text.text}
                      onChange={(e) => handleTextChange(index, 'text', e.target.value)}
                      onFocus={() => {
                        setShowKeyboard(true);
                        setActiveTextIndex(index);
                      }}
                      InputProps={{
                        style: { 
                          fontFamily: text.fontFamily,
                          direction: text.direction 
                        }
                      }}
                    />
                  </Box>

                  <Box sx={{ mb: 2 }}>
                    <Typography gutterBottom>Position X</Typography>
                    <Slider
                      value={text.x}
                      onChange={(e, value) => handleTextChange(index, 'x', value)}
                      min={0}
                      max={100}
                    />
                  </Box>

                  <Box sx={{ mb: 2 }}>
                    <Typography gutterBottom>Position Y</Typography>
                    <Slider
                      value={text.y}
                      onChange={(e, value) => handleTextChange(index, 'y', value)}
                      min={0}
                      max={100}
                    />
                  </Box>

                  <Box sx={{ mb: 2 }}>
                    <Typography gutterBottom>Font Size</Typography>
                    <Slider
                      value={text.size}
                      onChange={(e, value) => handleTextChange(index, 'size', value)}
                      min={12}
                      max={72}
                    />
                  </Box>

                  <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
                    <IconButton
                      onClick={() => handleTextChange(index, 'bold', !text.bold)}
                      color={text.bold ? 'primary' : 'default'}
                    >
                      <BoldIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleTextChange(index, 'italic', !text.italic)}
                      color={text.italic ? 'primary' : 'default'}
                    >
                      <ItalicIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleTextChange(index, 'underline', !text.underline)}
                      color={text.underline ? 'primary' : 'default'}
                    >
                      <UnderlineIcon />
                    </IconButton>
                    <input
                      type="color"
                      value={text.color}
                      onChange={(e) => handleTextChange(index, 'color', e.target.value)}
                      style={{ width: '40px', height: '40px' }}
                    />
                  </Box>

                  <Box sx={{ mb: 2 }}>
                    <Typography gutterBottom>Text Rotation</Typography>
                    <Slider
                      value={text.rotation}
                      onChange={(e, value) => handleTextChange(index, 'rotation', value)}
                      min={-180}
                      max={180}
                    />
                  </Box>

                  <Box sx={{ mb: 2 }}>
                    <Typography gutterBottom>Outline Width</Typography>
                    <Slider
                      value={text.outlineWidth}
                      onChange={(e, value) => handleTextChange(index, 'outlineWidth', value)}
                      min={0}
                      max={5}
                      step={0.5}
                    />
                  </Box>

                  <Box sx={{ mb: 2 }}>
                    <Typography gutterBottom>Outline Color</Typography>
                    <input
                      type="color"
                      value={text.outlineColor}
                      onChange={(e) => handleTextChange(index, 'outlineColor', e.target.value)}
                      style={{ width: '40px', height: '40px' }}
                    />
                  </Box>

                  <Box sx={{ mb: 2 }}>
                    <Typography gutterBottom>Text Effect</Typography>
                    <Select
                      value={text.effect}
                      onChange={(e) => handleTextChange(index, 'effect', e.target.value)}
                      fullWidth
                    >
                      <MenuItem value="none">None</MenuItem>
                      <MenuItem value="shadow">Shadow</MenuItem>
                      <MenuItem value="glow">Glow</MenuItem>
                    </Select>
                  </Box>

                  <Box sx={{ mb: 2 }}>
                    <Typography gutterBottom>Font Family</Typography>
                    <Select
                      value={text.fontFamily}
                      onChange={(e) => handleTextChange(index, 'fontFamily', e.target.value)}
                      fullWidth
                    >
                      <ListSubheader>System Fonts</ListSubheader>
                      {fontFamilies
                        .filter(font => font.type === 'system')
                        .map((font) => (
                          <MenuItem key={font.value} value={font.value}>
                            {font.name}
                          </MenuItem>
                        ))}
                      <ListSubheader>Google Fonts</ListSubheader>
                      {fontFamilies
                        .filter(font => font.type === 'google' && !font.script)
                        .map((font) => (
                          <MenuItem key={font.value} value={font.value}>
                            {font.name}
                          </MenuItem>
                        ))}
                      <ListSubheader>Indic Fonts</ListSubheader>
                      {fontFamilies
                        .filter(font => font.type === 'google' && font.script && ['devanagari', 'bengali', 'tamil', 'telugu', 'kannada', 'malayalam', 'gujarati'].includes(font.script))
                        .map((font) => (
                          <MenuItem key={font.value} value={font.value}>
                            {font.name}
                          </MenuItem>
                        ))}
                      <ListSubheader>Other Language Fonts</ListSubheader>
                      {fontFamilies
                        .filter(font => font.type === 'google' && font.script && !['devanagari', 'bengali', 'tamil', 'telugu', 'kannada', 'malayalam', 'gujarati'].includes(font.script))
                        .map((font) => (
                          <MenuItem key={font.value} value={font.value}>
                            {font.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </Box>
                </CardContent>
              </Card>
            ))}
          </StyledPaper>
        </Grid>
      </Grid>

      {/* Content Creation Tools */}
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <Typography variant="h6">Content Creation Tools</Typography>
        </Grid>
        <Grid item xs={3}>
          <Button
            fullWidth
            variant={showAudioTool ? 'contained' : 'outlined'}
            onClick={() => setShowAudioTool(!showAudioTool)}
            startIcon={<MusicNoteIcon />}
          >
            Audio
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            fullWidth
            variant={showVideoTool ? 'contained' : 'outlined'}
            onClick={() => setShowVideoTool(!showVideoTool)}
            startIcon={<VideocamIcon />}
          >
            Video
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            fullWidth
            variant={showShapeTool ? 'contained' : 'outlined'}
            onClick={() => setShowShapeTool(!showShapeTool)}
            startIcon={<ShapeLineIcon />}
          >
            Shapes
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            fullWidth
            variant={showStickerTool ? 'contained' : 'outlined'}
            onClick={() => setShowStickerTool(!showStickerTool)}
            startIcon={<EmojiEmotionsIcon />}
          >
            Stickers
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            fullWidth
            variant={showDrawingTool ? 'contained' : 'outlined'}
            onClick={() => setShowDrawingTool(!showDrawingTool)}
            startIcon={<BrushIcon />}
          >
            Drawing
          </Button>
        </Grid>
      </Grid>

      {/* Tool Panels */}
      {showAudioTool && <AudioTool />}
      {showVideoTool && <VideoTool />}
      {showShapeTool && (
        <StyledPaper>
          <Typography variant="h6" gutterBottom>
            Shapes
          </Typography>
          <Stack direction="row" spacing={1}>
            <Button
              variant="outlined"
              onClick={() => addShape('rectangle', shapeColor)}
            >
              Rectangle
            </Button>
            <Button
              variant="outlined"
              onClick={() => addShape('circle', shapeColor)}
            >
              Circle
            </Button>
            <Button
              variant="outlined"
              onClick={() => addShape('triangle', shapeColor)}
            >
              Triangle
            </Button>
          </Stack>
          <Box sx={{ mt: 2 }}>
            <Typography gutterBottom>Color</Typography>
            <input
              type="color"
              value={shapeColor}
              onChange={(e) => setShapeColor(e.target.value)}
              style={{ width: '100%' }}
            />
          </Box>
        </StyledPaper>
      )}
      {showStickerTool && (
        <StyledPaper>
          <Typography variant="h6" gutterBottom>
            Stickers & Emojis
          </Typography>
          <Grid container spacing={1}>
            {['😀', '😎', '🎉', '❤️', '🌟', '👍', '🔥', '💯'].map((emoji) => (
              <Grid item key={emoji}>
                <Button
                  variant="outlined"
                  onClick={() => addSticker(emoji)}
                  sx={{ minWidth: 'unset', width: 40, height: 40 }}
                >
                  {emoji}
                </Button>
              </Grid>
            ))}
          </Grid>
        </StyledPaper>
      )}
      {showDrawingTool && <DrawingTool />}

      {/* Image Filter Controls */}
      <StyledPaper>
        <Typography variant="h6" gutterBottom>
          Image Filters
        </Typography>
        <Box sx={{ width: '100%', my: 2 }}>
          <Typography gutterBottom>Brightness</Typography>
          <Slider
            value={filters.brightness}
            onChange={(e, value) => handleFilterChange('brightness', value)}
            min={0}
            max={200}
            valueLabelDisplay="auto"
          />
        </Box>
        <Box sx={{ width: '100%', my: 2 }}>
          <Typography gutterBottom>Contrast</Typography>
          <Slider
            value={filters.contrast}
            onChange={(e, value) => handleFilterChange('contrast', value)}
            min={0}
            max={200}
            valueLabelDisplay="auto"
          />
        </Box>
        <Box sx={{ width: '100%', my: 2 }}>
          <Typography gutterBottom>Saturation</Typography>
          <Slider
            value={filters.saturation}
            onChange={(e, value) => handleFilterChange('saturation', value)}
            min={0}
            max={200}
            valueLabelDisplay="auto"
          />
        </Box>
      </StyledPaper>

      {/* Templates Section */}
      <StyledPaper>
        <Typography variant="h6" gutterBottom>
          Popular Templates
        </Typography>
        <Grid container spacing={2}>
          {templates
            .filter(template => selectedCategory === 'all' || template.category === selectedCategory)
            .map((template, index) => (
              <Grid item xs={6} sm={4} md={3} key={index}>
                <Card
                  onClick={() => handleTemplateSelect(template)}
                  sx={{ cursor: 'pointer', '&:hover': { transform: 'scale(1.02)' } }}
                >
                  <img
                    src={template.url}
                    alt={template.name}
                    style={{ width: '100%', height: 'auto' }}
                  />
                  <CardContent>
                    <Typography variant="body2">{template.name}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
        </Grid>
        <Box sx={{ mb: 2, display: 'flex', gap: 1 }}>
          <Button
            variant={selectedCategory === 'all' ? 'contained' : 'outlined'}
            onClick={() => setSelectedCategory('all')}
          >
            All
          </Button>
          <Button
            variant={selectedCategory === 'classic' ? 'contained' : 'outlined'}
            onClick={() => setSelectedCategory('classic')}
          >
            Classic
          </Button>
          <Button
            variant={selectedCategory === 'reaction' ? 'contained' : 'outlined'}
            onClick={() => setSelectedCategory('reaction')}
          >
            Reaction
          </Button>
          <Button
            variant={selectedCategory === 'modern' ? 'contained' : 'outlined'}
            onClick={() => setSelectedCategory('modern')}
          >
            Modern
          </Button>
          <Button
            variant={selectedCategory === 'wholesome' ? 'contained' : 'outlined'}
            onClick={() => setSelectedCategory('wholesome')}
          >
            Wholesome
          </Button>
        </Box>
      </StyledPaper>

      {/* Documentation */}
      <StyledPaper>
        <Typography variant="h6" gutterBottom>
          About This Tool
        </Typography>
        <Typography paragraph>
          Create custom memes easily with our Meme Generator. Upload your own images or choose from popular templates,
          add multiple text captions, customize fonts, and share your creations with friends.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Features
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <ul>
              <li>Upload custom images or use templates</li>
              <li>Add multiple text captions</li>
              <li>Customize text size, color, and style</li>
              <li>Drag text to position</li>
            </ul>
          </Grid>
          <Grid item xs={12} md={6}>
            <ul>
              <li>Bold, italic, and underline options</li>
              <li>Download memes in high quality</li>
              <li>Popular meme templates included</li>
              <li>Easy-to-use interface</li>
            </ul>
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom>
          How to Use
        </Typography>
        <ol>
          <li>Upload an image or select a template</li>
          <li>Add text captions using the "Add Text" button</li>
          <li>Customize text properties (size, color, style)</li>
          <li>Position your text by adjusting the sliders</li>
          <li>Download your finished meme</li>
        </ol>
      </StyledPaper>

      {/* Tips Section */}
      <StyledPaper>
        <Typography variant="h6" gutterBottom>
          Tips for Creating Great Memes
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom>
              1. Keep it Simple
            </Typography>
            <Typography paragraph>
              The best memes are often the simplest ones. Don't overcrowd your meme with too much text or too many elements.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom>
              2. Use Contrast
            </Typography>
            <Typography paragraph>
              Make sure your text is readable by using contrasting colors and appropriate font sizes.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom>
              3. Stay Relevant
            </Typography>
            <Typography paragraph>
              Try to create memes that are relevant to current trends, events, or universal experiences.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom>
              4. Be Original
            </Typography>
            <Typography paragraph>
              While using templates is great, adding your own unique twist will make your memes stand out.
            </Typography>
          </Grid>
        </Grid>
      </StyledPaper>

      {/* Virtual Keyboard */}
      {showKeyboard && (
        <StyledPaper>
          <Box sx={{ mb: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <FormControl component="fieldset">
                <Typography variant="subtitle1" gutterBottom>
                  Typing Mode: {currentLanguage === 'hi' ? 'Hindi' : currentLanguage}
                </Typography>
              </FormControl>
              <IconButton onClick={() => setShowKeyboard(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
            
            <div ref={keyboardContainerRef} />
          </Box>
        </StyledPaper>
      )}
    </Container>
  );
};

export default MemeGenerator;
