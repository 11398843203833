import React, { useState, useRef } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  Button,
  TextField,
  CircularProgress,
  Alert,
  Tabs,
  Tab,
  IconButton,
} from '@mui/material';
import {
  CameraAlt as CameraIcon,
  FileUpload as FileUploadIcon,
  ContentCopy as ContentCopyIcon,
  Link as LinkIcon,
  RestartAlt as RestartIcon,
} from '@mui/icons-material';
import { Html5Qrcode } from 'html5-qrcode';
import ToolDescription from '../common/ToolDescription';

function QRCodeScanner() {
  const [scanning, setScanning] = useState(false);
  const [result, setResult] = useState('');
  const [error, setError] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [copied, setCopied] = useState(false);
  const fileInputRef = useRef(null);
  const scannerRef = useRef(null);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    stopScanning();
  };

  const startCameraScanning = async () => {
    try {
      setError('');
      setResult('');
      setScanning(true);

      const html5QrCode = new Html5Qrcode('reader');
      scannerRef.current = html5QrCode;

      const qrCodeSuccessCallback = (decodedText) => {
        setResult(decodedText);
        stopScanning();
      };

      const config = { fps: 10, qrbox: { width: 250, height: 250 } };
      await html5QrCode.start(
        { facingMode: 'environment' },
        config,
        qrCodeSuccessCallback,
        () => {}
      );
    } catch (err) {
      setError('Failed to access camera. Please ensure camera permissions are granted.');
      setScanning(false);
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      setError('');
      setResult('');
      setScanning(true);

      const html5QrCode = new Html5Qrcode('reader');
      scannerRef.current = html5QrCode;

      const result = await html5QrCode.scanFile(file, true);
      setResult(result);
    } catch (err) {
      setError('Failed to scan QR code from image. Please try a different image.');
    } finally {
      setScanning(false);
      if (scannerRef.current) {
        await scannerRef.current.clear();
      }
    }
  };

  const stopScanning = async () => {
    if (scannerRef.current && scanning) {
      try {
        await scannerRef.current.stop();
      } catch (err) {
        console.error('Error stopping scanner:', err);
      }
    }
    setScanning(false);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(result);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleOpenLink = () => {
    if (result.startsWith('http://') || result.startsWith('https://')) {
      window.open(result, '_blank');
    }
  };

  const resetScanner = () => {
    setResult('');
    setError('');
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  return (
    <Container maxWidth="lg">
      <Paper elevation={3} sx={{ p: 4, my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          QR Code Scanner
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          Scan QR codes using your camera or upload QR code images
        </Typography>

        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          centered
          sx={{ mb: 3 }}
        >
          <Tab icon={<CameraIcon />} label="Camera" />
          <Tab icon={<FileUploadIcon />} label="Upload Image" />
        </Tabs>

        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
          {error && (
            <Alert severity="error" sx={{ width: '100%', maxWidth: 500 }}>
              {error}
            </Alert>
          )}

          <Box
            id="reader"
            sx={{
              width: '100%',
              maxWidth: 500,
              height: 300,
              border: '1px solid #ccc',
              borderRadius: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            {scanning && <CircularProgress />}
          </Box>

          {activeTab === 0 ? (
            <Button
              variant="contained"
              startIcon={scanning ? <RestartIcon /> : <CameraIcon />}
              onClick={scanning ? stopScanning : startCameraScanning}
              color={scanning ? 'error' : 'primary'}
            >
              {scanning ? 'Stop Scanning' : 'Start Camera'}
            </Button>
          ) : (
            <Button
              variant="contained"
              component="label"
              startIcon={<FileUploadIcon />}
            >
              Upload QR Code
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={handleFileUpload}
                ref={fileInputRef}
              />
            </Button>
          )}

          {result && (
            <Paper
              sx={{
                width: '100%',
                maxWidth: 500,
                p: 2,
                mt: 2,
                backgroundColor: '#f5f5f5',
              }}
            >
              <Typography variant="subtitle1" gutterBottom>
                Scanned Result:
              </Typography>
              <TextField
                fullWidth
                value={result}
                multiline
                maxRows={4}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <IconButton onClick={handleCopy} title="Copy to clipboard">
                        <ContentCopyIcon />
                      </IconButton>
                      {isValidUrl(result) && (
                        <IconButton onClick={handleOpenLink} title="Open link">
                          <LinkIcon />
                        </IconButton>
                      )}
                    </Box>
                  ),
                }}
              />
              {copied && (
                <Alert severity="success" sx={{ mt: 1 }}>
                  Copied to clipboard!
                </Alert>
              )}
              <Button
                variant="outlined"
                startIcon={<RestartIcon />}
                onClick={resetScanner}
                sx={{ mt: 2 }}
              >
                Scan Another
              </Button>
            </Paper>
          )}
        </Box>
      </Paper>

      <ToolDescription
        title="QR Code Scanner"
        description="A versatile QR code scanner that supports both camera scanning and image uploads."
        benefits={[
          "Real-time QR code scanning using device camera",
          "Support for QR code image uploads",
          "Instant result display with copy functionality",
          "Automatic URL detection and quick access",
          "Easy-to-use interface",
          "Mobile-friendly design"
        ]}
        useCases={[
          "Scan QR codes from physical materials",
          "Extract information from digital QR codes",
          "Quick access to websites via QR codes",
          "Process QR codes from saved images",
          "Share contact information",
          "Access event details"
        ]}
        howTo={[
          "Choose between camera or image upload",
          "For camera: Click 'Start Camera' and point at QR code",
          "For images: Click 'Upload QR Code' and select an image",
          "View the scanned result instantly",
          "Copy the result or open URLs directly",
          "Click 'Scan Another' to start over"
        ]}
      />
    </Container>
  );
}

export default QRCodeScanner;
