import React, { useState, useRef } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  Button,
  Alert,
  Grid,
  CircularProgress,
  Slider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  LinearProgress,
} from '@mui/material';
import {
  Upload as UploadIcon,
  Download as DownloadIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import ToolDescription from '../common/ToolDescription';

const ffmpeg = new FFmpeg();

const QUALITY_PRESETS = {
  high: { crf: 18, preset: 'slow' },
  medium: { crf: 23, preset: 'medium' },
  low: { crf: 28, preset: 'fast' },
};

function VideoCompressor() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState('');
  const [ffmpegLoaded, setFfmpegLoaded] = useState(false);
  const [quality, setQuality] = useState('medium');
  const [compressionProgress, setCompressionProgress] = useState(0);
  const videoRef = useRef(null);

  // Load FFmpeg on component mount
  React.useEffect(() => {
    const loadFFmpeg = async () => {
      try {
        if (!ffmpegLoaded) {
          await ffmpeg.load({
            coreURL: `${window.location.origin}/ffmpeg-core.js`,
            wasmURL: `${window.location.origin}/ffmpeg-core.wasm`
          });
          setFfmpegLoaded(true);
        }
      } catch (error) {
        console.error('Error loading FFmpeg:', error);
        setError('Failed to load video processing library. Please try again.');
      }
    };
    loadFFmpeg();
  }, [ffmpegLoaded]);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type.startsWith('video/')) {
        setSelectedFile(file);
        setPreviewUrl(URL.createObjectURL(file));
        setError('');
      } else {
        setError('Please select a valid video file.');
      }
    }
  };

  const handleQualityChange = (event) => {
    setQuality(event.target.value);
  };

  const handleCompress = async () => {
    if (!selectedFile || !ffmpegLoaded) return;

    try {
      setProcessing(true);
      setError('');
      setCompressionProgress(0);

      // Write the input file to FFmpeg's virtual file system
      await ffmpeg.writeFile('input.mp4', await fetchFile(selectedFile));

      const { crf, preset } = QUALITY_PRESETS[quality];

      // Execute FFmpeg command to compress the video
      await ffmpeg.exec([
        '-i', 'input.mp4',
        '-c:v', 'libx264',
        '-crf', crf.toString(),
        '-preset', preset,
        '-c:a', 'aac',
        '-b:a', '128k',
        'output.mp4'
      ]);

      // Read the output file
      const data = await ffmpeg.readFile('output.mp4');
      const blob = new Blob([data.buffer], { type: 'video/mp4' });
      
      // Create download link
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'compressed_video.mp4';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);

    } catch (error) {
      console.error('Error compressing video:', error);
      setError('Failed to compress video. Please try again.');
    } finally {
      setProcessing(false);
      setCompressionProgress(100);
    }
  };

  const handleClear = () => {
    setSelectedFile(null);
    setPreviewUrl('');
    setError('');
    setCompressionProgress(0);
  };

  const fetchFile = async (file) => {
    const response = await fetch(URL.createObjectURL(file));
    const data = await response.arrayBuffer();
    return new Uint8Array(data);
  };

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 3, my: 3 }}>
        <ToolDescription
          title="Video Compressor"
          description="Compress your videos while maintaining good quality. Choose from different compression levels to balance size and quality."
          benefits={[
            "Reduce video file size while preserving quality",
            "Multiple quality presets available",
            "Fast compression with hardware acceleration",
            "Support for various video formats",
            "Real-time preview capability"
          ]}
          useCases={[
            "Compress videos for email sharing",
            "Optimize videos for web upload",
            "Save storage space",
            "Prepare videos for social media",
            "Reduce bandwidth usage"
          ]}
          howTo={[
            "Upload your video file",
            "Choose compression quality (High, Medium, or Low)",
            "Click 'Compress Video' to start",
            "Wait for processing to complete",
            "Download your compressed video"
          ]}
        />

        <Box sx={{ my: 3 }}>
          <input
            accept="video/*"
            style={{ display: 'none' }}
            id="video-file-input"
            type="file"
            onChange={handleFileSelect}
          />
          <label htmlFor="video-file-input">
            <Button
              variant="contained"
              component="span"
              startIcon={<UploadIcon />}
              disabled={processing}
            >
              Select Video
            </Button>
          </label>

          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}

          {selectedFile && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1" gutterBottom>
                Selected file: {selectedFile.name}
              </Typography>
              <video
                ref={videoRef}
                src={previewUrl}
                controls
                style={{ width: '100%', maxHeight: '400px' }}
              />

              <Box sx={{ mt: 2 }}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel>Compression Quality</InputLabel>
                  <Select
                    value={quality}
                    onChange={handleQualityChange}
                    label="Compression Quality"
                    disabled={processing}
                  >
                    <MenuItem value="high">High Quality (Larger File)</MenuItem>
                    <MenuItem value="medium">Medium Quality (Balanced)</MenuItem>
                    <MenuItem value="low">Low Quality (Smaller File)</MenuItem>
                  </Select>
                </FormControl>

                {compressionProgress > 0 && (
                  <Box sx={{ width: '100%', mb: 2 }}>
                    <LinearProgress variant="determinate" value={compressionProgress} />
                  </Box>
                )}

                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      variant="contained"
                      onClick={handleCompress}
                      startIcon={<DownloadIcon />}
                      disabled={processing || !ffmpegLoaded}
                    >
                      {processing ? (
                        <>
                          <CircularProgress size={24} sx={{ mr: 1 }} />
                          Compressing...
                        </>
                      ) : (
                        'Compress Video'
                      )}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={handleClear}
                      startIcon={<DeleteIcon />}
                      disabled={processing}
                    >
                      Clear
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}
        </Box>
      </Paper>
    </Container>
  );
}

export default VideoCompressor;
