import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Slider,
  TextField,
  Typography,
  CircularProgress,
  Alert,
  IconButton,
  LinearProgress,
  Grid
} from '@mui/material';
import {
  CloudUpload as CloudUploadIcon,
  Close as CloseIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
  Refresh as RefreshIcon,
  Save as SaveIcon,
  PlayArrow as PlayArrowIcon,
  Stop as StopIcon
} from '@mui/icons-material';
import { SketchPicker } from 'react-color';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { toBlobURL } from '@ffmpeg/util';
import ToolDescription from '../common/ToolDescription';

function VideoWatermark() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [watermarkFile, setWatermarkFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [watermarkPreviewUrl, setWatermarkPreviewUrl] = useState('');
  const [watermarkType, setWatermarkType] = useState('text');
  const [watermarkText, setWatermarkText] = useState('');
  const [watermarkPosition, setWatermarkPosition] = useState('center');
  const [processing, setProcessing] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState('');
  const [ffmpegLoaded, setFfmpegLoaded] = useState(false);
  const [ffmpegLoading, setFfmpegLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [opacity, setOpacity] = useState(1);
  const [fontSize, setFontSize] = useState(24);
  const [textColor, setTextColor] = useState('#ffffff');
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const ffmpegRef = useRef(new FFmpeg());
  const videoPreviewRef = useRef(null);

  // List of supported video formats
  const SUPPORTED_FORMATS = {
    'video/mp4': ['.mp4'],
    'video/quicktime': ['.mov'],
    'video/x-msvideo': ['.avi'],
    'video/webm': ['.webm'],
    'video/x-matroska': ['.mkv']
  };

  const isVideoFormatSupported = (file) => {
    const fileType = file.type;
    const extension = file.name.toLowerCase().split('.').pop();
    
    // Check if the mime type is supported
    if (SUPPORTED_FORMATS[fileType]) {
      return true;
    }
    
    // Check if the extension is supported (backup check)
    return Object.values(SUPPORTED_FORMATS).flat().includes(`.${extension}`);
  };

  useEffect(() => {
    const loadFFmpeg = async () => {
      try {
        const ffmpeg = ffmpegRef.current;
        if (!ffmpegLoaded) {
          ffmpeg.on('log', ({ message }) => {
            console.log(message);
            if (message.includes('fetch')) {
              setLoadingProgress(30);
            } else if (message.includes('instantiate')) {
              setLoadingProgress(60);
            } else if (message.includes('ready')) {
              setLoadingProgress(90);
            }
          });
          
          ffmpeg.on('progress', ({ progress }) => {
            setProgress(Math.round(progress * 100));
          });

          const baseURL = 'https://cdn.jsdelivr.net/npm/@ffmpeg/core@0.12.4/dist/umd';
          setLoadingProgress(10);
          
          await ffmpeg.load({
            coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
            wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
            workerURL: await toBlobURL(`${baseURL}/ffmpeg-core.worker.js`, 'text/javascript'),
          });
          
          setLoadingProgress(100);
          setFfmpegLoaded(true);
        }
      } catch (err) {
        console.error('Error loading FFmpeg:', err);
        setError('Failed to load FFmpeg. Please check your internet connection and try again.');
      } finally {
        setFfmpegLoading(false);
      }
    };

    loadFFmpeg();
  }, [ffmpegLoaded]);

  const handleFileSelect = async (event, isWatermark = false) => {
    try {
      const file = event.target.files?.[0];
      if (!file) return;

      setError('');
      setIsUploading(true);
      setUploadProgress(0);

      // Validate file size (500MB)
      const MAX_FILE_SIZE = 500 * 1024 * 1024;
      if (file.size > MAX_FILE_SIZE) {
        const fileSizeMB = Math.round(file.size / (1024 * 1024));
        const maxSizeMB = Math.round(MAX_FILE_SIZE / (1024 * 1024));
        throw new Error(`File size (${fileSizeMB}MB) exceeds ${maxSizeMB}MB limit. Please choose a smaller file or compress the video first.`);
      }

      // Validate file type
      if (!file.type.startsWith('video/') && !isWatermark) {
        throw new Error('Please select a valid video file.');
      }

      if (isWatermark && !file.type.startsWith('image/')) {
        throw new Error('Please select a valid image file.');
      }

      // Check if format is supported
      if (!isVideoFormatSupported(file) && !isWatermark) {
        const supportedFormats = Object.values(SUPPORTED_FORMATS).flat().join(', ');
        throw new Error(`Unsupported video format. Supported formats are: ${supportedFormats}`);
      }

      // Create a promise that resolves with the file data
      const readFile = () => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadstart = () => setUploadProgress(0);
        reader.onprogress = (e) => {
          if (e.lengthComputable) {
            const progress = (e.loaded / e.total) * 100;
            setUploadProgress(Math.round(progress));
          }
        };
        reader.onload = () => resolve(reader.result);
        reader.onerror = () => reject(new Error('Failed to read file'));
        reader.readAsArrayBuffer(file);
      });

      // Read the file
      await readFile();

      if (isWatermark) {
        setWatermarkFile(file);
        setWatermarkPreviewUrl(URL.createObjectURL(file));
      } else {
        setSelectedFile(file);
        setPreviewUrl(URL.createObjectURL(file));
      }
    } catch (err) {
      console.error('Error handling file:', err);
      setError(err.message || 'Failed to process file. Please try again.');
    } finally {
      setIsUploading(false);
      setUploadProgress(0);
    }
  };

  const getPositionCoordinates = () => {
    const positions = {
      'top-left': '10:10',
      'top-center': '(w-text_w)/2:10',
      'top-right': 'w-tw-10:10',
      'center-left': '10:(h-th)/2',
      'center': '(w-text_w)/2:(h-th)/2',
      'center-right': 'w-tw-10:(h-th)/2',
      'bottom-left': '10:h-th-10',
      'bottom-center': '(w-text_w)/2:h-th-10',
      'bottom-right': 'w-tw-10:h-th-10',
    };
    return positions[watermarkPosition] || 'center';
  };

  const applyWatermark = async () => {
    if (!selectedFile || !ffmpegLoaded) {
      setError('Please select a video file and wait for FFmpeg to load.');
      return;
    }

    // Check file size - reduce to 100MB for better reliability
    const MAX_SIZE = 100 * 1024 * 1024; // 100MB
    if (selectedFile.size > MAX_SIZE) {
      setError(`Video file is too large. Maximum size is 100MB for reliable processing.`);
      return;
    }

    try {
      setProcessing(true);
      setProgress(0);
      setError('');
      const ffmpeg = ffmpegRef.current;

      // Clean up
      console.log('Cleaning up...');
      try {
        await ffmpeg.deleteFile('input.mp4');
        await ffmpeg.deleteFile('temp.mp4');
        await ffmpeg.deleteFile('output.mp4');
        await ffmpeg.deleteFile('watermark.png');
      } catch (err) {
        console.log('Cleanup:', err);
      }

      // Write input file in smaller chunks
      console.log('Writing input file...');
      try {
        const CHUNK_SIZE = 1024 * 1024; // 1MB chunks
        const videoData = await selectedFile.arrayBuffer();
        const totalChunks = Math.ceil(videoData.byteLength / CHUNK_SIZE);
        
        for (let i = 0; i < totalChunks; i++) {
          const start = i * CHUNK_SIZE;
          const end = Math.min(start + CHUNK_SIZE, videoData.byteLength);
          const chunk = new Uint8Array(videoData.slice(start, end));
          
          if (i === 0) {
            await ffmpeg.writeFile('input.mp4', chunk);
          } else {
            const existingData = await ffmpeg.readFile('input.mp4');
            const newData = new Uint8Array(existingData.length + chunk.length);
            newData.set(existingData);
            newData.set(chunk, existingData.length);
            await ffmpeg.writeFile('input.mp4', newData);
          }
          
          setProgress(Math.floor((i + 1) * 20 / totalChunks)); // 0-20% progress
        }
      } catch (writeError) {
        console.error('Write error:', writeError);
        throw new Error('Failed to process video file. Please try a smaller video.');
      }

      // Extreme transcoding for minimum memory usage
      console.log('Transcoding video...');
      try {
        await ffmpeg.exec([
          '-i', 'input.mp4',
          '-vf', 'scale=480:-2', // 480p
          '-r', '24', // Reduce framerate
          '-c:v', 'libx264',
          '-preset', 'ultrafast',
          '-crf', '40', // Very low quality
          '-tune', 'fastdecode,zerolatency',
          '-profile:v', 'baseline',
          '-level', '3.0',
          '-c:a', 'aac',
          '-b:a', '32k', // Minimal audio
          '-ac', '1',
          '-y',
          'temp.mp4'
        ]);
        
        await ffmpeg.deleteFile('input.mp4');
        setProgress(40);
      } catch (err) {
        console.error('Transcode error:', err);
        throw new Error('Video format not supported or corrupted.');
      }

      // Add watermark
      console.log('Adding watermark...');
      let complexFilter = '';

      if (watermarkType === 'text' && watermarkText.trim()) {
        const pos = getPositionCoordinates();
        const escapedText = watermarkText.replace(/['"]/g, '\\"').trim();
        if (!escapedText) {
          throw new Error('Please enter watermark text');
        }
        
        // Minimal text watermark
        const simplifiedFontSize = Math.min(fontSize, 20);
        complexFilter = `drawtext=text='${escapedText}':fontsize=${simplifiedFontSize}:fontcolor=${textColor.replace('#', '0x')}@${opacity}:x=${pos.split(':')[0]}:y=${pos.split(':')[1]}`;
      } else if (watermarkType === 'image' && watermarkFile) {
        const watermarkData = await watermarkFile.arrayBuffer();
        await ffmpeg.writeFile('watermark.png', new Uint8Array(watermarkData));
        const pos = getPositionCoordinates();
        // Scale watermark to 1/6 of video size
        complexFilter = `[0:v][1:v]scale2ref=iw/6:-1[watermark][video];[video][watermark]overlay=${pos}:alpha=${opacity}[out]`;
      } else {
        throw new Error('Please select a watermark type and provide content');
      }

      setProgress(60);

      try {
        const commands = [
          '-i', 'temp.mp4',
          ...(watermarkType === 'image' ? ['-i', 'watermark.png'] : []),
          '-filter_complex', complexFilter,
          '-c:v', 'libx264',
          '-preset', 'ultrafast',
          '-crf', '40',
          '-tune', 'fastdecode,zerolatency',
          '-profile:v', 'baseline',
          '-level', '3.0',
          '-maxrate', '500k',
          '-bufsize', '500k',
          '-c:a', 'copy',
          '-movflags', '+faststart',
          '-f', 'mp4',
          '-y',
          'output.mp4'
        ];

        if (watermarkType === 'image') {
          commands.push('-map', '[out]', '-map', '0:a?');
        }

        await ffmpeg.exec(commands);
        setProgress(80);

        // Cleanup temp files
        await ffmpeg.deleteFile('temp.mp4');
        if (watermarkType === 'image') {
          await ffmpeg.deleteFile('watermark.png');
        }
      } catch (err) {
        console.error('Watermark error:', err);
        throw new Error('Failed to add watermark. Try simpler text or smaller image.');
      }

      // Read output in chunks and create download
      console.log('Preparing download...');
      try {
        const outputData = await ffmpeg.readFile('output.mp4');
        if (!outputData || outputData.length === 0) {
          throw new Error('Failed to generate output file');
        }

        const blob = new Blob([outputData.buffer], { type: 'video/mp4' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `watermarked_${selectedFile.name}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
        
        await ffmpeg.deleteFile('output.mp4');
        setProgress(100);
        console.log('Success!');
      } catch (err) {
        console.error('Download error:', err);
        throw new Error('Browser memory limit reached. Try Chrome browser or a smaller video file.');
      }
    } catch (err) {
      console.error('Error processing video:', err);
      let errorMessage = 'Failed to process video. ';
      
      // Safely check error message
      const errorStr = err?.message || String(err) || 'Unknown error';
      
      if (errorStr.includes('allocation failed') || errorStr.includes('Memory limit')) {
        errorMessage += 'Video file is too large for browser memory. Try a smaller file or compress the video first.';
      } else if (errorStr.includes('incorrect codec parameters') || errorStr.includes('Invalid data')) {
        errorMessage += 'Invalid video format or corrupted file. Please try another video.';
      } else if (errorStr.includes('drawtext')) {
        errorMessage += 'Error adding text watermark. Please try simpler text or different position.';
      } else if (errorStr.includes('overlay')) {
        errorMessage += 'Error adding image watermark. Please try a different image or position.';
      } else {
        errorMessage += `${errorStr}\nPlease make sure:\n1. The video format is supported (MP4, MOV, AVI, WEBM, MKV)\n2. The video is not corrupted\n3. Your browser has enough memory available`;
      }
      
      setError(errorMessage);
    } finally {
      setProcessing(false);
      setProgress(0);
      
      // Cleanup FFmpeg files
      try {
        const ffmpeg = ffmpegRef.current;
        await ffmpeg.deleteFile('input.mp4');
        await ffmpeg.deleteFile('output.mp4');
        if (watermarkType === 'image') {
          await ffmpeg.deleteFile('watermark.png');
        }
      } catch (err) {
        console.error('Error cleaning up files:', err);
      }
    }
  };

  const clearVideo = () => {
    setSelectedFile(null);
    setPreviewUrl('');
    setError('');
  };

  const clearWatermark = () => {
    setWatermarkFile(null);
    setWatermarkPreviewUrl('');
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      {/* Main Tool Paper */}
      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Typography variant="h4" gutterBottom>
          Video Watermark Tool
        </Typography>

        {error && (
          <Alert 
            severity="error" 
            sx={{ mb: 2 }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => setError('')}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {error}
          </Alert>
        )}

        {/* File Upload Section */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            1. Upload Video
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <label htmlFor="video-upload">
              <input
                id="video-upload"
                type="file"
                accept="video/*"
                style={{ display: 'none' }}
                onChange={handleFileSelect}
                disabled={processing || isUploading}
              />
              <Button
                variant="contained"
                component="span"
                startIcon={<CloudUploadIcon />}
                disabled={processing || isUploading}
              >
                Upload Video
              </Button>
            </label>

            {/* Upload Progress */}
            {isUploading && (
              <Box sx={{ width: '100%', mt: 2 }}>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  Uploading video... {uploadProgress}%
                </Typography>
                <LinearProgress variant="determinate" value={uploadProgress} />
              </Box>
            )}
          </Box>
        </Box>

        {/* Video Preview */}
        {selectedFile && (
          <Box sx={{ mb: 4, textAlign: 'center' }}>
            <video
              ref={videoPreviewRef}
              src={previewUrl}
              controls
              style={{
                maxWidth: '100%',
                maxHeight: '400px',
              }}
            />
          </Box>
        )}

        {/* Watermark Options */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            2. Add Watermark
          </Typography>
          <FormControl>
            <FormLabel id="watermark-type">Watermark Type</FormLabel>
            <RadioGroup
              aria-labelledby="watermark-type"
              name="watermark-type"
              value={watermarkType}
              onChange={(e) => setWatermarkType(e.target.value)}
              row
            >
              <FormControlLabel value="text" control={<Radio />} label="Text" />
              <FormControlLabel value="image" control={<Radio />} label="Image" />
            </RadioGroup>
          </FormControl>

          {watermarkType === 'text' ? (
            <Box sx={{ mt: 2 }}>
              <TextField
                fullWidth
                label="Watermark Text"
                value={watermarkText}
                onChange={(e) => setWatermarkText(e.target.value)}
                sx={{ mb: 2 }}
              />
              <Box sx={{ mt: 2 }}>
                <Typography gutterBottom>Font Size: {fontSize}px</Typography>
                <Slider
                  value={fontSize}
                  onChange={(e, value) => setFontSize(value)}
                  min={12}
                  max={200}
                  valueLabelDisplay="auto"
                />
              </Box>
              <Box sx={{ mt: 2 }}>
                <Button
                  variant="outlined"
                  onClick={() => setShowColorPicker(!showColorPicker)}
                  startIcon={<AddIcon />}
                  sx={{ backgroundColor: textColor }}
                >
                  Text Color
                </Button>
                {showColorPicker && (
                  <Box sx={{ position: 'absolute', zIndex: 2 }}>
                    <Box
                      sx={{
                        position: 'fixed',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                      }}
                      onClick={() => setShowColorPicker(false)}
                    />
                    <SketchPicker
                      color={textColor}
                      onChange={(color) => setTextColor(color.hex)}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          ) : (
            <Box sx={{ mt: 2 }}>
              <Button
                variant="outlined"
                component="label"
                startIcon={<AddIcon />}
              >
                Upload Watermark Image
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={(e) => handleFileSelect(e, true)}
                />
              </Button>
              {watermarkPreviewUrl && (
                <Box sx={{ textAlign: 'center', mt: 2 }}>
                  <img
                    src={watermarkPreviewUrl}
                    alt="Watermark"
                    style={{
                      maxWidth: '200px',
                      maxHeight: '100px',
                      objectFit: 'contain',
                    }}
                  />
                  <IconButton onClick={clearWatermark} color="error">
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )}
            </Box>
          )}
        </Box>

        {/* Position and Opacity */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            3. Customize Position & Opacity
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormControl>
                <FormLabel id="watermark-position">Position</FormLabel>
                <RadioGroup
                  aria-labelledby="watermark-position"
                  name="watermark-position"
                  value={watermarkPosition}
                  onChange={(e) => setWatermarkPosition(e.target.value)}
                >
                  <Grid container spacing={1}>
                    {[
                      ['top-left', 'Top Left'],
                      ['top-center', 'Top Center'],
                      ['top-right', 'Top Right'],
                      ['center-left', 'Center Left'],
                      ['center', 'Center'],
                      ['center-right', 'Center Right'],
                      ['bottom-left', 'Bottom Left'],
                      ['bottom-center', 'Bottom Center'],
                      ['bottom-right', 'Bottom Right']
                    ].map(([value, label]) => (
                      <Grid item xs={4} key={value}>
                        <FormControlLabel 
                          value={value} 
                          control={<Radio />} 
                          label={label}
                          sx={{ margin: 0 }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ mt: 2 }}>
                <Typography gutterBottom>Opacity: {Math.round(opacity * 100)}%</Typography>
                <Slider
                  value={opacity * 100}
                  onChange={(e, value) => setOpacity(value / 100)}
                  min={1}
                  max={100}
                  valueLabelDisplay="auto"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Apply Watermark Button */}
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
          <Button
            variant="contained"
            size="large"
            startIcon={processing ? <CircularProgress size={24} color="inherit" /> : <PlayArrowIcon />}
            onClick={applyWatermark}
            disabled={processing || (watermarkType === 'image' && !watermarkFile)}
          >
            {processing ? 'Processing...' : 'Apply Watermark and Download'}
          </Button>
        </Box>

        {/* Processing Progress */}
        {processing && (
          <Box sx={{ width: '100%', mt: 2 }}>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              Processing video... {progress}%
            </Typography>
            <LinearProgress variant="determinate" value={progress} />
          </Box>
        )}
      </Paper>

      {/* Tool Description Paper */}
      <Paper elevation={3} sx={{ p: 3 }}>
        <ToolDescription 
          title="Video Watermark"
          description="Add professional watermarks to your videos. Support both text and image watermarks with customizable position and opacity."
          benefits={[
            "Text and image watermark support",
            "Customizable position",
            "Opacity control",
            "Font size and color options",
            "Multiple position presets",
            "High-quality output",
            "Preserves video quality",
            "Maintains audio track"
          ]}
          useCases={[
            "Protect video copyright",
            "Add branding to videos",
            "Create professional watermarks",
            "Add logos to videos",
            "Create branded content",
            "Mark video ownership"
          ]}
          howTo={[
            "Upload your video",
            "Choose between text or image watermark",
            "Customize watermark appearance",
            "Adjust position",
            "Set opacity level",
            "Preview and process the video"
          ]}
        />
      </Paper>
    </Container>
  );
}

export default VideoWatermark;
