import React, { useState, useRef } from 'react';
import {
  Container,
  Box,
  Paper,
  Typography,
  Button,
  Grid,
  TextField,
  Stepper,
  Step,
  StepLabel,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  IconButton,
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
  Print as PrintIcon,
  Download as DownloadIcon,
  Palette as PaletteIcon,
  CheckCircleOutline,
  LightbulbOutlined,
  Description as TemplateIcon,
  Business as BusinessIcon,
  Brush as BrushIcon,
  FormatAlignLeft as MinimizeIcon,
  CompressOutlined as CompressIcon,
} from '@mui/icons-material';

import { styled } from '@mui/material/styles';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  margin: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
}));

const ResumeSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const steps = ['Template', 'Personal Info', 'Education', 'Experience', 'Skills', 'Preview'];

const templates = {
  modern: {
    name: 'Modern',
    preview: '/resumeTemplates/templateImages/modern.png',
    styles: {
      container: {
        padding: '40px',
        fontFamily: 'Roboto, sans-serif',
        backgroundColor: 'var(--background-color, #fff)',
      },
      header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginBottom: '30px',
        borderBottom: '3px solid var(--primary-color, #2196f3)',
        paddingBottom: '20px',
      },
      name: {
        fontSize: '2.5rem',
        fontWeight: 'bold',
        color: 'var(--primary-color, #2196f3)',
        marginBottom: '10px',
        letterSpacing: '1px',
      },
      contact: {
        color: 'var(--text-color, #666)',
        fontSize: '0.9rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
      },
      section: {
        marginBottom: '25px',
        backgroundColor: 'var(--section-bg-color, #fff)',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
      },
      sectionTitle: {
        color: 'var(--primary-color, #2196f3)',
        fontSize: '1.4rem',
        fontWeight: '600',
        marginBottom: '15px',
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        '&::before': {
          content: '""',
          width: '24px',
          height: '3px',
          backgroundColor: 'var(--primary-color, #2196f3)',
        },
      },
      experienceItem: {
        marginBottom: '20px',
        position: 'relative',
        paddingLeft: '20px',
        borderLeft: '2px solid var(--secondary-color, #e3f2fd)',
      },
      skillsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
      },
      skillItem: {
        backgroundColor: 'var(--secondary-color, #e3f2fd)',
        color: 'var(--primary-color, #2196f3)',
        padding: '8px 16px',
        borderRadius: '20px',
        fontSize: '0.9rem',
        fontWeight: '500',
        transition: 'all 0.3s ease',
        '&:hover': {
          transform: 'translateY(-2px)',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        },
      },
    },
  },
  professional: {
    name: 'Professional',
    preview: '/resumeTemplates/templateImages/professional.png',
    styles: {
      container: {
        padding: '40px',
        fontFamily: 'Georgia, serif',
        backgroundColor: 'var(--background-color, #fff)',
        maxWidth: '900px',
        margin: '0 auto',
      },
      header: {
        textAlign: 'center',
        borderBottom: '2px solid var(--primary-color, #000)',
        paddingBottom: '30px',
        marginBottom: '40px',
      },
      name: {
        fontSize: '2.8rem',
        fontWeight: 'bold',
        color: 'var(--primary-color, #000)',
        letterSpacing: '3px',
        textTransform: 'uppercase',
        marginBottom: '15px',
      },
      contact: {
        color: 'var(--text-color, #333)',
        fontSize: '1rem',
        display: 'flex',
        justifyContent: 'center',
        gap: '20px',
      },
      section: {
        marginBottom: '35px',
      },
      sectionTitle: {
        fontSize: '1.5rem',
        color: 'var(--primary-color, #000)',
        textTransform: 'uppercase',
        letterSpacing: '2px',
        borderBottom: '1px solid var(--primary-color, #000)',
        paddingBottom: '10px',
        marginBottom: '25px',
      },
      experienceItem: {
        marginBottom: '30px',
        display: 'grid',
        gridTemplateColumns: '200px 1fr',
        gap: '20px',
      },
      skillsContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
        gap: '15px',
      },
      skillItem: {
        padding: '10px 15px',
        border: '1px solid var(--primary-color, #000)',
        textAlign: 'center',
        fontSize: '0.95rem',
        transition: 'all 0.3s ease',
        '&:hover': {
          backgroundColor: 'var(--primary-color, #000)',
          color: '#fff',
        },
      },
    },
  },
  minimalist: {
    name: 'Minimalist',
    preview: '/resumeTemplates/templateImages/minimalist.png',
    styles: {
      container: {
        padding: '40px',
        fontFamily: '"Helvetica Neue", Arial, sans-serif',
        backgroundColor: 'var(--background-color, #fff)',
        color: 'var(--text-color, #333)',
        maxWidth: '800px',
        margin: '0 auto',
      },
      header: {
        marginBottom: '40px',
      },
      name: {
        fontSize: '2.4rem',
        fontWeight: '300',
        color: 'var(--primary-color, #000)',
        marginBottom: '15px',
        letterSpacing: '1px',
      },
      contact: {
        color: 'var(--text-color, #666)',
        fontSize: '0.9rem',
        display: 'flex',
        gap: '15px',
      },
      section: {
        marginBottom: '35px',
      },
      sectionTitle: {
        fontSize: '1.2rem',
        color: 'var(--primary-color, #000)',
        marginBottom: '20px',
        fontWeight: '400',
        letterSpacing: '1px',
      },
      experienceItem: {
        marginBottom: '25px',
        borderLeft: '1px solid var(--primary-color, #000)',
        paddingLeft: '20px',
      },
      skillsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '8px',
      },
      skillItem: {
        padding: '5px 12px',
        fontSize: '0.9rem',
        border: '1px solid var(--secondary-color, #ddd)',
        borderRadius: '3px',
      },
    },
  },
  creative: {
    name: 'Creative',
    preview: '/resumeTemplates/templateImages/creative.png',
    styles: {
      container: {
        padding: '40px',
        fontFamily: '"Segoe UI", sans-serif',
        background: 'linear-gradient(135deg, var(--primary-color, #ff4081) 0%, var(--secondary-color, #ffe4ec) 100%)',
        minHeight: '100vh',
        color: 'var(--text-color, #333)',
      },
      header: {
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        padding: '30px',
        borderRadius: '15px',
        marginBottom: '30px',
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
      },
      name: {
        fontSize: '3rem',
        fontWeight: '800',
        color: 'var(--primary-color, #ff4081)',
        marginBottom: '15px',
        textTransform: 'uppercase',
        letterSpacing: '2px',
      },
      contact: {
        color: 'var(--text-color, #333)',
        fontSize: '1rem',
        display: 'flex',
        gap: '20px',
      },
      section: {
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        padding: '25px',
        borderRadius: '15px',
        marginBottom: '25px',
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
      },
      sectionTitle: {
        color: 'var(--primary-color, #ff4081)',
        fontSize: '1.8rem',
        fontWeight: '600',
        marginBottom: '20px',
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
      },
      experienceItem: {
        marginBottom: '25px',
        padding: '20px',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        borderRadius: '10px',
        transition: 'transform 0.3s ease',
        '&:hover': {
          transform: 'translateX(10px)',
        },
      },
      skillsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '12px',
      },
      skillItem: {
        backgroundColor: 'var(--primary-color, #ff4081)',
        color: '#fff',
        padding: '10px 20px',
        borderRadius: '25px',
        fontSize: '1rem',
        fontWeight: '500',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        transition: 'all 0.3s ease',
        '&:hover': {
          transform: 'scale(1.05)',
          boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
        },
      },
    },
  },
  compact: {
    name: 'Compact',
    preview: '/resumeTemplates/templateImages/compact.png',
    styles: {
      container: {
        padding: '30px',
        fontFamily: '"SF Pro Display", Arial, sans-serif',
        backgroundColor: 'var(--background-color, #fff)',
        fontSize: '0.9rem',
        lineHeight: '1.4',
      },
      header: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        gap: '20px',
        alignItems: 'center',
        marginBottom: '25px',
        borderBottom: '2px solid var(--primary-color, #333)',
        paddingBottom: '15px',
      },
      name: {
        fontSize: '2rem',
        fontWeight: '600',
        color: 'var(--primary-color, #333)',
        letterSpacing: '0.5px',
      },
      contact: {
        textAlign: 'right',
        color: 'var(--text-color, #666)',
        fontSize: '0.85rem',
      },
      section: {
        marginBottom: '20px',
      },
      sectionTitle: {
        fontSize: '1.1rem',
        color: 'var(--primary-color, #333)',
        fontWeight: '600',
        marginBottom: '12px',
        textTransform: 'uppercase',
        letterSpacing: '1px',
      },
      experienceItem: {
        marginBottom: '15px',
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        gap: '15px',
        alignItems: 'start',
      },
      skillsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '6px',
      },
      skillItem: {
        backgroundColor: 'var(--secondary-color, #f5f5f5)',
        padding: '4px 10px',
        borderRadius: '3px',
        fontSize: '0.85rem',
      },
    },
  },
};

const colorSchemes = {
  blue: {
    primary: '#2196f3',
    secondary: '#e3f2fd',
    text: '#333333',
    background: '#ffffff',
    sectionBg: '#f8f9fa',
  },
  green: {
    primary: '#4caf50',
    secondary: '#e8f5e9',
    text: '#2e2e2e',
    background: '#ffffff',
    sectionBg: '#f9faf9',
  },
  purple: {
    primary: '#9c27b0',
    secondary: '#f3e5f5',
    text: '#333333',
    background: '#ffffff',
    sectionBg: '#faf8fa',
  },
  red: {
    primary: '#f44336',
    secondary: '#ffebee',
    text: '#2e2e2e',
    background: '#ffffff',
    sectionBg: '#faf8f8',
  },
  teal: {
    primary: '#009688',
    secondary: '#e0f2f1',
    text: '#333333',
    background: '#ffffff',
    sectionBg: '#f8fafa',
  },
  dark: {
    primary: '#bb86fc',
    secondary: '#3700b3',
    text: '#e1e1e1',
    background: '#121212',
    sectionBg: '#1e1e1e',
  },
  navy: {
    primary: '#1a237e',
    secondary: '#c5cae9',
    text: '#333333',
    background: '#ffffff',
    sectionBg: '#f8f9fc',
  },
  autumn: {
    primary: '#ff5722',
    secondary: '#ffccbc',
    text: '#3e2723',
    background: '#fff3e0',
    sectionBg: '#ffffff',
  },
  forest: {
    primary: '#2e7d32',
    secondary: '#c8e6c9',
    text: '#1b5e20',
    background: '#f1f8e9',
    sectionBg: '#ffffff',
  },
  ocean: {
    primary: '#0277bd',
    secondary: '#b3e5fc',
    text: '#01579b',
    background: '#e1f5fe',
    sectionBg: '#ffffff',
  },
};

const sampleData = {
  personal: {
    fullName: 'Alex Morgan',
    email: 'alex.morgan@email.com',
    phone: '(555) 123-4567',
    location: 'San Francisco, CA',
    summary: 'Experienced software engineer with 5+ years of expertise in full-stack development. Passionate about creating scalable web applications and mentoring junior developers. Strong background in React, Node.js, and cloud technologies.',
  },
  education: [
    {
      school: 'University of California, Berkeley',
      degree: 'Bachelor of Science in Computer Science',
      year: '2015-2019',
      description: 'Major in Software Engineering, Minor in Data Science\nGPA: 3.8/4.0\nRelevant Coursework: Algorithms, Database Systems, Machine Learning',
    },
    {
      school: 'Stanford University',
      degree: 'Master of Science in Software Engineering',
      year: '2019-2021',
      description: 'Focus on Distributed Systems and Cloud Computing\nGraduated with Honors\nResearch Assistant in Cloud Computing Lab',
    },
  ],
  experience: [
    {
      company: 'Tech Innovations Inc.',
      position: 'Senior Software Engineer',
      year: '2021-Present',
      description: '• Led development of microservices architecture serving 1M+ users\n• Reduced system latency by 40% through optimization\n• Mentored 5 junior developers and conducted technical interviews',
    },
    {
      company: 'CloudScale Solutions',
      position: 'Software Engineer',
      year: '2019-2021',
      description: '• Developed and maintained cloud-based applications using AWS\n• Implemented CI/CD pipelines reducing deployment time by 60%\n• Collaborated with cross-functional teams on 10+ projects',
    },
    {
      company: 'StartUp Ventures',
      position: 'Software Developer Intern',
      year: '2018-2019',
      description: '• Built responsive web applications using React and Node.js\n• Participated in agile development processes\n• Contributed to open-source projects',
    },
  ],
  skills: [
    'JavaScript/TypeScript',
    'React/Redux',
    'Node.js',
    'Python',
    'AWS/Azure',
    'Docker/Kubernetes',
    'MongoDB',
    'PostgreSQL',
    'Git/GitHub',
    'CI/CD',
    'Agile/Scrum',
    'System Design',
  ],
};

const PreviewControls = ({ 
  selectedTemplate, 
  setSelectedTemplate, 
  selectedColorScheme, 
  setSelectedColorScheme,
  handlePrint,
  handleDownloadPDF 
}) => {
  return (
    <Paper sx={{ p: 2, mb: 3, position: 'sticky', top: 16, zIndex: 1000 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Customize Design
          </Typography>
        </Grid>
        
        {/* Template Selection */}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Template</InputLabel>
            <Select
              value={selectedTemplate}
              onChange={(e) => setSelectedTemplate(e.target.value)}
              label="Template"
            >
              <MenuItem value="modern">
                <Stack direction="row" spacing={1} alignItems="center">
                  <TemplateIcon /> Modern
                </Stack>
              </MenuItem>
              <MenuItem value="professional">
                <Stack direction="row" spacing={1} alignItems="center">
                  <BusinessIcon /> Professional
                </Stack>
              </MenuItem>
              <MenuItem value="creative">
                <Stack direction="row" spacing={1} alignItems="center">
                  <BrushIcon /> Creative
                </Stack>
              </MenuItem>
              <MenuItem value="minimalist">
                <Stack direction="row" spacing={1} alignItems="center">
                  <MinimizeIcon /> Minimalist
                </Stack>
              </MenuItem>
              <MenuItem value="compact">
                <Stack direction="row" spacing={1} alignItems="center">
                  <CompressIcon /> Compact
                </Stack>
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Color Scheme Selection */}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Color Scheme</InputLabel>
            <Select
              value={selectedColorScheme}
              onChange={(e) => setSelectedColorScheme(e.target.value)}
              label="Color Scheme"
            >
              {Object.entries(colorSchemes).map(([key, scheme]) => (
                <MenuItem key={key} value={key}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Box
                      sx={{
                        width: 20,
                        height: 20,
                        borderRadius: '50%',
                        backgroundColor: scheme.primary,
                        border: '1px solid #ddd'
                      }}
                    />
                    {key.charAt(0).toUpperCase() + key.slice(1)}
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Template Preview */}
        <Grid item xs={12}>
          <Paper variant="outlined" sx={{ p: 2, bgcolor: 'background.default' }}>
            <Typography variant="subtitle2" gutterBottom>
              Current Template Preview
            </Typography>
            <Box sx={{ 
              height: 100, 
              bgcolor: colorSchemes[selectedColorScheme].background,
              border: `1px solid ${colorSchemes[selectedColorScheme].primary}`,
              borderRadius: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <Typography 
                variant="body2" 
                sx={{ color: colorSchemes[selectedColorScheme].text }}
              >
                {selectedTemplate.charAt(0).toUpperCase() + selectedTemplate.slice(1)} Template
              </Typography>
            </Box>
          </Paper>
        </Grid>

        {/* Action Buttons */}
        <Grid item xs={12}>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button
              variant="outlined"
              startIcon={<PrintIcon />}
              onClick={handlePrint}
            >
              Print
            </Button>
            <Button
              variant="contained"
              startIcon={<DownloadIcon />}
              onClick={handleDownloadPDF}
            >
              Download PDF
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
};

const ResumeBuilder = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedTemplate, setSelectedTemplate] = useState('modern');
  const [selectedColorScheme, setSelectedColorScheme] = useState('blue');
  const [personalInfo, setPersonalInfo] = useState(sampleData.personal);
  const [education, setEducation] = useState(sampleData.education);
  const [experience, setExperience] = useState(sampleData.experience);
  const [skills, setSkills] = useState(sampleData.skills);
  const resumeRef = useRef(null);

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handlePrint = () => {
    window.print();
  };

  const generatePDF = async () => {
    if (resumeRef.current) {
      const canvas = await html2canvas(resumeRef.current);
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save('resume.pdf');
    }
  };

  const handlePersonalInfoChange = (field) => (event) => {
    setPersonalInfo({
      ...personalInfo,
      [field]: event.target.value,
    });
  };

  const handleEducationChange = (index, field) => (event) => {
    const newEducation = [...education];
    newEducation[index][field] = event.target.value;
    setEducation(newEducation);
  };

  const addEducation = () => {
    setEducation([
      ...education,
      { school: '', degree: '', field: '', year: '', gpa: '' },
    ]);
  };

  const removeEducation = (index) => {
    setEducation(education.filter((_, i) => i !== index));
  };

  const handleExperienceChange = (index, field) => (event) => {
    const newExperience = [...experience];
    newExperience[index][field] = event.target.value;
    setExperience(newExperience);
  };

  const addExperience = () => {
    setExperience([
      ...experience,
      { company: '', position: '', startDate: '', endDate: '', description: '' },
    ]);
  };

  const removeExperience = (index) => {
    setExperience(experience.filter((_, i) => i !== index));
  };

  const handleSkillChange = (index) => (event) => {
    const newSkills = [...skills];
    newSkills[index] = event.target.value;
    setSkills(newSkills);
  };

  const addSkill = () => {
    setSkills([...skills, '']);
  };

  const removeSkill = (index) => {
    setSkills(skills.filter((_, i) => i !== index));
  };

  const renderPersonalInfo = () => (
    <ResumeSection>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Full Name"
            value={personalInfo.fullName}
            onChange={handlePersonalInfoChange('fullName')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Email"
            value={personalInfo.email}
            onChange={handlePersonalInfoChange('email')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Phone"
            value={personalInfo.phone}
            onChange={handlePersonalInfoChange('phone')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Location"
            value={personalInfo.location}
            onChange={handlePersonalInfoChange('location')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            rows={4}
            label="Professional Summary"
            value={personalInfo.summary}
            onChange={handlePersonalInfoChange('summary')}
          />
        </Grid>
      </Grid>
    </ResumeSection>
  );

  const renderEducation = () => (
    <ResumeSection>
      {education.map((edu, index) => (
        <Box key={index} sx={{ mb: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="School/University"
                value={edu.school}
                onChange={handleEducationChange(index, 'school')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Degree"
                value={edu.degree}
                onChange={handleEducationChange(index, 'degree')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Field of Study"
                value={edu.field}
                onChange={handleEducationChange(index, 'field')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Year"
                value={edu.year}
                onChange={handleEducationChange(index, 'year')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="GPA"
                value={edu.gpa}
                onChange={handleEducationChange(index, 'gpa')}
              />
            </Grid>
          </Grid>
          {education.length > 1 && (
            <IconButton
              onClick={() => removeEducation(index)}
              sx={{ mt: 1 }}
              color="error"
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      ))}
      <Button startIcon={<AddIcon />} onClick={addEducation}>
        Add Education
      </Button>
    </ResumeSection>
  );

  const renderExperience = () => (
    <ResumeSection>
      {experience.map((exp, index) => (
        <Box key={index} sx={{ mb: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Company"
                value={exp.company}
                onChange={handleExperienceChange(index, 'company')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Position"
                value={exp.position}
                onChange={handleExperienceChange(index, 'position')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Start Date"
                value={exp.startDate}
                onChange={handleExperienceChange(index, 'startDate')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="End Date"
                value={exp.endDate}
                onChange={handleExperienceChange(index, 'endDate')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Description"
                value={exp.description}
                onChange={handleExperienceChange(index, 'description')}
              />
            </Grid>
          </Grid>
          {experience.length > 1 && (
            <IconButton
              onClick={() => removeExperience(index)}
              sx={{ mt: 1 }}
              color="error"
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      ))}
      <Button startIcon={<AddIcon />} onClick={addExperience}>
        Add Experience
      </Button>
    </ResumeSection>
  );

  const renderSkills = () => (
    <ResumeSection>
      <List>
        {skills.map((skill, index) => (
          <ListItem key={index}>
            <ListItemText>
              <TextField
                fullWidth
                label={`Skill ${index + 1}`}
                value={skill}
                onChange={handleSkillChange(index)}
              />
            </ListItemText>
            <ListItemSecondaryAction>
              {skills.length > 1 && (
                <IconButton
                  edge="end"
                  onClick={() => removeSkill(index)}
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <Button startIcon={<AddIcon />} onClick={addSkill}>
        Add Skill
      </Button>
    </ResumeSection>
  );

  const renderPreview = () => {
    const template = templates[selectedTemplate];
    const styles = template.styles;

    return (
      <Box id="resume-preview" sx={styles.container}>
        <Box sx={styles.header}>
          <Typography sx={styles.name}>
            {personalInfo.fullName}
          </Typography>
          <Typography sx={styles.contact}>
            {personalInfo.email} | {personalInfo.phone} | {personalInfo.location}
          </Typography>
        </Box>

        <Box sx={styles.section}>
          <Typography sx={styles.sectionTitle}>
            Professional Summary
          </Typography>
          <Typography variant="body1" paragraph>
            {personalInfo.summary}
          </Typography>
        </Box>

        <Box sx={styles.section}>
          <Typography sx={styles.sectionTitle}>
            Education
          </Typography>
          {education.map((edu, index) => (
            <Box key={index} sx={styles.experienceItem}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                {edu.school}
              </Typography>
              <Typography variant="body1">
                {edu.degree} in {edu.field}
              </Typography>
              <Typography variant="body2" sx={{ color: '#666' }}>
                Year: {edu.year} {edu.gpa && `| GPA: ${edu.gpa}`}
              </Typography>
            </Box>
          ))}
        </Box>

        <Box sx={styles.section}>
          <Typography sx={styles.sectionTitle}>
            Experience
          </Typography>
          {experience.map((exp, index) => (
            <Box key={index} sx={styles.experienceItem}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                {exp.position} at {exp.company}
              </Typography>
              <Typography variant="body2" sx={{ color: '#666', mb: 1 }}>
                {exp.startDate} - {exp.endDate}
              </Typography>
              <Typography variant="body2">
                {exp.description}
              </Typography>
            </Box>
          ))}
        </Box>

        <Box sx={styles.section}>
          <Typography sx={styles.sectionTitle}>
            Skills
          </Typography>
          <Box sx={styles.skillsContainer}>
            {skills.filter(Boolean).map((skill, index) => (
              <Box key={index} sx={styles.skillItem}>
                {skill}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    );
  };

  const renderStep = (step) => {
    switch (step) {
      case 0:
        return renderPersonalInfo();
      case 1:
        return renderEducation();
      case 2:
        return renderExperience();
      case 3:
        return renderSkills();
      case 4:
        return (
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <PreviewControls
                selectedTemplate={selectedTemplate}
                setSelectedTemplate={setSelectedTemplate}
                selectedColorScheme={selectedColorScheme}
                setSelectedColorScheme={setSelectedColorScheme}
                handlePrint={handlePrint}
                handleDownloadPDF={generatePDF}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <Box ref={resumeRef}>
                <Paper 
                  elevation={3} 
                  sx={{ 
                    p: 3,
                    bgcolor: colorSchemes[selectedColorScheme].background 
                  }}
                >
                  {renderPreview()}
                </Paper>
              </Box>
            </Grid>
          </Grid>
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <Box sx={{ maxWidth: 1200, margin: 'auto', p: 2 }}>
      <Typography variant="h4" gutterBottom>
        Resume Builder
      </Typography>
      
      <StyledPaper>
        <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {renderStep(activeStep)}

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
          >
            Back
          </Button>
          <Box>
            {activeStep === steps.length - 1 ? (
              <></>
            ) : (
              <Button
                variant="contained"
                onClick={handleNext}
              >
                Next
              </Button>
            )}
          </Box>
        </Box>
      </StyledPaper>
    </Box>
  );
};

export default ResumeBuilder;
