import React, { useState, useRef } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  Button,
  Slider,
  Alert,
  Grid,
  CircularProgress,
  IconButton,
} from '@mui/material';
import {
  Upload as UploadIcon,
  Download as DownloadIcon,
  Delete as DeleteIcon,
  Refresh as RefreshIcon,
  Brightness6 as BrightnessIcon,
  Contrast as ContrastIcon,
  ColorLens as ColorLensIcon,
  Tune as TuneIcon,
} from '@mui/icons-material';
import ToolDescription from '../common/ToolDescription';

function ImageColorAdjuster() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [error, setError] = useState('');
  const [processing, setProcessing] = useState(false);
  const [adjustments, setAdjustments] = useState({
    brightness: 100,
    contrast: 100,
    saturation: 100,
    hue: 0,
    sepia: 0,
    blur: 0,
    sharpen: 0,
  });

  const imageRef = useRef(null);
  const canvasRef = useRef(null);

  const handleFileSelect = (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (!file.type.startsWith('image/')) {
      setError('Please select a valid image file');
      return;
    }

    setSelectedFile(file);
    setError('');
    const reader = new FileReader();
    reader.onload = () => setPreviewUrl(reader.result);
    reader.readAsDataURL(file);
  };

  const clearImage = () => {
    setSelectedFile(null);
    setPreviewUrl('');
    setError('');
    resetAdjustments();
  };

  const resetAdjustments = () => {
    setAdjustments({
      brightness: 100,
      contrast: 100,
      saturation: 100,
      hue: 0,
      sepia: 0,
      blur: 0,
      sharpen: 0,
    });
  };

  const handleAdjustmentChange = (type, value) => {
    setAdjustments(prev => ({
      ...prev,
      [type]: value
    }));
  };

  const getFilterStyle = () => {
    const {
      brightness,
      contrast,
      saturation,
      hue,
      sepia,
      blur,
      sharpen,
    } = adjustments;

    return {
      filter: `
        brightness(${brightness}%)
        contrast(${contrast}%)
        saturate(${saturation}%)
        hue-rotate(${hue}deg)
        sepia(${sepia}%)
        blur(${blur / 10}px)
      `,
      transform: `scale(${1 + sharpen / 1000})`,
    };
  };

  const generateAdjustedImage = async () => {
    if (!selectedFile || !imageRef.current || !canvasRef.current) return;

    setProcessing(true);
    const image = imageRef.current;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // Set canvas dimensions
    canvas.width = image.naturalWidth;
    canvas.height = image.naturalHeight;

    // Apply filters using CSS filters
    ctx.filter = getFilterStyle().filter;
    ctx.drawImage(image, 0, 0);

    // Convert to blob and download
    canvas.toBlob(
      (blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `adjusted_${selectedFile.name}`;
        link.click();
        URL.revokeObjectURL(url);
        setProcessing(false);
      },
      'image/jpeg',
      0.95
    );
  };

  const adjustmentControls = [
    {
      label: 'Brightness',
      key: 'brightness',
      icon: <BrightnessIcon />,
      min: 0,
      max: 200,
      default: 100,
    },
    {
      label: 'Contrast',
      key: 'contrast',
      icon: <ContrastIcon />,
      min: 0,
      max: 200,
      default: 100,
    },
    {
      label: 'Saturation',
      key: 'saturation',
      icon: <ColorLensIcon />,
      min: 0,
      max: 200,
      default: 100,
    },
    {
      label: 'Hue',
      key: 'hue',
      icon: <TuneIcon />,
      min: -180,
      max: 180,
      default: 0,
    },
    {
      label: 'Sepia',
      key: 'sepia',
      icon: <TuneIcon />,
      min: 0,
      max: 100,
      default: 0,
    },
    {
      label: 'Blur',
      key: 'blur',
      icon: <TuneIcon />,
      min: 0,
      max: 20,
      default: 0,
    },
    {
      label: 'Sharpen',
      key: 'sharpen',
      icon: <TuneIcon />,
      min: 0,
      max: 100,
      default: 0,
    },
  ];

  return (
    <Container maxWidth="lg">
      <Paper elevation={3} sx={{ p: 4, my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Image Color Adjuster
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          Adjust colors and effects of your images with precision
        </Typography>

        <Box sx={{ mb: 4 }}>
          <Button
            variant="contained"
            component="label"
            startIcon={<UploadIcon />}
            sx={{ mr: 2 }}
          >
            Upload Image
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={handleFileSelect}
            />
          </Button>

          {selectedFile && (
            <>
              <Button
                variant="outlined"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={clearImage}
                sx={{ mr: 2 }}
              >
                Clear
              </Button>
              <Button
                variant="outlined"
                startIcon={<RefreshIcon />}
                onClick={resetAdjustments}
              >
                Reset Adjustments
              </Button>
            </>
          )}
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {selectedFile && (
          <>
            <Box sx={{ mb: 4, textAlign: 'center' }}>
              <img
                ref={imageRef}
                src={previewUrl}
                alt="Preview"
                style={{
                  maxWidth: '100%',
                  maxHeight: '400px',
                  objectFit: 'contain',
                  ...getFilterStyle(),
                }}
              />
            </Box>

            <Grid container spacing={3}>
              {adjustmentControls.map((control) => (
                <Grid item xs={12} key={control.key}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      {control.icon}
                    </Grid>
                    <Grid item xs>
                      <Typography gutterBottom>
                        {control.label}: {adjustments[control.key]}
                        {control.key === 'hue' ? '°' : '%'}
                      </Typography>
                      <Slider
                        value={adjustments[control.key]}
                        onChange={(e, value) =>
                          handleAdjustmentChange(control.key, value)
                        }
                        min={control.min}
                        max={control.max}
                        valueLabelDisplay="auto"
                        valueLabelFormat={(value) =>
                          `${value}${control.key === 'hue' ? '°' : '%'}`
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>

            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="contained"
                startIcon={<DownloadIcon />}
                onClick={generateAdjustedImage}
                disabled={processing}
              >
                {processing ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  'Save Adjusted Image'
                )}
              </Button>
            </Box>
          </>
        )}

        <canvas ref={canvasRef} style={{ display: 'none' }} />
      </Paper>

      <ToolDescription
        title="Image Color Adjuster"
        description="A powerful tool for adjusting image colors and effects. Perfect for enhancing photos and creating unique visual effects."
        benefits={[
          "Real-time preview of adjustments",
          "Multiple color adjustment options",
          "Fine-tuned control over each parameter",
          "High-quality output",
          "Non-destructive editing",
          "Easy reset functionality"
        ]}
        useCases={[
          "Enhance photo colors",
          "Correct image exposure",
          "Create artistic effects",
          "Adjust image mood and tone",
          "Fix color balance issues",
          "Create vintage or special effects"
        ]}
        howTo={[
          "Upload an image using the upload button",
          "Use the sliders to adjust various parameters",
          "Preview changes in real-time",
          "Reset adjustments if needed",
          "Click 'Save Adjusted Image' when satisfied",
          "Download the processed image"
        ]}
      />
    </Container>
  );
}

export default ImageColorAdjuster;
