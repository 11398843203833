import React, { useState } from 'react';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
  Box,
  Alert,
  Snackbar,
  IconButton,
  Tooltip,
  FormControlLabel,
  Switch,
} from '@mui/material';
import {
  ContentCopy as CopyIcon,
  Clear as ClearIcon,
  SwapVert as SwapIcon,
} from '@mui/icons-material';
import ToolDescription from '../common/ToolDescription';

function URLTool() {
  const [input, setInput] = useState('');
  const [result, setResult] = useState('');
  const [mode, setMode] = useState('encode');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [encodeSpaces, setEncodeSpaces] = useState(true);

  const handleModeToggle = () => {
    setMode(mode === 'encode' ? 'decode' : 'encode');
    // Swap input and output
    setInput(result);
    setResult('');
  };

  const handleCopy = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setSnackbar({
        open: true,
        message: 'Copied to clipboard!',
        severity: 'success',
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to copy text',
        severity: 'error',
      });
    }
  };

  const handleClear = () => {
    setInput('');
    setResult('');
  };

  const handleProcess = () => {
    try {
      if (mode === 'encode') {
        if (encodeSpaces) {
          setResult(encodeURIComponent(input));
        } else {
          setResult(encodeURIComponent(input).replace(/%20/g, '+'));
        }
      } else {
        // First replace + with space if it exists
        const preprocessed = input.replace(/\+/g, '%20');
        setResult(decodeURIComponent(preprocessed));
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Failed to ${mode} URL. Please check your input.`,
        severity: 'error',
      });
    }
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
    // Clear result when input changes
    setResult('');
  };

  return (
    <Container maxWidth="lg">
      <Paper elevation={3} sx={{ p: 4, my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          URL Encoder/Decoder
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          Encode or decode URLs and query parameters. Handles special characters and Unicode.
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
              <Button
                variant="contained"
                onClick={handleModeToggle}
                startIcon={<SwapIcon />}
              >
                {mode === 'encode' ? 'Switch to Decode' : 'Switch to Encode'}
              </Button>
              <Button
                variant="outlined"
                onClick={handleClear}
                startIcon={<ClearIcon />}
              >
                Clear All
              </Button>
            </Box>
          </Grid>

          {mode === 'encode' && (
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={encodeSpaces}
                    onChange={(e) => setEncodeSpaces(e.target.checked)}
                  />
                }
                label="Encode spaces as %20 (instead of +)"
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={4}
              label={mode === 'encode' ? 'URL to Encode' : 'URL to Decode'}
              value={input}
              onChange={handleInputChange}
              placeholder={mode === 'encode' 
                ? 'Enter URL or query parameters to encode...' 
                : 'Enter encoded URL to decode...'}
            />
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <Button
                variant="contained"
                onClick={handleProcess}
                disabled={!input}
              >
                {mode === 'encode' ? 'Encode URL' : 'Decode URL'}
              </Button>
            </Box>
          </Grid>

          {result && (
            <Grid item xs={12}>
              <Paper variant="outlined" sx={{ p: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                    Result
                  </Typography>
                  <Tooltip title="Copy to clipboard">
                    <IconButton onClick={() => handleCopy(result)} size="small">
                      <CopyIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  value={result}
                  InputProps={{ readOnly: true }}
                />
              </Paper>
            </Grid>
          )}
        </Grid>
      </Paper>

      <ToolDescription
        title="URL Encoder/Decoder"
        description="The URL Encoder/Decoder tool helps you properly encode and decode URLs and query parameters. It handles special characters, spaces, and Unicode characters according to URL encoding standards, making it essential for web development and API work."
        benefits={[
          "Properly encode URLs for web use",
          "Handle special characters and Unicode",
          "Support for query parameter encoding",
          "Choose between space encoding methods (%20 or +)",
          "Real-time encoding/decoding",
          "Copy results instantly to clipboard"
        ]}
        useCases={[
          "Preparing URLs for API requests",
          "Encoding query parameters for web forms",
          "Debugging URL-related issues",
          "Working with internationalized URLs",
          "Processing user input for URLs",
          "Creating valid URLs for email links"
        ]}
        howTo={[
          "Enter your URL or query parameter in the input field",
          "Choose encode or decode mode using the switch button",
          "For encoding, select your preferred space encoding method",
          "The result will update automatically",
          "Use the copy button to copy the result",
          "Click clear to start over with new input"
        ]}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default URLTool;
