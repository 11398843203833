import React, { useState } from 'react';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Slider,
  IconButton,
  Snackbar,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RefreshIcon from '@mui/icons-material/Refresh';
import { loremIpsum } from 'lorem-ipsum';
import ToolDescription from '../common/ToolDescription';

const unitOptions = [
  { value: 'words', label: 'Words' },
  { value: 'sentences', label: 'Sentences' },
  { value: 'paragraphs', label: 'Paragraphs' },
];

function LoremIpsumGenerator() {
  const [unit, setUnit] = useState('paragraphs');
  const [count, setCount] = useState(3);
  const [minWordsPerSentence, setMinWordsPerSentence] = useState(5);
  const [maxWordsPerSentence, setMaxWordsPerSentence] = useState(15);
  const [minSentencesPerParagraph, setMinSentencesPerParagraph] = useState(3);
  const [maxSentencesPerParagraph, setMaxSentencesPerParagraph] = useState(7);
  const [generatedText, setGeneratedText] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });

  const generateText = () => {
    const text = loremIpsum({
      count,
      units: unit,
      format: 'plain',
      sentenceLowerBound: minWordsPerSentence,
      sentenceUpperBound: maxWordsPerSentence,
      paragraphLowerBound: minSentencesPerParagraph,
      paragraphUpperBound: maxSentencesPerParagraph,
      random: Math.random,
    });
    setGeneratedText(text);
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(generatedText);
      setSnackbar({
        open: true,
        message: 'Text copied to clipboard!',
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to copy text.',
      });
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Container maxWidth="lg">
      <Paper elevation={3} sx={{ p: 4, my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Lorem Ipsum Generator
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          Generate customizable Lorem Ipsum placeholder text for your designs.
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth sx={{ mb: 3 }}>
              <InputLabel>Unit</InputLabel>
              <Select
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
                label="Unit"
              >
                {unitOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Typography gutterBottom>Count: {count}</Typography>
            <Slider
              value={count}
              onChange={(_, value) => setCount(value)}
              min={1}
              max={unit === 'words' ? 100 : unit === 'sentences' ? 20 : 10}
              marks={[
                { value: 1, label: '1' },
                {
                  value: unit === 'words' ? 100 : unit === 'sentences' ? 20 : 10,
                  label: unit === 'words' ? '100' : unit === 'sentences' ? '20' : '10',
                },
              ]}
              sx={{ mb: 4 }}
            />

            <Typography gutterBottom>Words per Sentence</Typography>
            <Box sx={{ px: 1, mb: 4 }}>
              <Typography variant="caption" color="text.secondary">
                Min: {minWordsPerSentence} - Max: {maxWordsPerSentence}
              </Typography>
              <Slider
                value={[minWordsPerSentence, maxWordsPerSentence]}
                onChange={(_, value) => {
                  setMinWordsPerSentence(value[0]);
                  setMaxWordsPerSentence(value[1]);
                }}
                min={3}
                max={20}
                marks={[
                  { value: 3, label: '3' },
                  { value: 20, label: '20' },
                ]}
              />
            </Box>

            <Typography gutterBottom>Sentences per Paragraph</Typography>
            <Box sx={{ px: 1, mb: 4 }}>
              <Typography variant="caption" color="text.secondary">
                Min: {minSentencesPerParagraph} - Max: {maxSentencesPerParagraph}
              </Typography>
              <Slider
                value={[minSentencesPerParagraph, maxSentencesPerParagraph]}
                onChange={(_, value) => {
                  setMinSentencesPerParagraph(value[0]);
                  setMaxSentencesPerParagraph(value[1]);
                }}
                min={1}
                max={10}
                marks={[
                  { value: 1, label: '1' },
                  { value: 10, label: '10' },
                ]}
              />
            </Box>

            <Button
              variant="contained"
              onClick={generateText}
              startIcon={<RefreshIcon />}
              fullWidth
            >
              Generate Text
            </Button>
          </Grid>

          <Grid item xs={12} md={8}>
            <Box sx={{ position: 'relative' }}>
              <TextField
                fullWidth
                multiline
                rows={20}
                value={generatedText}
                variant="outlined"
                InputProps={{ readOnly: true }}
                sx={{
                  mb: 2,
                  '& .MuiInputBase-input': {
                    fontFamily: 'Georgia, serif',
                    lineHeight: 1.6,
                  },
                }}
              />
              <IconButton
                onClick={handleCopy}
                disabled={!generatedText}
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  bgcolor: 'background.paper',
                  '&:hover': {
                    bgcolor: 'action.hover',
                  },
                }}
              >
                <ContentCopyIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <ToolDescription
        title="Lorem Ipsum Generator"
        description="A versatile Lorem Ipsum text generator with customizable options. Generate placeholder text for your designs, mockups, and layouts with precise control over text structure."
        benefits={[
          "Customizable text length and structure",
          "Multiple output formats (words, sentences, paragraphs)",
          "Control over sentence and paragraph length",
          "Easy copy to clipboard functionality",
          "Real-time text generation",
          "Professional-looking placeholder text"
        ]}
        useCases={[
          "Website mockups and prototypes",
          "Document templates",
          "Design presentations",
          "Content layout testing",
          "Typography demonstrations",
          "UI/UX design workflows"
        ]}
        howTo={[
          "Select the desired output unit (words, sentences, paragraphs)",
          "Adjust the count using the slider",
          "Customize words per sentence and sentences per paragraph",
          "Click 'Generate Text' to create Lorem Ipsum",
          "Copy the generated text using the copy button",
          "Regenerate as needed for different variations"
        ]}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbar.message}
      />
    </Container>
  );
}

export default LoremIpsumGenerator;
