import React, { useState, useRef } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  Button,
  Slider,
  Alert,
  Grid,
  CircularProgress,
  IconButton,
} from '@mui/material';
import {
  Upload as UploadIcon,
  Download as DownloadIcon,
  Delete as DeleteIcon,
  RotateLeft as RotateLeftIcon,
  RotateRight as RotateRightIcon,
  AspectRatio as AspectRatioIcon,
  Crop as CropIcon,
} from '@mui/icons-material';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import ToolDescription from '../common/ToolDescription';

function ImageCropper() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [error, setError] = useState('');
  const [crop, setCrop] = useState({
    unit: '%',
    width: 50,
    height: 50,
    x: 25,
    y: 25,
  });
  const [rotation, setRotation] = useState(0);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [aspect, setAspect] = useState(null);

  const imageRef = useRef(null);
  const canvasRef = useRef(null);

  const aspectRatios = [
    { label: 'Free', value: null },
    { label: '1:1', value: 1 },
    { label: '16:9', value: 16 / 9 },
    { label: '4:3', value: 4 / 3 },
    { label: '3:2', value: 3 / 2 },
  ];

  const handleFileSelect = (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (!file.type.startsWith('image/')) {
      setError('Please select a valid image file');
      return;
    }

    setSelectedFile(file);
    setError('');
    const reader = new FileReader();
    reader.onload = () => setPreviewUrl(reader.result);
    reader.readAsDataURL(file);
  };

  const clearImage = () => {
    setSelectedFile(null);
    setPreviewUrl('');
    setCrop({
      unit: '%',
      width: 50,
      height: 50,
      x: 25,
      y: 25,
    });
    setRotation(0);
    setError('');
  };

  const rotateImage = (direction) => {
    setRotation((prev) => (prev + (direction === 'left' ? -90 : 90)) % 360);
  };

  const generateCroppedImage = async () => {
    if (!completedCrop || !imageRef.current || !canvasRef.current) return;

    setProcessing(true);
    const image = imageRef.current;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // Set canvas dimensions
    canvas.width = completedCrop.width;
    canvas.height = completedCrop.height;

    // Create a temporary canvas for rotation if needed
    let tempCanvas;
    if (rotation !== 0) {
      tempCanvas = document.createElement('canvas');
      const tempCtx = tempCanvas.getContext('2d');
      const radians = (rotation * Math.PI) / 180;
      const sin = Math.abs(Math.sin(radians));
      const cos = Math.abs(Math.cos(radians));
      
      tempCanvas.width = image.width * cos + image.height * sin;
      tempCanvas.height = image.width * sin + image.height * cos;
      
      tempCtx.translate(tempCanvas.width / 2, tempCanvas.height / 2);
      tempCtx.rotate(radians);
      tempCtx.drawImage(image, -image.width / 2, -image.height / 2);
    }

    // Draw the cropped image
    ctx.drawImage(
      rotation !== 0 ? tempCanvas : image,
      completedCrop.x,
      completedCrop.y,
      completedCrop.width,
      completedCrop.height,
      0,
      0,
      completedCrop.width,
      completedCrop.height
    );

    // Convert to blob and download
    canvas.toBlob(
      (blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `cropped_${selectedFile.name}`;
        link.click();
        URL.revokeObjectURL(url);
        setProcessing(false);
      },
      'image/jpeg',
      1
    );
  };

  return (
    <Container maxWidth="lg">
      <Paper elevation={3} sx={{ p: 4, my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Image Cropper
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          Crop and rotate your images with precision
        </Typography>

        <Box sx={{ mb: 4 }}>
          <Button
            variant="contained"
            component="label"
            startIcon={<UploadIcon />}
            sx={{ mr: 2 }}
          >
            Upload Image
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={handleFileSelect}
            />
          </Button>

          {selectedFile && (
            <Button
              variant="outlined"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={clearImage}
            >
              Clear
            </Button>
          )}
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {selectedFile && (
          <>
            <Box sx={{ mb: 4 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Typography>Rotation: {rotation}°</Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={() => rotateImage('left')}>
                    <RotateLeftIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton onClick={() => rotateImage('right')}>
                    <RotateRightIcon />
                  </IconButton>
                </Grid>
                <Grid item xs>
                  <Slider
                    value={rotation}
                    onChange={(e, value) => setRotation(value)}
                    min={-180}
                    max={180}
                    step={1}
                    valueLabelDisplay="auto"
                    valueLabelFormat={(value) => `${value}°`}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ mb: 4 }}>
              <Typography gutterBottom>Aspect Ratio</Typography>
              <Box sx={{ display: 'flex', gap: 1 }}>
                {aspectRatios.map((ratio) => (
                  <Button
                    key={ratio.label}
                    variant={aspect === ratio.value ? 'contained' : 'outlined'}
                    onClick={() => setAspect(ratio.value)}
                    startIcon={<AspectRatioIcon />}
                  >
                    {ratio.label}
                  </Button>
                ))}
              </Box>
            </Box>

            <Box sx={{ mb: 4, textAlign: 'center' }}>
              <ReactCrop
                crop={crop}
                onChange={(c) => setCrop(c)}
                onComplete={(c) => setCompletedCrop(c)}
                aspect={aspect}
              >
                <img
                  ref={imageRef}
                  src={previewUrl}
                  alt="Preview"
                  style={{
                    maxWidth: '100%',
                    maxHeight: '600px',
                    transform: `rotate(${rotation}deg)`,
                  }}
                />
              </ReactCrop>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="contained"
                startIcon={<CropIcon />}
                onClick={generateCroppedImage}
                disabled={processing || !completedCrop?.width || !completedCrop?.height}
              >
                {processing ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  'Crop and Download'
                )}
              </Button>
            </Box>
          </>
        )}

        <canvas ref={canvasRef} style={{ display: 'none' }} />
      </Paper>

      <ToolDescription
        title="Image Cropper"
        description="A versatile tool for cropping and rotating images. Perfect for creating perfectly framed photos, profile pictures, or custom image dimensions."
        benefits={[
          "Interactive crop selection",
          "Multiple aspect ratio presets",
          "Precise rotation control",
          "Live preview",
          "High-quality output",
          "Free-form or constrained cropping"
        ]}
        useCases={[
          "Create profile pictures",
          "Adjust photo composition",
          "Remove unwanted image areas",
          "Create thumbnails with specific ratios",
          "Fix image orientation",
          "Prepare images for social media"
        ]}
        howTo={[
          "Upload an image using the upload button",
          "Select an aspect ratio (optional)",
          "Adjust the crop area by dragging the corners",
          "Use the rotation slider if needed",
          "Click 'Crop and Download' to save",
          "Use 'Clear' to start over"
        ]}
      />
    </Container>
  );
}

export default ImageCropper;
