import React, { useState, useRef } from 'react';
import { Box, Button, CircularProgress, Typography, Paper, Alert, Slider, FormControlLabel, Switch } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import * as pdfjsLib from 'pdfjs-dist';
import { PDFDocument } from 'pdf-lib';

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100MB limit

const PDFCompressor = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);
  const [imageQuality, setImageQuality] = useState(70);
  const [grayscale, setGrayscale] = useState(false);
  const [originalSize, setOriginalSize] = useState(null);
  const [compressedSize, setCompressedSize] = useState(null);
  const canvasRef = useRef(null);

  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (file.type !== 'application/pdf') {
      setError('Please upload a PDF file');
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      setError('File size exceeds 100MB limit');
      return;
    }

    try {
      setLoading(true);
      setError(null);
      setProgress(0);
      setOriginalSize(file.size);
      setCompressedSize(null);

      // Read the PDF file
      const arrayBuffer = await file.arrayBuffer();
      const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
      const totalPages = pdf.numPages;

      // Create a new PDF document
      const newPdfDoc = await PDFDocument.create();

      // Create canvas if it doesn't exist
      if (!canvasRef.current) {
        canvasRef.current = document.createElement('canvas');
      }
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');

      // Process each page
      for (let pageNum = 1; pageNum <= totalPages; pageNum++) {
        setProgress((pageNum / totalPages) * 100);

        // Get the page
        const page = await pdf.getPage(pageNum);
        const viewport = page.getViewport({ scale: 1.0 });

        // Set canvas dimensions
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        // Render PDF page to canvas
        await page.render({
          canvasContext: context,
          viewport: viewport
        }).promise;

        // Convert to image with compression
        let imageData = canvas.toDataURL('image/jpeg', imageQuality / 100);

        // Apply grayscale if selected
        if (grayscale) {
          const img = new Image();
          await new Promise((resolve) => {
            img.onload = resolve;
            img.src = imageData;
          });
          context.drawImage(img, 0, 0);
          const imageDataObj = context.getImageData(0, 0, canvas.width, canvas.height);
          const data = imageDataObj.data;
          for (let i = 0; i < data.length; i += 4) {
            const avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
            data[i] = avg;
            data[i + 1] = avg;
            data[i + 2] = avg;
          }
          context.putImageData(imageDataObj, 0, 0);
          imageData = canvas.toDataURL('image/jpeg', imageQuality / 100);
        }

        // Convert base64 to array buffer
        const base64Data = imageData.replace(/^data:image\/jpeg;base64,/, '');
        const imageBytes = Uint8Array.from(atob(base64Data), c => c.charCodeAt(0));

        // Embed the compressed image
        const embeddedImage = await newPdfDoc.embedJpg(imageBytes);
        const imagePage = newPdfDoc.addPage([viewport.width, viewport.height]);
        
        imagePage.drawImage(embeddedImage, {
          x: 0,
          y: 0,
          width: viewport.width,
          height: viewport.height,
        });
      }

      // Save the compressed PDF
      const compressedPdfBytes = await newPdfDoc.save();
      const compressedSize = compressedPdfBytes.length;
      setCompressedSize(compressedSize);

      // Download the compressed PDF
      const blob = new Blob([compressedPdfBytes], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `compressed_${file.name}`;
      link.click();
      URL.revokeObjectURL(url);

    } catch (err) {
      console.error('Compression error:', err);
      setError('Error compressing PDF: ' + (err.message || 'Unknown error'));
    } finally {
      setLoading(false);
      setProgress(0);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" gutterBottom>
        PDF Compressor
      </Typography>
      
      <Paper 
        elevation={3} 
        sx={{ 
          p: 3, 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          gap: 2 
        }}
      >
        <Typography variant="body1" textAlign="center">
          Compress your PDF files while maintaining reasonable quality.
        </Typography>
        <Typography variant="body2" color="text.secondary" textAlign="center">
          Maximum file size: 100MB
        </Typography>

        <Box sx={{ width: '100%', maxWidth: 400 }}>
          <Typography gutterBottom>Image Quality: {imageQuality}%</Typography>
          <Slider
            value={imageQuality}
            onChange={(_, newValue) => setImageQuality(newValue)}
            disabled={loading}
            aria-label="Image Quality"
            valueLabelDisplay="auto"
            min={10}
            max={100}
          />
        </Box>

        <FormControlLabel
          control={
            <Switch
              checked={grayscale}
              onChange={(e) => setGrayscale(e.target.checked)}
              disabled={loading}
            />
          }
          label="Convert to Grayscale"
        />

        <Button
          component="label"
          variant="contained"
          startIcon={<CloudUploadIcon />}
          disabled={loading}
        >
          Upload PDF
          <VisuallyHiddenInput type="file" accept=".pdf" onChange={handleFileUpload} />
        </Button>

        {loading && (
          <Box sx={{ width: '100%', maxWidth: 400 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
              <CircularProgress size={20} />
              <Typography>Compressing... {Math.round(progress)}%</Typography>
            </Box>
          </Box>
        )}

        {(originalSize || compressedSize) && (
          <Box sx={{ width: '100%', maxWidth: 400 }}>
            {originalSize && (
              <Typography>Original Size: {formatFileSize(originalSize)}</Typography>
            )}
            {compressedSize && (
              <>
                <Typography>Compressed Size: {formatFileSize(compressedSize)}</Typography>
                <Typography>
                  Reduction: {Math.round((1 - compressedSize / originalSize) * 100)}%
                </Typography>
              </>
            )}
          </Box>
        )}

        {error && (
          <Alert severity="error" sx={{ width: '100%', maxWidth: 400 }}>
            {error}
          </Alert>
        )}
      </Paper>
    </Box>
  );
};

export default PDFCompressor;
