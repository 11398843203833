import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  Button,
  TextField,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import {
  ContentCopy as CopyIcon,
  FormatIndentIncrease as FormatIcon,
  RestartAlt as ResetIcon,
} from '@mui/icons-material';
import ToolDescription from '../common/ToolDescription';

function SQLTool() {
  const [sqlInput, setSqlInput] = useState('');
  const [formattedSql, setFormattedSql] = useState('');
  const [error, setError] = useState('');
  const [copied, setCopied] = useState(false);
  const [dialect, setDialect] = useState('standard');

  useEffect(() => {
    // Load SQL formatter from CDN
    const script = document.createElement('script');
    script.src = 'https://cdnjs.cloudflare.com/ajax/libs/sql-formatter/12.2.4/sql-formatter.min.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const formatSQL = () => {
    try {
      if (typeof window.sqlFormatter === 'undefined') {
        setError('Formatter is still loading. Please try again in a moment.');
        return;
      }

      const formatted = window.sqlFormatter.format(sqlInput, {
        language: dialect,
        uppercase: true,
        indentStyle: '  ',
        keywordCase: 'upper',
      });
      setFormattedSql(formatted);
      setError('');
    } catch (err) {
      setError('Failed to format SQL. Please check for syntax errors.');
      setFormattedSql('');
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(formattedSql || sqlInput);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleReset = () => {
    setSqlInput('');
    setFormattedSql('');
    setError('');
  };

  return (
    <Container maxWidth="lg">
      <Paper elevation={3} sx={{ p: 4, my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          SQL Formatter
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          Format and beautify SQL queries with syntax highlighting
        </Typography>

        <FormControl fullWidth sx={{ mb: 3 }}>
          <InputLabel>SQL Dialect</InputLabel>
          <Select
            value={dialect}
            label="SQL Dialect"
            onChange={(e) => setDialect(e.target.value)}
          >
            <MenuItem value="standard">Standard SQL</MenuItem>
            <MenuItem value="mysql">MySQL</MenuItem>
            <MenuItem value="postgresql">PostgreSQL</MenuItem>
            <MenuItem value="mariadb">MariaDB</MenuItem>
            <MenuItem value="sqlite">SQLite</MenuItem>
            <MenuItem value="db2">DB2</MenuItem>
            <MenuItem value="plsql">PL/SQL</MenuItem>
            <MenuItem value="tsql">T-SQL</MenuItem>
          </Select>
        </FormControl>

        <TextField
          multiline
          rows={8}
          value={sqlInput}
          onChange={(e) => setSqlInput(e.target.value)}
          fullWidth
          placeholder="Paste your SQL query here..."
          variant="outlined"
          sx={{ mb: 2, fontFamily: 'monospace' }}
        />

        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
          <Button
            variant="contained"
            startIcon={<FormatIcon />}
            onClick={formatSQL}
            disabled={!sqlInput}
          >
            Format SQL
          </Button>
          <Button
            variant="contained"
            startIcon={<CopyIcon />}
            onClick={handleCopy}
            disabled={!sqlInput && !formattedSql}
          >
            {copied ? 'Copied!' : 'Copy'}
          </Button>
          <Button
            variant="outlined"
            startIcon={<ResetIcon />}
            onClick={handleReset}
            disabled={!sqlInput && !formattedSql}
          >
            Reset
          </Button>
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {formattedSql && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Formatted SQL:
            </Typography>
            <TextField
              multiline
              rows={12}
              value={formattedSql}
              fullWidth
              variant="outlined"
              InputProps={{
                readOnly: true,
                sx: { fontFamily: 'monospace' }
              }}
            />
          </Box>
        )}
      </Paper>

      <ToolDescription
        title="SQL Formatter"
        description="A powerful SQL formatting tool that supports multiple SQL dialects and provides clean, consistent formatting for your queries."
        benefits={[
          "Support for multiple SQL dialects",
          "Automatic keyword capitalization",
          "Proper indentation and spacing",
          "Copy formatted SQL to clipboard",
          "Clean and consistent output",
          "Easy-to-use interface"
        ]}
        useCases={[
          "Format complex SQL queries",
          "Improve query readability",
          "Standardize SQL coding style",
          "Prepare SQL for documentation",
          "Debug SQL queries",
          "Learn proper SQL formatting"
        ]}
        howTo={[
          "Select your SQL dialect",
          "Paste your SQL query in the editor",
          "Click 'Format SQL' to beautify the code",
          "Copy the formatted SQL using the Copy button",
          "Reset the editor to start fresh",
          "Use different dialects for specific databases"
        ]}
      />
    </Container>
  );
}

export default SQLTool;
