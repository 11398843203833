import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Alert,
  LinearProgress,
  Divider,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  Chip,
} from '@mui/material';
import {
  CloudUpload as CloudUploadIcon,
  Delete as DeleteIcon,
  PictureAsPdf as PdfIcon,
  ContentCut as SplitIcon,
} from '@mui/icons-material';
import { PDFDocument } from 'pdf-lib';

const PDFSplitter = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState('');
  const [pageCount, setPageCount] = useState(0);
  const [splitMethod, setSplitMethod] = useState('custom'); // 'custom' or 'individual'
  const [pageRanges, setPageRanges] = useState('');
  const [selectedPages, setSelectedPages] = useState([]);

  const handleFileSelect = async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (file.type !== 'application/pdf') {
      setError('Please select a PDF file.');
      return;
    }

    if (file.size > 100 * 1024 * 1024) {
      setError('File size must be less than 100MB.');
      return;
    }

    try {
      // Load the PDF to get page count
      const arrayBuffer = await file.arrayBuffer();
      const pdf = await PDFDocument.load(arrayBuffer);
      const pages = pdf.getPageCount();

      setSelectedFile(file);
      setPageCount(pages);
      setError('');
      // Initialize selected pages array
      setSelectedPages(Array(pages).fill(false));
    } catch (err) {
      console.error('Error loading PDF:', err);
      setError('Failed to load PDF. The file might be corrupted.');
      setSelectedFile(null);
      setPageCount(0);
    }
  };

  const handlePageSelect = (index) => {
    setSelectedPages(prev => {
      const newSelection = [...prev];
      newSelection[index] = !newSelection[index];
      return newSelection;
    });
  };

  const validatePageRanges = (ranges) => {
    if (!ranges.trim()) return false;
    
    const rangePattern = /^(\d+(-\d+)?)(,\s*\d+(-\d+)?)*$/;
    if (!rangePattern.test(ranges)) return false;

    const individualRanges = ranges.split(',').map(r => r.trim());
    for (const range of individualRanges) {
      const [start, end] = range.split('-').map(Number);
      if (end && end < start) return false;
      if (start > pageCount || (end && end > pageCount)) return false;
      if (start < 1) return false;
    }

    return true;
  };

  const extractPages = async () => {
    if (!selectedFile) {
      setError('Please select a PDF file.');
      return;
    }

    if (splitMethod === 'custom' && !validatePageRanges(pageRanges)) {
      setError('Invalid page ranges. Please use format like "1-3, 5, 7-9"');
      return;
    }

    if (splitMethod === 'individual' && !selectedPages.some(Boolean)) {
      setError('Please select at least one page to extract.');
      return;
    }

    setProcessing(true);
    setProgress(0);
    setError('');

    try {
      const arrayBuffer = await selectedFile.arrayBuffer();
      const sourcePdf = await PDFDocument.load(arrayBuffer);
      
      let pagesToExtract = [];
      if (splitMethod === 'custom') {
        // Parse page ranges
        const ranges = pageRanges.split(',').map(r => r.trim());
        ranges.forEach(range => {
          const [start, end] = range.split('-').map(Number);
          if (end) {
            for (let i = start; i <= end; i++) {
              pagesToExtract.push(i - 1);
            }
          } else {
            pagesToExtract.push(start - 1);
          }
        });
      } else {
        // Get individually selected pages
        selectedPages.forEach((selected, index) => {
          if (selected) pagesToExtract.push(index);
        });
      }

      // Create new PDF with selected pages
      const newPdf = await PDFDocument.create();
      const copiedPages = await newPdf.copyPages(sourcePdf, pagesToExtract);
      copiedPages.forEach(page => newPdf.addPage(page));

      // Save the new PDF
      const newPdfBytes = await newPdf.save();
      const blob = new Blob([newPdfBytes], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);

      // Download the file
      const link = document.createElement('a');
      link.href = url;
      link.download = `split_${selectedFile.name}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);

      setProgress(100);
      setTimeout(() => {
        setProcessing(false);
        setProgress(0);
      }, 1000);
    } catch (err) {
      console.error('Error splitting PDF:', err);
      setError('Failed to split PDF. Please try again.');
      setProcessing(false);
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Typography variant="h4" gutterBottom>
          PDF Splitter
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <Box sx={{ mb: 4 }}>
          <label htmlFor="pdf-upload">
            <input
              id="pdf-upload"
              type="file"
              accept=".pdf"
              style={{ display: 'none' }}
              onChange={handleFileSelect}
              disabled={processing}
            />
            <Button
              variant="contained"
              component="span"
              startIcon={<CloudUploadIcon />}
              disabled={processing}
              fullWidth
            >
              Select PDF File
            </Button>
          </label>
        </Box>

        {selectedFile && (
          <>
            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle1" gutterBottom>
                Selected File: {selectedFile.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Total Pages: {pageCount}
              </Typography>
            </Box>

            <Box sx={{ mb: 3 }}>
              <Typography variant="h6" gutterBottom>
                Split Method
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Button
                    variant={splitMethod === 'custom' ? 'contained' : 'outlined'}
                    fullWidth
                    onClick={() => setSplitMethod('custom')}
                    sx={{ mb: 2 }}
                  >
                    Custom Range
                  </Button>
                  {splitMethod === 'custom' && (
                    <TextField
                      fullWidth
                      label="Page Ranges"
                      placeholder="e.g., 1-3, 5, 7-9"
                      value={pageRanges}
                      onChange={(e) => setPageRanges(e.target.value)}
                      helperText="Enter page ranges (e.g., 1-3, 5, 7-9)"
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    variant={splitMethod === 'individual' ? 'contained' : 'outlined'}
                    fullWidth
                    onClick={() => setSplitMethod('individual')}
                    sx={{ mb: 2 }}
                  >
                    Select Individual Pages
                  </Button>
                  {splitMethod === 'individual' && (
                    <Box sx={{ maxHeight: 200, overflowY: 'auto' }}>
                      <Grid container spacing={1}>
                        {Array.from({ length: pageCount }, (_, i) => (
                          <Grid item xs={4} sm={3} key={i}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={selectedPages[i]}
                                  onChange={() => handlePageSelect(i)}
                                />
                              }
                              label={`Page ${i + 1}`}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>

            {processing && (
              <Box sx={{ width: '100%', mb: 2 }}>
                <LinearProgress variant="determinate" value={progress} />
                <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 1 }}>
                  Splitting PDF: {Math.round(progress)}%
                </Typography>
              </Box>
            )}

            <Button
              variant="contained"
              color="primary"
              onClick={extractPages}
              disabled={processing}
              fullWidth
              startIcon={<SplitIcon />}
            >
              Split PDF
            </Button>
          </>
        )}

        <Divider sx={{ my: 4 }} />

        <Box>
          <Typography variant="h6" gutterBottom>
            About PDF Splitter
          </Typography>
          <Typography variant="body1" paragraph>
            Extract specific pages from your PDF document quickly and easily, right in your browser!
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" gutterBottom>
                Features:
              </Typography>
              <ul>
                <li>Split by page ranges</li>
                <li>Select individual pages</li>
                <li>Client-side processing</li>
                <li>No file upload needed</li>
                <li>Secure and private</li>
                <li>Free to use</li>
              </ul>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" gutterBottom>
                Limitations:
              </Typography>
              <ul>
                <li>Maximum 100MB file size</li>
                <li>PDF files only</li>
                <li>Some complex PDFs may not split correctly</li>
              </ul>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
};

export default PDFSplitter;
