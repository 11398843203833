import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Select,
  MenuItem,
  Grid,
  FormControl,
  InputLabel,
  Box,
} from '@mui/material';
import ToolDescription from '../common/ToolDescription';

const unitTypes = {
  length: {
    name: 'Length',
    units: {
      meter: { name: 'Meters (m)', factor: 1 },
      kilometer: { name: 'Kilometers (km)', factor: 1000 },
      centimeter: { name: 'Centimeters (cm)', factor: 0.01 },
      millimeter: { name: 'Millimeters (mm)', factor: 0.001 },
      mile: { name: 'Miles (mi)', factor: 1609.34 },
      yard: { name: 'Yards (yd)', factor: 0.9144 },
      foot: { name: 'Feet (ft)', factor: 0.3048 },
      inch: { name: 'Inches (in)', factor: 0.0254 },
    },
  },
  weight: {
    name: 'Weight',
    units: {
      kilogram: { name: 'Kilograms (kg)', factor: 1 },
      gram: { name: 'Grams (g)', factor: 0.001 },
      milligram: { name: 'Milligrams (mg)', factor: 0.000001 },
      pound: { name: 'Pounds (lb)', factor: 0.453592 },
      ounce: { name: 'Ounces (oz)', factor: 0.0283495 },
      ton: { name: 'Tons (t)', factor: 1000 },
    },
  },
  temperature: {
    name: 'Temperature',
    units: {
      celsius: { name: 'Celsius (°C)', factor: 1 },
      fahrenheit: { name: 'Fahrenheit (°F)', factor: 1 },
      kelvin: { name: 'Kelvin (K)', factor: 1 },
    },
  },
  area: {
    name: 'Area',
    units: {
      squareMeter: { name: 'Square Meters (m²)', factor: 1 },
      squareKilometer: { name: 'Square Kilometers (km²)', factor: 1000000 },
      hectare: { name: 'Hectares (ha)', factor: 10000 },
      acre: { name: 'Acres', factor: 4046.86 },
      squareFoot: { name: 'Square Feet (ft²)', factor: 0.092903 },
      squareInch: { name: 'Square Inches (in²)', factor: 0.00064516 },
    },
  },
  volume: {
    name: 'Volume',
    units: {
      cubicMeter: { name: 'Cubic Meters (m³)', factor: 1 },
      liter: { name: 'Liters (L)', factor: 0.001 },
      milliliter: { name: 'Milliliters (mL)', factor: 0.000001 },
      gallon: { name: 'Gallons (gal)', factor: 0.00378541 },
      quart: { name: 'Quarts (qt)', factor: 0.000946353 },
      pint: { name: 'Pints (pt)', factor: 0.000473176 },
      cup: { name: 'Cups', factor: 0.000236588 },
    },
  },
};

function UnitConverter() {
  const [unitType, setUnitType] = useState('length');
  const [fromUnit, setFromUnit] = useState('');
  const [toUnit, setToUnit] = useState('');
  const [fromValue, setFromValue] = useState('');
  const [toValue, setToValue] = useState('');

  useEffect(() => {
    // Set default units when unit type changes
    const units = Object.keys(unitTypes[unitType].units);
    setFromUnit(units[0]);
    setToUnit(units[1]);
  }, [unitType]);

  useEffect(() => {
    if (fromValue && fromUnit && toUnit) {
      convert();
    }
  }, [fromValue, fromUnit, toUnit]);

  const convert = () => {
    if (!fromValue || isNaN(fromValue)) {
      setToValue('');
      return;
    }

    const value = parseFloat(fromValue);

    if (unitType === 'temperature') {
      // Special handling for temperature
      let celsius;
      switch (fromUnit) {
        case 'fahrenheit':
          celsius = (value - 32) * (5/9);
          break;
        case 'kelvin':
          celsius = value - 273.15;
          break;
        default:
          celsius = value;
      }

      let result;
      switch (toUnit) {
        case 'fahrenheit':
          result = (celsius * 9/5) + 32;
          break;
        case 'kelvin':
          result = celsius + 273.15;
          break;
        default:
          result = celsius;
      }
      setToValue(result.toFixed(4));
    } else {
      // Standard conversion for other unit types
      const fromFactor = unitTypes[unitType].units[fromUnit].factor;
      const toFactor = unitTypes[unitType].units[toUnit].factor;
      const result = (value * fromFactor) / toFactor;
      setToValue(result.toFixed(4));
    }
  };

  return (
    <Container maxWidth="lg">
      <Paper elevation={3} sx={{ p: 4, my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Unit Converter
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          Convert between different units of measurement.
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel>Unit Type</InputLabel>
              <Select
                value={unitType}
                onChange={(e) => setUnitType(e.target.value)}
                label="Unit Type"
              >
                {Object.entries(unitTypes).map(([key, type]) => (
                  <MenuItem key={key} value={key}>
                    {type.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={9}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={5}>
                <FormControl fullWidth>
                  <InputLabel>From Unit</InputLabel>
                  <Select
                    value={fromUnit}
                    onChange={(e) => setFromUnit(e.target.value)}
                    label="From Unit"
                  >
                    {Object.entries(unitTypes[unitType].units).map(([key, unit]) => (
                      <MenuItem key={key} value={key}>
                        {unit.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Typography variant="h5">→</Typography>
              </Grid>

              <Grid item xs={12} sm={5}>
                <FormControl fullWidth>
                  <InputLabel>To Unit</InputLabel>
                  <Select
                    value={toUnit}
                    onChange={(e) => setToUnit(e.target.value)}
                    label="To Unit"
                  >
                    {Object.entries(unitTypes[unitType].units).map(([key, unit]) => (
                      <MenuItem key={key} value={key}>
                        {unit.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={5}>
                <TextField
                  fullWidth
                  label="From Value"
                  type="number"
                  value={fromValue}
                  onChange={(e) => setFromValue(e.target.value)}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} sm={2} />

              <Grid item xs={12} sm={5}>
                <TextField
                  fullWidth
                  label="To Value"
                  value={toValue}
                  variant="outlined"
                  InputProps={{ readOnly: true }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <ToolDescription
        title="Unit Converter"
        description="A versatile unit conversion tool that supports various measurement types including length, weight, temperature, area, and volume."
        benefits={[
          "Multiple unit types support",
          "Real-time conversion",
          "Accurate calculations",
          "Easy-to-use interface",
          "Common unit conversions",
          "Temperature conversion support"
        ]}
        useCases={[
          "Engineering calculations",
          "Scientific conversions",
          "Cooking measurements",
          "Construction calculations",
          "International trade",
          "Educational purposes"
        ]}
        howTo={[
          "Select the type of unit to convert",
          "Choose the source unit",
          "Choose the target unit",
          "Enter the value to convert",
          "View the converted result instantly",
          "Switch between different unit types as needed"
        ]}
      />
    </Container>
  );
}

export default UnitConverter;
