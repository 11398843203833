import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  CheckCircleOutline as BenefitIcon,
  HowToReg as UseCaseIcon,
  Help as HowToIcon,
} from '@mui/icons-material';

function ToolDescription({ title, description, benefits, useCases, howTo }) {
  return (
    <Paper sx={{ mt: 4, mb: 2 }} elevation={0}>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" component="h2" gutterBottom>
          About {title}
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          {description}
        </Typography>
      </Box>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="benefits-content"
          id="benefits-header"
        >
          <Typography variant="subtitle1">Key Benefits</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {benefits.map((benefit, index) => (
              <ListItem key={index} sx={{ py: 0 }}>
                <ListItemIcon>
                  <BenefitIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary={benefit} />
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="use-cases-content"
          id="use-cases-header"
        >
          <Typography variant="subtitle1">Common Use Cases</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {useCases.map((useCase, index) => (
              <ListItem key={index} sx={{ py: 0 }}>
                <ListItemIcon>
                  <UseCaseIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary={useCase} />
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="how-to-content"
          id="how-to-header"
        >
          <Typography variant="subtitle1">How to Use</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {howTo.map((step, index) => (
              <ListItem key={index} sx={{ py: 0 }}>
                <ListItemIcon>
                  <HowToIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary={`Step ${index + 1}`}
                  secondary={step}
                />
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
}

export default ToolDescription;
