import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Alert,
  LinearProgress,
  Divider,
  Grid,
} from '@mui/material';
import {
  CloudUpload as CloudUploadIcon,
  Delete as DeleteIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
  PictureAsPdf as PdfIcon,
} from '@mui/icons-material';
import { PDFDocument } from 'pdf-lib';

const PDFMerger = () => {
  const [pdfFiles, setPdfFiles] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState('');

  const handleFileSelect = async (event) => {
    const files = Array.from(event.target.files);
    
    // Validate files
    for (const file of files) {
      if (file.type !== 'application/pdf') {
        setError('Please select only PDF files.');
        return;
      }

      // Check file size (max 100MB per file)
      if (file.size > 100 * 1024 * 1024) {
        setError('Each PDF file must be less than 100MB.');
        return;
      }
    }

    // Add new files to the list
    setPdfFiles(prevFiles => [...prevFiles, ...files]);
    setError('');
  };

  const removeFile = (index) => {
    setPdfFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  const moveFile = (index, direction) => {
    setPdfFiles(prevFiles => {
      const newFiles = [...prevFiles];
      const temp = newFiles[index];
      newFiles[index] = newFiles[index + direction];
      newFiles[index + direction] = temp;
      return newFiles;
    });
  };

  const mergePDFs = async () => {
    if (pdfFiles.length < 2) {
      setError('Please select at least 2 PDF files to merge.');
      return;
    }

    setProcessing(true);
    setProgress(0);
    setError('');

    try {
      // Create a new PDF document
      const mergedPdf = await PDFDocument.create();
      const totalFiles = pdfFiles.length;

      // Process each PDF file
      for (let i = 0; i < totalFiles; i++) {
        const file = pdfFiles[i];
        const fileArrayBuffer = await file.arrayBuffer();
        const pdf = await PDFDocument.load(fileArrayBuffer);
        const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
        copiedPages.forEach(page => mergedPdf.addPage(page));

        // Update progress
        setProgress(((i + 1) / totalFiles) * 100);
      }

      // Save the merged PDF
      const mergedPdfFile = await mergedPdf.save();
      const blob = new Blob([mergedPdfFile], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);

      // Create download link
      const link = document.createElement('a');
      link.href = url;
      link.download = 'merged_document.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);

      setProgress(100);
      setTimeout(() => {
        setProcessing(false);
        setProgress(0);
      }, 1000);
    } catch (err) {
      console.error('Error merging PDFs:', err);
      setError('Failed to merge PDFs. Please make sure all files are valid PDFs.');
      setProcessing(false);
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Typography variant="h4" gutterBottom>
          PDF Merger
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <Box sx={{ mb: 4 }}>
          <label htmlFor="pdf-upload">
            <input
              id="pdf-upload"
              type="file"
              accept=".pdf"
              multiple
              style={{ display: 'none' }}
              onChange={handleFileSelect}
              disabled={processing}
            />
            <Button
              variant="contained"
              component="span"
              startIcon={<CloudUploadIcon />}
              disabled={processing}
              fullWidth
            >
              Select PDF Files
            </Button>
          </label>
        </Box>

        {pdfFiles.length > 0 && (
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" gutterBottom>
              Selected Files
            </Typography>
            <List>
              {pdfFiles.map((file, index) => (
                <ListItem key={index} sx={{ bgcolor: 'background.paper', mb: 1 }}>
                  <PdfIcon sx={{ mr: 2 }} />
                  <ListItemText 
                    primary={file.name}
                    secondary={`${(file.size / (1024 * 1024)).toFixed(2)} MB`}
                  />
                  <ListItemSecondaryAction>
                    {index > 0 && (
                      <IconButton
                        edge="end"
                        aria-label="move up"
                        onClick={() => moveFile(index, -1)}
                      >
                        <ArrowUpwardIcon />
                      </IconButton>
                    )}
                    {index < pdfFiles.length - 1 && (
                      <IconButton
                        edge="end"
                        aria-label="move down"
                        onClick={() => moveFile(index, 1)}
                      >
                        <ArrowDownwardIcon />
                      </IconButton>
                    )}
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => removeFile(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Box>
        )}

        {processing && (
          <Box sx={{ width: '100%', mb: 2 }}>
            <LinearProgress variant="determinate" value={progress} />
            <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 1 }}>
              Merging PDFs: {Math.round(progress)}%
            </Typography>
          </Box>
        )}

        <Button
          variant="contained"
          color="primary"
          onClick={mergePDFs}
          disabled={pdfFiles.length < 2 || processing}
          fullWidth
          startIcon={<PdfIcon />}
        >
          Merge PDFs
        </Button>

        <Divider sx={{ my: 4 }} />

        <Box>
          <Typography variant="h6" gutterBottom>
            About PDF Merger
          </Typography>
          <Typography variant="body1" paragraph>
            Combine multiple PDF files into a single document quickly and easily, right in your browser!
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" gutterBottom>
                Features:
              </Typography>
              <ul>
                <li>Merge multiple PDFs</li>
                <li>Drag and drop file reordering</li>
                <li>Client-side processing</li>
                <li>No file upload needed</li>
                <li>Secure and private</li>
                <li>Free to use</li>
              </ul>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" gutterBottom>
                Limitations:
              </Typography>
              <ul>
                <li>Maximum 100MB per file</li>
                <li>PDF files only</li>
                <li>Some complex PDFs may not merge correctly</li>
              </ul>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
};

export default PDFMerger;
