import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Paper, Alert, CircularProgress, Slider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CloudUpload as CloudUploadIcon, PlayArrow as PlayArrowIcon, Stop as StopIcon } from '@mui/icons-material';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { toBlobURL, fetchFile } from '@ffmpeg/util';
import WaveSurfer from 'wavesurfer.js';

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

const AudioTrimmer = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);
  const [fileName, setFileName] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [trimRange, setTrimRange] = useState([0, 100]);
  const ffmpegRef = React.useRef(new FFmpeg());
  const [loaded, setLoaded] = useState(false);
  const wavesurferRef = React.useRef(null);
  const audioRef = React.useRef(null);

  const load = async () => {
    const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.2/dist/umd';
    const ffmpeg = ffmpegRef.current;
    ffmpeg.on('progress', ({ progress }) => {
      setProgress(Math.round(progress * 100));
    });
    await ffmpeg.load({
      coreURL: await toBlobURL(baseURL + '/ffmpeg-core.js', 'text/javascript'),
      wasmURL: await toBlobURL(baseURL + '/ffmpeg-core.wasm', 'application/wasm'),
    });
    setLoaded(true);
  };

  useEffect(() => {
    load();
    return () => {
      if (wavesurferRef.current) {
        wavesurferRef.current.destroy();
      }
    };
  }, []);

  const initWaveform = (audioBlob) => {
    if (wavesurferRef.current) {
      wavesurferRef.current.destroy();
    }

    const wavesurfer = WaveSurfer.create({
      container: '#waveform',
      waveColor: '#4a9eff',
      progressColor: '#1976d2',
      cursorColor: '#1976d2',
      barWidth: 2,
      barRadius: 3,
      responsive: true,
      height: 100,
      normalize: true,
      partialRender: true,
    });

    wavesurfer.loadBlob(audioBlob);
    wavesurferRef.current = wavesurfer;

    wavesurfer.on('ready', () => {
      setDuration(wavesurfer.getDuration());
      setTrimRange([0, wavesurfer.getDuration()]);
    });

    wavesurfer.on('play', () => setIsPlaying(true));
    wavesurfer.on('pause', () => setIsPlaying(false));
    wavesurfer.on('finish', () => setIsPlaying(false));
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      setLoading(true);
      setError(null);
      setProgress(0);
      setFileName(file.name);

      // Initialize waveform with original audio
      initWaveform(file);

      // Store the file for later processing
      audioRef.current = file;

    } catch (err) {
      console.error('Audio loading error:', err);
      setError('Error loading audio: ' + (err.message || 'Unknown error'));
    } finally {
      setLoading(false);
      setProgress(0);
    }
  };

  const handleTrim = async () => {
    if (!audioRef.current) return;

    try {
      setLoading(true);
      setError(null);
      setProgress(0);

      const ffmpeg = ffmpegRef.current;
      const inputFormat = audioRef.current.name.split('.').pop().toLowerCase();
      const outputFileName = 'trimmed.' + inputFormat;

      // Write the input file to FFmpeg's virtual file system
      await ffmpeg.writeFile('input.' + inputFormat, await fetchFile(audioRef.current));

      // Calculate trim duration
      const startTime = trimRange[0];
      const duration = trimRange[1] - trimRange[0];

      // Run FFmpeg command to trim audio
      await ffmpeg.exec([
        '-i', 'input.' + inputFormat,
        '-ss', startTime.toString(),
        '-t', duration.toString(),
        outputFileName
      ]);

      // Read the output file
      const data = await ffmpeg.readFile(outputFileName);
      const blob = new Blob([data.buffer], { type: 'audio/' + inputFormat });
      
      // Create download link
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'trimmed_' + audioRef.current.name;
      link.click();
      URL.revokeObjectURL(url);

    } catch (err) {
      console.error('Trimming error:', err);
      setError('Error trimming audio: ' + (err.message || 'Unknown error'));
    } finally {
      setLoading(false);
      setProgress(0);
    }
  };

  const togglePlayPause = () => {
    if (wavesurferRef.current) {
      wavesurferRef.current.playPause();
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return minutes + ':' + (remainingSeconds < 10 ? '0' : '') + remainingSeconds;
  };

  return (
    <Box sx={{ p: 3 }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom>
          Audio Trimmer
        </Typography>
        
        <Typography variant="body1" sx={{ mb: 2 }}>
          Trim your audio files with precise control.
        </Typography>

        <Box sx={{ mb: 3 }}>
          <Button
            component="label"
            variant="contained"
            startIcon={<CloudUploadIcon />}
            disabled={loading || !loaded}
          >
            Upload Audio File
            <VisuallyHiddenInput type="file" onChange={handleFileUpload} accept="audio/*" />
          </Button>
        </Box>

        {fileName && (
          <Typography variant="body2" sx={{ mb: 1 }}>
            Selected file: {fileName}
          </Typography>
        )}

        <Box id="waveform" sx={{ mb: 3 }} />

        {wavesurferRef.current && (
          <>
            <Button
              variant="contained"
              onClick={togglePlayPause}
              startIcon={isPlaying ? <StopIcon /> : <PlayArrowIcon />}
              sx={{ mb: 3 }}
            >
              {isPlaying ? 'Stop' : 'Play'}
            </Button>

            <Box sx={{ mb: 3 }}>
              <Typography gutterBottom>
                Trim Range: {formatTime(trimRange[0])} - {formatTime(trimRange[1])}
              </Typography>
              <Slider
                value={trimRange}
                onChange={(e, newValue) => setTrimRange(newValue)}
                min={0}
                max={duration}
                step={0.1}
                valueLabelDisplay="auto"
                valueLabelFormat={formatTime}
              />
            </Box>

            <Button
              variant="contained"
              onClick={handleTrim}
              disabled={loading}
              sx={{ mb: 3 }}
            >
              Trim Audio
            </Button>
          </>
        )}

        {loading && (
          <Box sx={{ textAlign: 'center', my: 2 }}>
            <CircularProgress variant="determinate" value={progress} />
            <Typography variant="body2" sx={{ mt: 1 }}>
              Processing... {progress}%
            </Typography>
          </Box>
        )}

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Paper>
    </Box>
  );
};

export default AudioTrimmer;
